<template>
  <div>
    <router-view />
  </div>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "ExportRouter",
});
</script>
