<template>
  <div>
    <Portal to="subheader-actions" key="patients-actions">
      <span class="mb-2 mr-2 is-hidden-mobile">{{
        $t("common.last_refreshed_on", { updatedOn: formatTime(updatedOn) })
      }}</span>
      <b-button
        icon-left="sync-alt"
        @click="onRefreshClick"
        :loading="isRefreshingPatients"
      >
        {{ $t("common.refresh_list") }}
      </b-button>

      <b-button
        type="is-primary"
        icon-left="plus"
        @click="$router.push('/patient/create')"
        >{{ $t("patients_page.add_patient") }}
      </b-button>
    </Portal>
    <transition name="fade">
      <div class="columns" key="content">
        <div class="column is-one-fifth">
          <Card :title="$t('common.filter') | titleize">
            <Filters
              :onSearchClick="onSearchClick"
              :resource="AttributeResource.Patients"
              :activeFilters.sync="activeFilters"
            />
          </Card>
        </div>
        <div class="column">
          <Warnings :warnings="warnings">
            <template v-slot:after-message>
              <div
                @click="onFilterResetClick"
                class="reset-filter button is-text"
              >
                {{ $t("common.reset_filters") }}
              </div>
            </template>
          </Warnings>
          <Card :contentPadding="false" :overflow="true" class="no-background">
            <Table
              :isLoading="isLoading"
              :columns="columns"
              :items="items"
              :page="params.page"
              :page-size="params.page_size"
              :total="total"
              :sort-direction="params.direction"
              :sort="params.sort"
              :onPageChange="onPageChange"
              :onSortChange="onSortChange"
              :onRowClick="onRowClick"
              :onPageSizeChange="onPageSizeChange"
            />
          </Card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Table from "@/components/Table.vue";
import Card from "@/components/Card.vue";
import Filters from "@/components/Filters.vue";

import { getPatients, getLocks } from "@/api";
import TableMixin from "@/mixins/TableMixin";
import mixins from "vue-typed-mixins";
import eventBus from "@/utils/eventBus";

import { PatientRow } from "@/types/patient";
import { AttributeResource, FilterParams } from "@/types/table";
import Warnings from "@/components/Common/Warnings.vue";
import { Cache } from "@/utils/cache";
import { formatTime } from "@/filters/date";
import i18n from "@/i18n";

export default mixins(TableMixin<PatientRow, FilterParams>()).extend({
  name: "Patients",
  components: {
    Warnings,
    Table,
    Card,
    Filters,
  },

  data() {
    return {
      lockRefresher: 0,
      updatedOn: null as unknown as Date,
      AttributeResource,
      isLoading: true,
      isRefreshingPatients: false,
      activeFilters: [],
      paramsLoaded: false,
      formatTime: formatTime,
    };
  },
  computed: {
    visiblePatientIds(): string {
      return this.items.map((p) => p.id).join(",");
    },
    warnings() {
      const warnings = [];

      if (this.activeFilters.length > 0) {
        let warningMessage = i18n
          .t("patients_page.filters.one_filter_active_message")
          .toString();

        if (this.activeFilters.length > 1) {
          warningMessage = i18n
            .t("patients_page.filters.multiple_filters_active_message", {
              activeFiltersCount: this.activeFilters.length,
            })
            .toString();
        }

        warningMessage +=
          " " +
          i18n
            .t("patients_page.filters.less_patients_visible_message")
            .toString();

        warnings.push({ type: "warning", message: warningMessage });
      }

      if (this.params.grid_entity === "screenings") {
        warnings.push({
          type: "warning",
          message: i18n
            .t("patients_page.filters.screenings_entity_message")
            .toString(),
        });
      }

      return warnings;
    },
  },

  watch: {
    $route: {
      handler: function () {
        this.getPatients();
      },
    },
  },
  methods: {
    async getPatients() {
      const query = this.createQueryString(this.$route);
      try {
        const { data: patientResponse } = await getPatients(query);
        this.items = [...patientResponse.items];
        this.params = patientResponse.params;
        this.total = patientResponse.total;
        this.updatedOn = new Date();
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("common.retrieving_error_message", {
              resource: i18n.t("common.patients").toString(),
            })
            .toString(),
          type: "is-danger",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async onRefreshClick() {
      try {
        this.isRefreshingPatients = true;
        await this.getPatients();
        this.$buefy.toast.open({
          message: i18n.t("common.refresh_data_done").toString(),
          type: "is-success",
        });
        this.isRefreshingPatients = false;
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            i18n.t("common.refresh_data_error_message").toString(),
          type: "is-danger",
        });
        this.isRefreshingPatients = false;
      }
    },

    onRowClick(row: PatientRow) {
      this.$router.push(`/patient/${row.id}`);
    },

    onFilterResetClick() {
      eventBus.$emit("clear-filters");
    },
  },
  async mounted() {
    this.loadCachedQueryString();
    this.getAttributes(AttributeResource.Patients);

    this.lockRefresher = window.setInterval(async () => {
      if (this.visiblePatientIds?.length > 0) {
        const { data: locks } = await getLocks(this.visiblePatientIds);

        for (let patientId in locks) {
          const patientIndex = this.items.findIndex(
            (patient) => patient.id === parseInt(patientId)
          );

          this.$set(
            this.items[patientIndex],
            "locked_by_name",
            locks[patientId]
          );
        }
      }
    }, parseInt(process.env.VUE_APP_PATIENTS_REFRESH_INTERVAL || "0") || 10000);

    this.getPatients();
  },
  beforeDestroy() {
    clearInterval(this.lockRefresher);
  },
  beforeRouteLeave(to, from, next) {
    if (this.$route.query) {
      Cache.set(this.$route.path, this.$route.query);
    }
    next();
  },
});
</script>
<style lang="scss" scoped>
.reset-filter {
  height: inherit;
  line-height: inherit;
}
</style>
