var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.value && _vm.value.selected_options && !_vm.isLoading)?_c('div',[_c('table',{staticClass:"table is-fullwidth is-bordered mb-4"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("anesthesia_table.anesthesia_technique")))]),_c('th',[_vm._v(_vm._s(_vm.$t("anesthesia_table.applicable")))]),_c('th',[_vm._v(_vm._s(_vm.$t("anesthesia_table.preferred")))])])]),_c('tbody',_vm._l((_vm.field.options),function(option){return _c('tr',{key:option.value},[_c('td',[_vm._v(_vm._s(option.label))]),_c('td',{staticClass:"has-text-centered"},[_c('b-checkbox',{attrs:{"native-value":option.value,"disabled":_vm.exclusiveChoiceSelected &&
              _vm.exclusiveChoiceSelected !== option.value},on:{"input":() => _vm.handleChoice(option.value, option)},model:{value:(_vm.value.selected_options),callback:function ($$v) {_vm.$set(_vm.value, "selected_options", $$v)},expression:"value.selected_options"}})],1),_c('td',{staticClass:"has-text-centered"},[_c('b-checkbox',{attrs:{"native-value":option.value,"disabled":_vm.value.selected_options.length <= 1 ||
              !_vm.value.selected_options.includes(option.value)},on:{"input":(v) => _vm.handleSingleSelection(option.value, v)},model:{value:(_vm.preferredOptions),callback:function ($$v) {_vm.preferredOptions=$$v},expression:"preferredOptions"}})],1)])}),0)]),(_vm.anesthesiaTechniques && _vm.value.selected_options.length > 0)?_c('b-message',{attrs:{"head":"","has-icon":"","type":"is-primary"}},[_c('p',{staticClass:"has-text-black is-flex is-align-items-center"},[(_vm.noInfoChoiceSelected)?[_c('span',{staticClass:"is-flex-grow-1"},[_vm._v(" "+_vm._s(_vm.$t("anesthesia_table.selected_techniques_no_information"))+" ")])]:[_c('span',{staticClass:"is-flex-grow-1",domProps:{"innerHTML":_vm._s(
            _vm.richMessage(
              _vm.$t('anesthesia_table.selected_techniques_info_message', {
                nVideos: _vm.videos.length,
                nSelectedOptions: _vm.value.selected_options.length,
              }).toString()
            )
          )}}),_c('b-button',{staticClass:"is-primary is-pulled-right is-hidden-desktop",on:{"click":_vm.toggleSidebar}},[_vm._v(_vm._s(_vm.$t("common.open"))+" ")])]],2)]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }