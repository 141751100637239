<template>
  <div v-if="value && value.selected_options && !isLoading">
    <table class="table is-fullwidth is-bordered mb-4">
      <thead>
        <tr>
          <th>{{ $t("anesthesia_table.anesthesia_technique") }}</th>
          <th>{{ $t("anesthesia_table.applicable") }}</th>
          <th>{{ $t("anesthesia_table.preferred") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="option in field.options" :key="option.value">
          <td>{{ option.label }}</td>
          <td class="has-text-centered">
            <b-checkbox
              :native-value="option.value"
              v-model="value.selected_options"
              @input="() => handleChoice(option.value, option)"
              :disabled="
                exclusiveChoiceSelected &&
                exclusiveChoiceSelected !== option.value
              "
            />
          </td>
          <td class="has-text-centered">
            <b-checkbox
              @input="(v) => handleSingleSelection(option.value, v)"
              v-model="preferredOptions"
              :native-value="option.value"
              :disabled="
                value.selected_options.length <= 1 ||
                !value.selected_options.includes(option.value)
              "
            />
          </td>
        </tr>
      </tbody>
    </table>
    <b-message
      head
      has-icon
      type="is-primary"
      v-if="anesthesiaTechniques && value.selected_options.length > 0"
    >
      <p class="has-text-black is-flex is-align-items-center">
        <template v-if="noInfoChoiceSelected">
          <span class="is-flex-grow-1">
            {{ $t("anesthesia_table.selected_techniques_no_information") }}
          </span>
        </template>
        <template v-else>
          <span
            v-html="
              richMessage(
                $t('anesthesia_table.selected_techniques_info_message', {
                  nVideos: videos.length,
                  nSelectedOptions: value.selected_options.length,
                }).toString()
              )
            "
            class="is-flex-grow-1"
          >
          </span>
          <b-button
            class="is-primary is-pulled-right is-hidden-desktop"
            @click="toggleSidebar"
            >{{ $t("common.open") }}
          </b-button>
        </template>
      </p>
    </b-message>
  </div>
</template>

<script lang="ts">
import {
  AnesthesiaTechniqueField,
  AnesthesiaVideo,
} from "@/types/anesthesiaTechniques";
import Vue, { VueConstructor } from "vue";
import { FieldMixin } from "./FieldMixin";
import { resources } from "@/utils/resources";
import { first } from "lodash";
import i18n from "@/i18n";
import { richMessage } from "@/utils/templateFilters";

export default //@ts-ignore
(Vue as VueConstructor<Vue & InstanceType<typeof FieldMixin>>).extend({
  name: "AnesthesiaTable",
  mixins: [FieldMixin],
  props: {
    validate: Function,
  },
  data() {
    return {
      anesthesiaTechniques: null,
      isLoading: true,
    };
  },
  computed: {
    preferredOptions: {
      get() {
        return this.value.preferred_options ?? [];
      },
      set() {
        /* @ts-ignore */
      },
    },
    filteredTechniques(): AnesthesiaTechniqueField[] {
      return (this.anesthesiaTechniques || []).filter(
        (c: AnesthesiaTechniqueField) => {
          return this.value.selected_options.includes(c.value);
        }
      );
    },
    videos(): AnesthesiaVideo[] {
      const videos = this.filteredTechniques
        .map((t: AnesthesiaTechniqueField) => t.videos)
        .flat();

      return [...new Set<AnesthesiaVideo>(videos)];
    },
    // returns a list of choices that have the exclusive flag to true.
    exclusiveChoices(): string[] {
      return this.field.options
        .filter((v: AnesthesiaTechniqueField) => v?.exclusive === true)
        .map((v: AnesthesiaTechniqueField) => v.value);
    },
    exclusiveChoiceSelected() {
      const options = this.value.selected_options as string[];
      const firstOption = first(options);
      return firstOption && this.exclusiveChoices.includes(firstOption)
        ? firstOption
        : null;
    },
    noInfoChoiceSelected() {
      const options = this.field.options as AnesthesiaTechniqueField[];

      // A choice that disables information is necessarily exclusive
      const exclusive = this.exclusiveChoiceSelected;
      return (
        exclusive &&
        options.find((v) => v.value === exclusive)?.information === false
      );
    },
  },
  methods: {
    richMessage,
    deselectAllOtherChoicesButThisChoice(choice: string) {
      const selectedOptions = this.value.selected_options.filter(
        (selected: any) => {
          return selected === choice;
        }
      );

      this.value.selected_options = [...selectedOptions];
      this.value.preferred_options = [];
    },
    resetPreferredOptions() {
      this.preferredOptions.splice(0, 1);
      this.value.preferred_options = [];
    },
    handleChoice(choice: string, option: any) {
      const choiceSelected = this.value.selected_options.includes(choice);
      const choiceInPreferredOptions =
        this.value.preferred_options.includes(choice);

      //
      if (choiceSelected && option?.exclusive === true) {
        this.deselectAllOtherChoicesButThisChoice(choice);
      }

      if (
        (!choiceSelected && choiceInPreferredOptions) ||
        this.value.selected_options.length <= 1
      ) {
        this.resetPreferredOptions();
      }

      this.validate();
    },
    handleSingleSelection(choice: string, selectedCheckboxes: Array<any>) {
      // Note: this triggers a reaction in the checkbox.
      // https://vuejs.org/v2/guide/reactivity.html
      selectedCheckboxes.splice(0, 1);

      if (!this.value.preferred_options.includes(choice)) {
        this.$set(selectedCheckboxes, 0, choice);
        this.value.preferred_options = [choice];
      } else {
        this.value.preferred_options = [];
      }

      this.validate();
    },
    toggleSidebar() {
      this.$root.$emit("toggle-video-sidebar", true);
    },
  },
  async created() {
    try {
      this.isLoading = true;
      this.anesthesiaTechniques = await resources.getAnesthesiaTechniques();
    } catch (err) {
      this.$buefy.toast.open({
        message: i18n
          .t("common.retrieving_error_message", {
            resource: i18n.t("common.anesthesia_techniques").toString(),
          })
          .toString(),
        type: "is-danger",
      });
    } finally {
      this.isLoading = false;
    }
  },
});
</script>
