<template>
  <div class="tabs-container">
    <b-tabs class="report-tabs" v-model="currentTab">
      <b-tab-item
        :label="$t('common.questionnaire') | titleize"
        icon="clipboard-list"
      >
        <b-loading :is-full-page="true" :active="isLoadingQuestionnaire" />

        <transition name="fade">
          <div class="vertical-card-spacing" v-if="!isLoadingQuestionnaire">
            <template v-if="$route.name === 'screening-process-questionnaire'">
              <card
                :label="$t('common.conclude') | titleize"
                icon="book-medical"
              >
                <Form
                  :fields="fields"
                  :onSubmitClick="onSubmitClick"
                  :isSubmitDisabled="isSubmitDisabled"
                  v-model="processData"
                >
                </Form>
              </card>
            </template>

            <card
              v-for="(section, index) in questionnaire.sections"
              :key="index"
              :title="section.title"
              icon="clipboard-list"
            >
              <inline-text-presentation
                v-for="(row, index) in section.answers"
                bold="value"
                layout="columns"
                :key="index"
                :label="row.question"
                :value="row.answer"
                class="questionnaire-row"
              />
            </card>
          </div>
        </transition>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { getData, getForm, getQuestionnaire, postFormData } from "@/api";
import { Report, ReportColumn } from "@/types/report";
import Form from "@/components/Form/Form.vue";
import InlineTextPresentation from "@/components/InlineTextPresentation.vue";

import Card from "@/components/Card.vue";
import { ProcessData } from "@/types/process-screening";
import { FormField } from "@/types/form";
import i18n from "@/i18n";

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Questionnaire",
  components: {
    InlineTextPresentation,
    Card,
    Form,
  },
  inject: ["isLocked"],
  data() {
    return {
      isSubmitDisabled: false,
      isLoadingReport: false,
      isLoadingSummary: false,
      isLoadingQuestionnaire: false,
      isLoading: false,
      report: {} as Report,
      reportSummary: {} as ReportColumn,
      questionnaire: {},
      processData: {} as ProcessData,
      filter: "all" as "all" | "danger",
      fields: [] as FormField[],
      currentTab: 0,
    };
  },
  computed: {
    screeningId(): number {
      return parseInt(this.$route.params?.screeningId);
    },
    patientId(): number {
      return parseInt(this.$route.params?.patientId);
    },
  },
  methods: {
    async getQuestionnaire() {
      try {
        this.isLoadingQuestionnaire = true;
        const { data } = await getQuestionnaire(this.screeningId);
        this.questionnaire = data;
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("common.retrieving_error_message", {
              resource: i18n.t("common.questionnaire").toString(),
            })
            .toString(),
          type: "is-danger",
        });
      } finally {
        this.isLoadingQuestionnaire = false;
      }
    },
    async getProcessForm() {
      try {
        const { data } = await getForm(
          this.screeningId,
          "process-questionnaire"
        );
        const { data: processData } = await getData<ProcessData>(
          this.screeningId,
          "process-questionnaire"
        );
        this.fields = data;
        this.processData = processData;
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("common.retrieving_error_message", {
              resource: i18n.t("common.conclude_form").toString(),
            })
            .toString(),
          type: "is-danger",
        });
      }
    },

    async onSubmitClick() {
      this.isSubmitDisabled = true;
      try {
        await postFormData<ProcessData>(
          this.screeningId,
          "process-questionnaire",
          this.processData
        );
        this.$buefy.toast.open({
          message: i18n.t("report_page.saved_conclude_message").toString(),
          type: "is-success",
        });
        this.$router.push(`/patient/${this.patientId}`);
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            i18n
              .t("common.retrieving_error_message", {
                resource: i18n.t("common.preparation").toString(),
              })
              .toString(),
          type: "is-danger",
        });
      } finally {
        this.isSubmitDisabled = false;
      }
    },

    async getData() {
      try {
        if (this.$route.name === "screening-process-questionnaire") {
          await this.getProcessForm();
        }

        await this.getQuestionnaire();
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("common.retrieving_error_message", {
              resource: i18n.t("common.report").toString(),
            })
            .toString(),
          type: "is-danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    isLocked: {
      handler: function ({ value }) {
        if (!value) {
          this.getData();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.getData();
  },
});
</script>
