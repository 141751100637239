<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t("export_page.export_modal.title") }}
      </p>
    </header>
    <section class="modal-card-body">
      <b-message type="is-info" has-icon>
        {{ $t("export_page.export_modal.info_message") }}
      </b-message>
      <b-field
        :label="
          $t('export_page.export_modal.select_export_template').toString()
        "
      >
        <select-field
          v-if="isLoaded"
          :field="exportTemplates"
          labelKey="name"
          v-model="exportTemplate"
          valueKey="id"
        />
      </b-field>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <b-button @click="$parent.close()">
        {{ $t("common.cancel") }}
      </b-button>
      <b-button type="is-primary" @click="onSubmitClick">
        {{ $t("export_page.export_modal.new_export") }}
      </b-button>
    </footer>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { FormField } from "@/types/form";
import SelectField from "@/components/Form/SelectField.vue";

import { doExport, getExportTemplates } from "@/api";
import {
  ExportOptions,
  ExportTemplate,
  OptionsInterface,
} from "@/types/export";

export default Vue.extend({
  name: "ExportModal",

  data() {
    return {
      fields: [] as FormField[],
      exportTemplate: "",
      exportTemplates: {} as OptionsInterface<ExportTemplate>,
      error: {},
      isLoaded: false,
    };
  },
  components: {
    SelectField,
  },
  methods: {
    async onSubmitClick() {
      const options = {
        export_template_id: this.exportTemplate,
      } as ExportOptions;

      const { data: ExportResponse } = await doExport(options);

      // @ts-ignore
      this.$parent.close();

      this.$emit("afterSubmit", ExportResponse.id);
    },
  },
  async mounted() {
    this.isLoaded = false;

    const { data: exportTemplates } = await getExportTemplates();

    this.exportTemplates = {
      options: exportTemplates,
    };

    this.isLoaded = true;
  },
});
</script>
