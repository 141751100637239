<template>
  <div class="card" :class="{ overflow: overflow, dashed: dashed }">
    <header class="card-header" v-if="title">
      <p class="card-header-title">
        <b-icon v-if="icon" :icon="icon" size="is-small" />
        <span class="title-text">{{ title }} </span>
        <slot name="subtitle"></slot>
      </p>
      <div class="card-header-actions">
        <slot name="header-actions" />
      </div>
    </header>

    <div
      class="card-content"
      :class="{ 'reset-content-padding': !contentPadding }"
    >
      <slot />
    </div>
    <footer class="card-footer" v-if="$slots['card-footer']">
      <slot name="card-footer" />
    </footer>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Card",
  props: {
    title: String,
    icon: String,
    subtitle: String,
    contentPadding: {
      type: Boolean,
      default: true,
    },
    overflow: {
      type: Boolean,
      default: false,
    },
    dashed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow {
  overflow: hidden;
}

.dashed {
  border: 1px dashed #bebebe;
}
.card-content {
  text-align: left;
}
.reset-content-padding {
  padding: 0 0 1em 0;
}

.card-header {
  font-size: 18px;

  .card-header-title {
    .title-text {
      padding-right: 1em;
    }

    .icon {
      margin-right: 0.5em;
    }
  }
}

.card-header-actions {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.card-footer {
  padding: 1rem 1rem;
  display: flex;
  justify-content: flex-end;
}
</style>
