<template>
  <b-field grouped group-multiline>
    <b-checkbox-button
      v-for="(option, key) in field.options"
      :key="key"
      :native-value="option.value"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      :disabled="disabled"
      >{{ option.label }}
      <b-icon :icon="option.icon" v-if="option.icon" />
    </b-checkbox-button>
  </b-field>
</template>

<script lang="ts">
import Vue from "vue";
import { FieldMixin } from "./FieldMixin";

export default Vue.extend({
  name: "MultiSelectField",
  mixins: [FieldMixin],
});
</script>
