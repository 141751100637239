<template>
  <div class="full-screen-loader">
    <div class="background" />
    <div class="content">
      <div class="centered-content">
        <div class="relative">
          <div class="loading-icon" />
        </div>
        <div v-if="text" class="has-text-centered is-flex">
          <span class="is-size-4">{{ text }}</span>
          <span class="is-size-4 dots"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    text: {
      default() {
        return "";
      },
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.full-screen-loader {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  position: absolute;
}

.background {
  flex: 1;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  justify-content: center;
  background-color: $color-snow;
}

.loading-icon {
  width: 5rem;
  height: 10rem;

  &::after {
    -webkit-animation: spinAround 500ms infinite linear;
    animation: spinAround 500ms infinite linear;
    border: 0.25rem solid #2b3d51;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 1.5em);
    left: calc(50% - 1.5em);
    width: 5rem;
    height: 5rem;
  }
}

.content {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
