import { getAnesthesiaTechniques, getAnesthesiaVideos } from "@/api";
import {
  AnesthesiaTechniqueField,
  AnesthesiaVideo,
} from "@/types/anesthesiaTechniques";

/**
 * This is specifically used for stale resources, preventing unnecessary requests.
 */
export const resources = {
  anesthesiaTechniques: null as null | Promise<AnesthesiaTechniqueField[]>,
  anesthesiaVideos: null as null | Promise<AnesthesiaVideo[]>,

  getAnesthesiaTechniques(): Promise<AnesthesiaTechniqueField[]> {
    if (!this.anesthesiaTechniques) {
      this.anesthesiaTechniques = getAnesthesiaTechniques().then((response) => {
        return response.data;
      });
    }

    return this.anesthesiaTechniques;
  },

  getAnesthesiaVideos(): Promise<AnesthesiaVideo[]> {
    if (!this.anesthesiaVideos) {
      this.anesthesiaVideos = getAnesthesiaVideos().then((response) => {
        return response.data;
      });
    }

    return this.anesthesiaVideos;
  },
};
