<template>
  <div v-if="warnings && warnings.length" class="warnings my-2">
    <b-message
      v-for="(warning, index) in warnings"
      :key="index"
      :type="'is-' + warning.type || 'info'"
      has-icon
      icon-size="is-medium"
      class="is-flex is-align-items-center mb-1"
    >
      {{ warning.message }}
      <slot name="after-message" />
    </b-message>
  </div>
</template>

<script>
/* eslint vue/multi-word-component-names: 0 */
import Vue from "vue";

export default Vue.extend({
  props: {
    warnings: {
      default() {
        return [];
      },
      type: Array,
    },
  },
});
</script>
<style lang="scss" scoped>
.warnings :deep(.message-body) {
  width: 100%;

  padding: 0;
  height: 46.5px;
  display: flex;
  align-items: center;

  .media,
  .media-left,
  .media-content {
    display: flex;
    align-items: center;
  }

  .media-left .icon {
    margin-left: 1em;
  }
}
</style>
