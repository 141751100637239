<template>
  <div class="prepare">
    <b-modal v-model="isOperationRiskActive">
      <div class="is-flex is-justify-content-center">
        <img src="@/assets/images/operation_risk_table.png" alt="" />
      </div>
    </b-modal>
    <b-loading :is-full-page="true" :active="isLoading" />
    <transition name="fade">
      <div class="columns" v-if="!isLoading">
        <div class="column is-half-desktop">
          <card
            :title="$t('screening_prepare_page.title').toString()"
            icon="list-ul"
          >
            <Form
              :fields="fields"
              :onSubmitClick="onSubmitClick"
              v-model="prepareData"
              :formActionType="singlePatientMode ? 'bar' : 'inline'"
            >
              <template v-slot:form-actions>
                <b-button @click="$router.push(`/patient/${patientId}`)"
                  >{{ $t("common.back") }}
                </b-button>
                <b-message
                  id="before-save-warning"
                  v-if="singlePatientMode"
                  type="is-warning"
                  has-icon
                  ><span
                    v-html="
                      richMessage(
                        $t(
                          'screening_prepare_page.before_save_warning_message'
                        ).toString()
                      )
                    "
                  ></span>
                </b-message>
              </template>
              <template v-slot:extraLabel="field">
                <a
                  v-if="field.key === 'doctor_operation_risk'"
                  class="link"
                  @click="toggleOperationRiskModal"
                >
                  {{ $t("screening_prepare_page.see_table") }}
                </a>
              </template>
            </Form>
          </card>
        </div>
        <div class="column is-half is-hidden-touch vertical-card-spacing">
          <card
            :title="$t('screening_prepare_page.patient_videos').toString()"
            icon="video"
          >
            <video-list :videos="videos" />
          </card>
          <card
            v-if="!isMdl"
            :title="
              titleize(
                $t('screening_prepare_page.anesthesia_techniques').toString()
              )
            "
            icon="list-ol"
          >
            <technique-list
              :techniques="filteredTechniques"
              :preferredTechniqueKey="preferredTechniqueKey"
            />
          </card>
        </div>

        <video-sidebar
          :videos="videos"
          :techniques="filteredTechniques"
          :preferredTechniqueKey="preferredTechniqueKey"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Card from "@/components/Card.vue";
import Form from "@/components/Form/Form.vue";
import TechniqueList from "@/components/Prepare/TechniqueList.vue";
import VideoList from "@/components/Prepare/VideoList.vue";
import {
  AnesthesiaTechniqueField,
  AnesthesiaVideo,
  VideosMap,
} from "@/types/anesthesiaTechniques";
import { PrepareData, PrepareDataFormField } from "@/types/prepare-screening";
import { getData, getForm, postFormData } from "@/api";
import VideoSidebar from "@/components/Prepare/VideoSidebar.vue";
import { resources } from "@/utils/resources";
import { find, mapKeys } from "lodash";
import { richMessage, titleize } from "@/utils/templateFilters";
import i18n from "@/i18n";

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Prepare",
  inject: ["singlePatientMode"],
  components: {
    Card,
    TechniqueList,
    VideoList,
    Form,
    VideoSidebar,
  },
  computed: {
    isMdl(): boolean {
      return !!this.fields.find((field) => field.key === "mdl_procedure");
    },
    screeningId(): number {
      return parseInt(this.$route.params.screeningId);
    },
    patientId(): number {
      return parseInt(this.$route.params.patientId);
    },
    filteredTechniques(): AnesthesiaTechniqueField[] {
      if (!this.prepareData?.anesthesia_methods?.selected_options) {
        return [];
      }

      const methods = find(this.fields, ["key", "anesthesia_methods"]);

      if (!methods) {
        return [];
      }

      const allTechniques = methods.options as AnesthesiaTechniqueField[];

      return allTechniques.filter((c: AnesthesiaTechniqueField) => {
        return this.prepareData.anesthesia_methods.selected_options.includes(
          c.value
        );
      });
    },
    preferredTechniqueKey(): string {
      return this.prepareData?.anesthesia_methods?.preferred_options
        ? this.prepareData.anesthesia_methods.preferred_options[0]
        : "";
    },
    videos(): AnesthesiaVideo[] {
      const videosMap = mapKeys(this.anesthesiaVideos, "name") as VideosMap;

      if (this.isMdl) {
        const currentVideos = [];

        if (this.prepareData.mdl_procedure === "coloscopy") {
          currentVideos.push(videosMap.coloscopy);
        }
        if (this.prepareData.mdl_procedure === "gastroscopy") {
          currentVideos.push(videosMap.gastroscopy);
        }
        if (this.prepareData.mdl_procedure === "ercp") {
          currentVideos.push(videosMap.ercp);
        }
        if (this.prepareData.mdl_procedure === "sigmoidoscopy") {
          currentVideos.push(videosMap.sigmoidoscopy);
        }

        if (this.prepareData.mdl_procedure === "cologastro") {
          currentVideos.push(videosMap.coloscopy);
          currentVideos.push(videosMap.gastroscopy);
        }

        if (this.prepareData.mdl_anesthesia === "deep") {
          currentVideos.push(videosMap.sedationonly);
        }
        if (this.prepareData.mdl_anesthesia === "full") {
          currentVideos.push(videosMap.narcose);
        }

        return [...new Set(currentVideos)];
      } else {
        // Note: filteredTechniques has a video property, these are names / keys referring to a video.
        // so here we get the actual videos objects.
        const videos = this.filteredTechniques
          .map((c) => {
            return c.videos.map((name) => {
              return videosMap[name];
            });
          })
          .flat();

        return [...new Set(videos)];
      }
    },
  },
  data() {
    return {
      titleize: titleize,
      anesthesiaTechniques: [] as AnesthesiaTechniqueField[],
      anesthesiaVideos: [] as AnesthesiaVideo[],
      prepareData: {} as PrepareData,
      valid: false,
      fields: [] as PrepareDataFormField[],
      isOperationRiskActive: false,
      isLoading: true,
    };
  },
  methods: {
    richMessage,
    toggleOperationRiskModal() {
      this.isOperationRiskActive = !this.isOperationRiskActive;
    },
    async getAnesthesiaTechniques() {
      this.anesthesiaTechniques = await resources.getAnesthesiaTechniques();
    },
    async getAnesthesiaVideos() {
      this.anesthesiaVideos = await resources.getAnesthesiaVideos();
    },
    async getPrepare() {
      try {
        const { data } = await getData<PrepareData>(
          this.screeningId,
          "prepare"
        );
        this.prepareData = { ...data };
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n.t("common.retrieve_new_data_error_message").toString(),
          type: "is-danger",
        });
      }
    },
    async getPrepareForm() {
      try {
        const { data } = await getForm(this.screeningId, "prepare");
        this.fields = data as PrepareDataFormField[];

        for (const field of this.fields) {
          (this.prepareData as Record<string, any>)[field.key] = null;
        }
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("screening_prepare_page.saving_data_error_message")
            .toString(),
          type: "is-danger",
        });
      }
    },

    async onSubmitClick() {
      try {
        await postFormData<PrepareData>(
          this.screeningId,
          "prepare",
          this.prepareData
        );
        this.$buefy.toast.open({
          message: i18n
            .t("screening_prepare_page.saved_preparation_message")
            .toString(),
          type: "is-success",
        });
        this.$router.push(`/patient/${this.patientId}`);
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            i18n
              .t("screening_prepare_page.saving_preparation_error_message")
              .toString(),
          type: "is-danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    try {
      await Promise.all([
        this.getAnesthesiaTechniques(),
        this.getAnesthesiaVideos(),
        Promise.all([
          await this.getPrepareForm(),
          // Is dependent on the prepareForm request.
          this.getPrepare(),
        ]),
      ]);
    } catch (err) {
      this.$buefy.toast.open({
        message: i18n
          .t("common.retrieving_error_message", {
            resource: i18n.t("common.preparation").toString(),
          })
          .toString(),
        type: "is-danger",
      });
    } finally {
      this.isLoading = false;
    }
  },
});
</script>
<style lang="scss" scoped>
:deep(#before-save-warning) {
  .message-body {
    padding: 0 0.5rem 0 0.5rem;

    .media-content {
      align-self: center;
    }
  }
}

.prepare {
  padding-bottom: 10em;
}

.link {
  cursor: pointer;
  color: #4c6a8e;
  text-decoration: underline;
}

:deep(.form-actions) {
  button {
    font-size: 1.1em;
  }

  &.bar {
    .content {
      //display: flex;
      //align-items: center;
      //
      > * + * {
        margin-left: 0.5rem;
      }
    }
  }
}
</style>
