import VueRouter from "vue-router";
import { getAbsoluteUri } from "@/utils/functions";

// Generates a random string of the given length.
// https://stackoverflow.com/a/27747377/358873
export function generateRandomString(length: number) {
  const arr = new Uint8Array(length / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, (d) => d.toString(16).padStart(2, "0")).join("");
}

// Session storage key used for parameters
const paramsKey = "oidc_params";

// Stores OpenID connect login parameters after a login has been initiated
export function storeParameters(
  handle: string,
  state: string,
  nonceSecret: string,
  params?: Record<string, string | number | null>
) {
  const data = {
    handle,
    state,
    nonceSecret,
    params,
  };
  sessionStorage.setItem(paramsKey, JSON.stringify(data));
}

// Fetches and clears OpenID Connect login parameters.
export function getAndClearParameters() {
  const json = sessionStorage.getItem(paramsKey);
  sessionStorage.removeItem(paramsKey);
  const data = json ? JSON.parse(json) : null;
  return {
    handle: data?.handle,
    state: data?.state,
    nonceSecret: data?.nonceSecret,
    params: data?.params,
  };
}

export function getRedirectUri(router: VueRouter, handle: string) {
  const route = router.resolve({
    name: "openIdLogin",

    // HMC uses the old redirect URI with the handle. Make sure we pass
    // the handle, so we don't generate an invalid redirect URI.
    ...(handle === "hmc" && { params: { handle } }),
  });
  return getAbsoluteUri(route);
}
