<template>
  <div>
    <b-radio v-model="localFilter" name="filter" native-value="all">
      {{ $t("report_page.report_filter.show_all") }}
    </b-radio>
    <b-radio v-model="localFilter" name="filter" native-value="danger">
      {{ $t("report_page.report_filter.show_warnings") }}
    </b-radio>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";

const props = defineProps<{
  filter: "all" | "danger";
}>();

const emit = defineEmits<{
  (e: "update:filter", v: "all" | "danger"): void;
}>();

const localFilter = ref(props.filter);

watch(
  () => localFilter.value,
  () => emit("update:filter", localFilter.value)
);
</script>
