<template>
  <div class="history-container">
    <div class="history pb-4" v-if="history.length > 0">
      <div class="event" v-for="(event, index) in history" :key="event.id">
        <div class="timestamp">
          <div class="date">{{ formatDateTime(event.created_at) }}</div>
          <div class="user" v-if="event.user_name">{{ event.user_name }}</div>
        </div>
        <div class="timeline">
          <div class="lineup" :class="{ first: index === 0 }"></div>
          <div class="dot"></div>
          <div
            class="linedown"
            :class="{ last: index === history.length - 1 }"
          ></div>
        </div>
        <div class="content">
          <div class="text">
            <span>
              {{ event.description }}
              <span v-if="event.screening_id" class="is-size-7 text-info"
                >- #{{ event.screening_id }}</span
              >
            </span>
            <b-tooltip
              v-if="event.tooltip"
              :active="!!event.tooltip.message"
              multilined
              size="is-large"
              :label="event.tooltip.message"
            >
              <b-icon
                size="is-small"
                :icon="eventIcon(event)"
                :type="eventType(event)"
              ></b-icon>
            </b-tooltip>
          </div>
          <div class="subtext">
            {{ event.sub_text }}
          </div>
        </div>
      </div>
    </div>
    <span class="is-italic" v-else>
      {{ $t("history.empty_patient_history_message") }}</span
    >
  </div>
</template>

<script setup lang="ts">
/* eslint vue/multi-word-component-names: 0 */
import { Log } from "@/types/logs";
import { formatDateTime } from "@/filters/date";

defineProps<{
  history: Log[];
}>();

function eventIcon(log: Log): string | null {
  if (!log.tooltip) {
    return null;
  }

  if (log.tooltip.type === "error") {
    return "exclamation-triangle";
  }

  if (log.tooltip.type === "warning") {
    return "exclamation-triangle";
  }

  if (log.tooltip.type === "success") {
    return "check-circle";
  }

  return "info-circle";
}

function eventType(log: Log): string | null {
  if (!log.tooltip?.color) {
    return null;
  }

  if (log.tooltip.type === "error") {
    return "type-danger";
  }

  if (log.tooltip.type === "warning") {
    return "type-warning";
  }

  if (log.tooltip.type === "success") {
    return "type-success";
  }

  return "type-info";
}
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.history-container {
  font-size: $body-size;
  display: flex;
  justify-content: flex-start;
  padding-top: 1em;
}

.event {
  display: flex;
  flex-direction: row;

  &:last-of-type {
    margin-bottom: -3em;
  }

  .timestamp {
    width: 150px;
    padding-top: 8px;
    text-align: right;
    display: flex;
    flex-direction: column;

    color: #a5a5a5;
    opacity: 1;
  }
}

.timeline {
  margin: 0 12px 0 24px;

  .dot {
    height: 12px;
    width: 12px;
    background-color: $primary;
    border-radius: 6px;
  }

  .linedown,
  .lineup {
    margin-left: 5px;
    width: 2px;
    background-color: $primary;
  }

  .linedown {
    height: 60px;
  }

  .lineup {
    height: 12px;
  }

  .lineup.first {
    background-color: white;
  }

  .linedown.last {
    background-color: white;
    visibility: hidden;
  }
}

.content {
  margin: 0 0 0 12px;
  padding-top: 6px;
  flex: 1;

  .subtext {
    color: #a5a5a5;
    max-width: 580px;
    white-space: pre-line;
  }

  .text {
    display: flex;
    flex-direction: row;
    align-items: center;

    .b-tooltip {
      margin-left: 0.5rem;
    }

    :deep(.b-tooltip) {
      // The tooltip trigger (but not the icon) will fill the whole
      // height with its default display: block, putting the icon
      // at its top, out of alignment. With display: flex or inline-flex,
      // it instead wraps the icon. However, it still looks out of alignment,
      // which may be optical only, but moving it up 1px looks better.
      .tooltip-trigger {
        display: flex;
        margin-top: -1px;

        .icon {
          color: #a5a5a5;
        }
      }

      .tooltip-content {
        white-space: pre-line;
        text-align: left;
      }
    }
  }

  .text-info {
    color: #a5a5a5;
  }
}

// }
</style>
