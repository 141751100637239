export interface Report {
  columns: ReportColumn[];
  created_at: string;
  id: number;
  screening_id: number;
  show_medication_tab: boolean;
  show_summary_tab?: boolean;
  sbar_summary?: boolean;
  show_nurse_intake_tab?: boolean;
  updated_at: string;
  version: number;
  versions: Array<any>;
  warnings: Array<Warning>;
}

export interface Warning {
  message: string;
  type: FieldHighlight;
}

export enum ReportColumnType {
  left = "left",
  right = "right",
  sbar = "sbar",
  nurseIntake = "nurse",
}

export interface ReportColumn {
  created_at: string;
  id: number;
  sections: Array<ReportSection>;
  title: string;
  type: ReportColumnType;
  updated_at: string;

  // Summary text, if available. Older style summaries
  // may have the summary text on the first section instead.
  text?: string;
}

export enum ReportSectionType {
  left = "left",
  right = "right",
  sbar = "sbar",
  medication = "medication",
}

export interface ReportSection {
  blocks: ReportBlock[];
  fields?: ReportField[];
  changes: ReportChange[];
  created_at: string;
  icon: string;
  id: number;
  is_editable: boolean;
  title: string;
  updated_at: string;
  text: string;
  type?: ReportSectionType;
}

export interface ReportBlock {
  id: number;
  fields: ReportField[];
  title: string;
  updated_at: string;
}

export interface ReportChange {
  action: ReportChangeActionType;
  id: number;
  new: {
    description: string;
    highlight: FieldHighlight;
    label: string;
    type: FieldType;
    value: string;
  };
  old: {
    description: string;
    highlight: FieldHighlight;
    label: string;
    type: FieldType;
    value: string;
  };
  timestamp: string;
  user_id: string;
  user_name: string;
  version: number;
}

export enum ReportChangeActionType {
  change = "change",
  create = "create",
  delete = "delete",
}

export interface ReportField {
  id: number;
  description: string;
  highlight: FieldHighlight;
  label: string;
  type: FieldType;
  value: string;
  updated_at: string;
  created_at: string;
}

export enum FieldHighlight {
  DANGER = "danger",
  WARNING = "warning",
  NONE = "none",
  INFO = "info",
}

export enum FieldType {
  TEXT = "text",
  INLINE_TEXT = "inline-text",
  LIST = "list",
  HEADER = "header",
}

export function fieldHasWarnings(field: ReportField): boolean {
  return ["warning", "danger"].includes(field.highlight);
}
