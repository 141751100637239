<template>
  <b-datepicker
    :placeholder="field.placeholder"
    :value="parseDate(value)"
    @input="$emit('input', stripTime($event))"
    :date-parser="parseInput"
    @blur="$emit('blur', $event)"
    :date-formatter="formatDate"
    :inline="!!field?.is_inline"
    :disabled="disabled"
    :append-to-body="!!field && !field.is_inline"
    :position="pickerPosition"
    :first-day-of-week="1"
    editable
  />
</template>

<script lang="ts">
import { format } from "date-fns";
import Vue from "vue";
import { FieldMixin } from "./FieldMixin";
import { parseDateInput } from "@/utils/input";

export default Vue.extend({
  name: "DateField",
  mixins: [FieldMixin],
  props: {
    pickerPosition: {
      type: String,
      default: "is-bottom-right",
    },
  },
  methods: {
    parseDate(date: string) {
      if (date) {
        return new Date(date);
      }
    },
    parseInput(date: string) {
      return parseDateInput(date);
    },
    formatDate(date: Date) {
      if (date) {
        return format(date, "dd-MM-yyyy");
      }
    },
    stripTime(date: Date) {
      if (date) {
        return format(date, "yyyy-MM-dd");
      }
    },
  },
});
</script>
