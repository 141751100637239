<template>
  <div>
    <div
      class="b-steps is-small is-flex is-align-items-center"
      :style="stepperStyle"
    >
      <nav class="steps is-animated is-rounded">
        <ul class="step-items">
          <li
            class="step-item"
            v-for="(item, index) in progressItems"
            :key="item.icon"
            :class="{
              'is-previous': item.status === 'finished',
              'is-active': index === nextItemIndex,
            }"
          >
            <b-tooltip :label="item.tooltip">
              <a class="step-link">
                <div class="step-marker">
                  <b-icon :icon="item.icon" size="is-small"></b-icon>
                </div>

                <div class="step-details">
                  <span class="step-title" v-if="item.label">
                    {{ item.label }}
                  </span>
                </div>
              </a>
            </b-tooltip>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { Step } from "@/types/generic";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ProgressBar",
  props: {
    progressItems: {
      type: Array as PropType<Step[]>,
      default: () => [],
    },
  },
  computed: {
    stepperStyle() {
      return {
        width: `${
          // @ts-ignore
          this.progressItems?.length > 0 ? this.progressItems.length * 50 : 0
        }px`,
      };
    },
    nextItemIndex() {
      return this.progressItems.findIndex((item) => item.status === "pending");
    },
  },
});
</script>

<style lang="scss" scoped>
.step-link {
  cursor: auto !important;
}

.step-item {
  padding: 0 1em;

  &::before {
    top: 1.15em !important;
  }
}

.step-title {
  font-size: 10px !important;
}

.step-marker {
  width: 25px !important;
  height: 25px !important;
}

.step-item.is-previous .step-marker {
  border-color: #ffffff !important;
}

nav {
  padding: 0;
}
</style>
