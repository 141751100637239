var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"prepare"},[_c('b-modal',{model:{value:(_vm.isOperationRiskActive),callback:function ($$v) {_vm.isOperationRiskActive=$$v},expression:"isOperationRiskActive"}},[_c('div',{staticClass:"is-flex is-justify-content-center"},[_c('img',{attrs:{"src":require("@/assets/images/operation_risk_table.png"),"alt":""}})])]),_c('b-loading',{attrs:{"is-full-page":true,"active":_vm.isLoading}}),_c('transition',{attrs:{"name":"fade"}},[(!_vm.isLoading)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half-desktop"},[_c('card',{attrs:{"title":_vm.$t('screening_prepare_page.title').toString(),"icon":"list-ul"}},[_c('Form',{attrs:{"fields":_vm.fields,"onSubmitClick":_vm.onSubmitClick,"formActionType":_vm.singlePatientMode ? 'bar' : 'inline'},scopedSlots:_vm._u([{key:"form-actions",fn:function(){return [_c('b-button',{on:{"click":function($event){return _vm.$router.push(`/patient/${_vm.patientId}`)}}},[_vm._v(_vm._s(_vm.$t("common.back"))+" ")]),(_vm.singlePatientMode)?_c('b-message',{attrs:{"id":"before-save-warning","type":"is-warning","has-icon":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.richMessage(
                      _vm.$t(
                        'screening_prepare_page.before_save_warning_message'
                      ).toString()
                    )
                  )}})]):_vm._e()]},proxy:true},{key:"extraLabel",fn:function(field){return [(field.key === 'doctor_operation_risk')?_c('a',{staticClass:"link",on:{"click":_vm.toggleOperationRiskModal}},[_vm._v(" "+_vm._s(_vm.$t("screening_prepare_page.see_table"))+" ")]):_vm._e()]}}],null,false,1345222436),model:{value:(_vm.prepareData),callback:function ($$v) {_vm.prepareData=$$v},expression:"prepareData"}})],1)],1),_c('div',{staticClass:"column is-half is-hidden-touch vertical-card-spacing"},[_c('card',{attrs:{"title":_vm.$t('screening_prepare_page.patient_videos').toString(),"icon":"video"}},[_c('video-list',{attrs:{"videos":_vm.videos}})],1),(!_vm.isMdl)?_c('card',{attrs:{"title":_vm.titleize(
              _vm.$t('screening_prepare_page.anesthesia_techniques').toString()
            ),"icon":"list-ol"}},[_c('technique-list',{attrs:{"techniques":_vm.filteredTechniques,"preferredTechniqueKey":_vm.preferredTechniqueKey}})],1):_vm._e()],1),_c('video-sidebar',{attrs:{"videos":_vm.videos,"techniques":_vm.filteredTechniques,"preferredTechniqueKey":_vm.preferredTechniqueKey}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }