<template>
  <div class="tenant-switcher">
    <b-button
      v-if="impersonated && showSwitcher"
      @click="switchBack"
      type="is-warning is-light"
      class="button-with-icon"
      expanded
    >
      <b-icon icon="arrow-left" />
      <span>
        {{ $t("tenant_switcher.switch_back") }}
      </span>
    </b-button>
    <b-menu v-else-if="showSwitcher">
      <b-menu-list
        :label="
          $t('tenant_switcher.switch_to_test_environment_label').toString()
        "
      >
        <template v-for="(tenant, index) in profile.user_tenants">
          <template v-if="tenant.allow_role_select">
            <b-menu-item class="tenant" :key="index">
              <template #label="props">
                {{ tenant.name }}
                <b-icon
                  class="is-pulled-right"
                  :icon="props.expanded ? 'chevron-up' : 'chevron-down'"
                ></b-icon>
              </template>
              <b-menu-item
                @click="switchToTenant(tenant, role)"
                v-for="(role, roleIndex) in tenant.roles"
                :key="roleIndex"
                icon="user"
                :label="role.label"
              ></b-menu-item>
            </b-menu-item>
          </template>
          <template v-else>
            <b-menu-item
              @click="switchToTenant(tenant)"
              class="tenant"
              :key="index"
              icon="hospital"
              :label="tenant.name"
            ></b-menu-item>
          </template>
        </template>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
import Vue from "vue";
import { switchBackToTenant, switchTenant, switchTenantWithRole } from "@/api";
import {
  currentUser,
  setJWT,
  showInternalAdminSwitcher,
} from "@/utils/authUtil";

import { determineLandingRoute } from "@/router";

export default Vue.extend({
  props: {
    profile: {
      type: Object,
    },
  },
  computed: {
    showSwitcher() {
      return !!this.profile && showInternalAdminSwitcher(this.profile);
    },
    impersonated: function () {
      return this.profile && this.profile.impersonated;
    },
  },
  methods: {
    async switchBack() {
      const response = await switchBackToTenant();
      const jwt = response.data;

      setJWT(jwt);

      this.goToLandingRouteForUser();
    },
    async switchToTenant(tenant, role) {
      const response = role
        ? await switchTenantWithRole(tenant.id, role.value)
        : await switchTenant(tenant.id);
      const jwt = response.data;

      setJWT(jwt);

      this.goToLandingRouteForUser();
    },
    goToLandingRouteForUser() {
      const path = determineLandingRoute(currentUser.value);

      this.$router
        .push({ path: path, query: { timestamp: Date.now() } })
        .then(() => {
          this.$emit("afterSwitch");
        });
    },
  },
});
</script>

<style lang="scss" scoped></style>
