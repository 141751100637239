export const resizeTextarea = {
  inserted: function (el: HTMLElement) {
    const textarea = el.children[0] as HTMLTextAreaElement;
    const height = textarea.scrollHeight - 1.5;
    textarea.style.height = `${height}px`;
    textarea.style.overflowY = "hidden";

    textarea.addEventListener("input", OnInput, false);

    function OnInput(this: any) {
      this.style.height = "auto";
      this.style.height = textarea.scrollHeight - 1.5 + "px";
    }
  },
};
