export type PromiseOr<T> = Promise<T> | T;

// Returns a function that calls the given `on` function at the start
// of a callback, and the `off` function when the callback has completed.
export default function useToggler(on: () => void, off: () => void) {
  return function <T>(f: () => PromiseOr<T>) {
    on();
    let resultIsPromise = false;
    try {
      const result = f();

      if (result && "then" in result) {
        // Result is a promise
        resultIsPromise = true;
        return result.then(
          (value) => {
            off();
            return value;
          },
          (err) => {
            off();
            throw err;
          }
        );
      }

      return result;
    } finally {
      if (!resultIsPromise) {
        off();
      }
    }
  };
}
