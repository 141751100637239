<template>
  <div class="report-container">
    <Portal to="subheader-actions" key="patient-actions">
      <b-button
        @click="saveAllEditedSectionsWithConfirm"
        v-if="changed"
        type="is-primary"
        :loading="isSavingSections"
        icon-left="save"
      >
        {{ $t("common.save_changes_message") }}
      </b-button>
    </Portal>
    <div class="tabs-container">
      <div class="report-filter-container">
        <div class="report-filter">
          <report-filter
            :filter.sync="filter"
            v-if="currentTab === 'full-report-tab'"
          />
          <b-button
            icon-left="copy"
            icon-pack="fal"
            class="filter-button"
            @click="copySummary(reportSummary)"
            v-if="
              showSummaryTab &&
              summaryPresent &&
              currentTab !== 'nurse-intake-tab'
            "
          >
            {{ $t("report_card.actions.copy_summary") }}
          </b-button>

          <b-button
            icon-left="copy"
            icon-pack="fal"
            class="filter-button"
            @click="copySummary(nurseIntake)"
            v-if="
              showNurseIntakeTab &&
              nurseIntakePresent &&
              currentTab === 'nurse-intake-tab'
            "
          >
            {{ $t("report_card.actions.copy_nurse_intake") }}
          </b-button>

          <b-button
            v-if="hasVideoCalling"
            icon-left="phone"
            class="filter-button"
            @click="promptStartVideoCall"
          >
            {{ $t("video_call.button") }}
          </b-button>
        </div>
      </div>
      <b-tabs class="report-tabs" v-model="currentTab">
        <!-- Full report -->
        <b-tab-item
          value="full-report-tab"
          :label="titleize($t('common.report'))"
          icon="book-medical"
        >
          <b-loading :is-full-page="true" :active="isLoadingReport" />

          <Warnings v-if="report.warnings" :warnings="report.warnings" />

          <transition name="fade">
            <template>
              <div class="columns" v-if="reportPresent">
                <div
                  class="column is-half vertical-card-spacing"
                  :class="
                    filterWarnings(columnIndex) ? 'column-filter-warnings' : ''
                  "
                  v-for="(column, columnIndex) in mainColumns"
                  :key="column.id"
                >
                  <template
                    v-if="
                      columnIndex === 0 && $route.name === 'screening-process'
                    "
                  >
                    <card
                      :title="titleize($t('common.conclude'))"
                      icon="book-medical"
                    >
                      <Form
                        :fields="fields"
                        :onSubmitClick="onSubmitClick"
                        :isSubmitDisabled="isSubmitDisabled"
                        v-model="processData"
                      >
                      </Form>
                    </card>
                  </template>
                  <template v-for="section in column.sections">
                    <report-card
                      :filter-warnings="filterWarnings(columnIndex)"
                      :section="sectionData(section)"
                      :key="section.id"
                      :is-dirty="sectionIsDirty(section.id)"
                      :is-editing="sectionIsEditing(section.id)"
                      @edit-section="startEditSection(section.id)"
                      @save-section="saveSection(section.id)"
                      @restore-section="restoreSection(section.id)"
                    />
                  </template>
                </div>
              </div>
            </template>
          </transition>
        </b-tab-item>
        <!-- Regular summary -->
        <b-tab-item
          v-if="showSummaryTab && !hasSbarSummary"
          value="regular-summary-tab"
          :label="titleize($t('common.summary'))"
          icon="notes-medical"
        >
          <b-loading :is-full-page="true" :active="isLoadingSummary" />

          <Warnings v-if="report.warnings" :warnings="report.warnings" />

          <transition name="fade">
            <template v-if="summaryPresent">
              <div class="columns">
                <div class="column is-full vertical-card-spacing">
                  <report-card
                    v-for="section in reportSummary.sections"
                    :is-editing="false"
                    :section="sectionData(section)"
                    :key="section.id"
                    :is-summary="true"
                  />
                </div>
              </div>
            </template>
          </transition>
        </b-tab-item>

        <!-- SBAR-style summary -->
        <b-tab-item
          value="sbar-summary-tab"
          v-if="showSummaryTab && hasSbarSummary"
          :label="titleize($t('common.summary'))"
          icon="notes-medical"
        >
          <b-loading
            :is-full-page="true"
            :active="isLoadingReport || isLoadingSummary"
          />
          <Warnings v-if="report.warnings" :warnings="report.warnings" />

          <transition name="fade">
            <template v-if="summaryPresent">
              <div class="columns">
                <div class="column is-full vertical-card-spacing">
                  <report-card
                    v-for="section in reportSummary.sections"
                    :is-summary="true"
                    :summary-text="reportSummary.text"
                    :section="sectionData(section)"
                    :key="section.id"
                    :is-dirty="sectionIsDirty(section.id)"
                    :is-editing="sectionIsEditing(section.id)"
                    @edit-section="startEditSection(section.id)"
                    @save-section="saveSection(section.id)"
                    @restore-section="restoreSection(section.id)"
                  />
                </div>
              </div>
            </template>
          </transition>
        </b-tab-item>

        <!-- Medication -->
        <b-tab-item
          value="medication-tab"
          v-if="medicationColumns"
          :label="titleize($t('common.medication'))"
          icon="pills"
        >
          <b-loading :is-full-page="true" :active="isLoadingMedication" />

          <Warnings v-if="report.warnings" :warnings="report.warnings" />

          <transition name="fade">
            <div class="columns" v-if="!isLoadingReport">
              <div
                class="column is-half vertical-card-spacing"
                v-for="column in medicationColumns"
                :key="column.id"
              >
                <template
                  v-for="section in medicationSections(column.sections)"
                >
                  <report-card
                    :is-editing="sectionIsEditing(section.id)"
                    :is-dirty="sectionIsDirty(section.id)"
                    :section="sectionData(section)"
                    :key="section.id"
                    @edit-section="startEditSection(section.id)"
                    @save-section="saveSection(section.id)"
                    @restore-section="restoreSection(section.id)"
                  />
                </template>
              </div>
            </div>
          </transition>
        </b-tab-item>

        <!-- Nurse intake -->
        <b-tab-item
          value="nurse-intake-tab"
          :label="nurseColumnTitle"
          icon="notes-medical"
          v-if="showNurseIntakeTab && nurseIntakeColumns"
        >
          <b-loading
            :is-full-page="true"
            :active="isLoadingReport || isLoadingNurseIntake"
          />

          <Warnings v-if="report.warnings" :warnings="report.warnings" />

          <transition name="fade">
            <div class="columns">
              <div
                class="column is-full vertical-card-spacing"
                v-for="column in nurseIntakeColumns"
                :key="column.id"
              >
                <report-card
                  v-for="section in column.sections"
                  :key="section.id"
                  :is-editing="sectionIsEditing(section.id)"
                  :is-dirty="sectionIsDirty(section.id)"
                  :section="sectionData(section)"
                  @edit-section="startEditSection(section.id)"
                  @save-section="saveSection(section.id)"
                  @restore-section="restoreSection(section.id)"
                ></report-card>
              </div>
            </div>
          </transition>
        </b-tab-item>

        <!-- Questionnaire -->
        <b-tab-item
          value="questionnaire-tab"
          :label="titleize($t('common.questionnaire'))"
          icon="clipboard-list"
        >
          <b-loading :is-full-page="true" :active="isLoadingQuestionnaire" />

          <Warnings v-if="report.warnings" :warnings="report.warnings" />

          <transition name="fade">
            <div class="vertical-card-spacing" v-if="!isLoadingQuestionnaire">
              <card
                v-for="(section, index) in questionnaire.sections"
                :key="index"
                :title="section.title"
                icon="clipboard-list"
              >
                <inline-text-presentation
                  v-for="(row, index) in section.answers"
                  bold="value"
                  layout="columns"
                  :key="index"
                  :label="row.question"
                  class="questionnaire-row"
                >
                  <template v-slot:value_slot="{ bold, layout }">
                    <span
                      :class="{
                        'is-bold': bold === 'value',
                        column: layout === 'columns',
                      }"
                      class="value"
                      ><span>{{ isNil(row.answer) ? "-" : row.answer }}</span>
                      <b-collapse
                        v-if="row.answer_translated"
                        :aria-id="index + '-collapse'"
                        :open="openTranslationIndex === index"
                        @open="openTranslationIndex = index"
                        animation="none"
                        class="translation-collapse"
                        position="is-bottom"
                      >
                        <template #trigger="props">
                          <a :aria-controls="index + '-translation'">
                            <b-icon
                              :icon="props.open ? 'arrow-up' : 'angle-down'"
                            ></b-icon>
                            {{
                              props.open
                                ? $t("common.hide")
                                : $t("common.translated_value")
                            }}
                          </a>
                        </template>
                        <p class="translated-answer">
                          {{ row.answer_translated }}
                          <b-tooltip
                            :label="
                              $t(
                                'report_page.translated_by_google_translate'
                              ).toString()
                            "
                            type="is-primary"
                            position="is-bottom"
                          >
                            <b-icon icon="question-circle" />
                          </b-tooltip>
                        </p>
                      </b-collapse>
                    </span>
                  </template>
                </inline-text-presentation>
              </card>
            </div>
          </transition>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint vue/multi-word-component-names: 0 */
import Vue from "vue";
import {
  createCall,
  getData,
  getForm,
  getQuestionnaire,
  getReportSummary,
  getNurseIntake,
  getTenantSettings,
  postFormData,
  saveReportSection,
  saveReportSections,
} from "@/api";
import {
  Report,
  ReportColumn,
  ReportColumnType,
  ReportSection,
  ReportSectionType,
} from "@/types/report";
import ReportCard from "@/components/ReportCard.vue";
import ReportFilter from "@/components/ReportFilter.vue";
import Form from "@/components/Form/Form.vue";
import { cloneDeep, isEmpty, isEqual, isNil, some } from "lodash";
import InlineTextPresentation from "@/components/InlineTextPresentation.vue";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

import Card from "@/components/Card.vue";
import { ProcessData } from "@/types/process-screening";
import { FormField } from "@/types/form";
import { Route } from "vue-router";
import { NavigationGuardNext } from "vue-router/types/router";
import Warnings from "@/components/Common/Warnings.vue";
import i18n from "@/i18n";
import { titleize } from "@/utils/templateFilters";
import { TenantSettings } from "@/types/profile";

export default Vue.extend({
  name: "Report",
  components: {
    Warnings,
    ReportCard,
    ReportFilter,
    InlineTextPresentation,
    Card,
    Form,
  },
  inject: ["isLocked"],
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.guard(next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(next);
  },
  data() {
    return {
      openTranslationIndex: 0,
      isSubmitDisabled: false,
      isLoadingReport: true,
      isLoadingSummary: false,
      isLoadingNurseIntake: false,
      isLoadingMedication: false,
      isLoadingQuestionnaire: true,
      isSavingSections: false,
      isLoading: false,
      report: {} as Report,
      reportSummary: {} as ReportColumn,
      nurseIntake: {} as ReportColumn,
      questionnaire: {},
      processData: {} as ProcessData,
      filter: "all" as "all" | "danger",
      fields: [] as FormField[],
      tenantSettings: null as null | TenantSettings,
      currentTab: "full-report-tab",

      // Backup of the report how it is currently known to the backend
      // (as far as we're aware).
      abortController: null as AbortController | null,

      // Sections that are currently being edited
      editingSections: {} as Record<string, ReportSection>,
    };
  },
  computed: {
    screeningId(): number {
      return parseInt(this.$route.params?.screeningId);
    },
    patientId(): number {
      return parseInt(this.$route.params?.patientId);
    },
    reportPresent(): boolean {
      return !!this.report?.columns;
    },
    summaryPresent(): boolean {
      return !!this.reportSummary?.sections;
    },
    nurseIntakePresent(): boolean {
      return !!this.nurseIntake?.sections;
    },
    changed(): boolean {
      const sections = Object.values(this.editingSections);
      if (!sections.length) {
        // Not editing any sections
        return false;
      }

      return some(sections, (section) => this.sectionIsDirty(section.id));
    },

    hasSbarSummary(): boolean {
      return !!this.report?.sbar_summary;
    },

    hasVideoCalling(): boolean {
      return !!this.tenantSettings?.feature_flags?.includes("video-call");
    },

    // Left / right columns for the main report
    mainColumns(): ReportColumn[] | null {
      if (!this.report?.columns) {
        return null;
      }

      return this.report.columns.filter(
        (column) =>
          column.type === ReportColumnType.left ||
          column.type === ReportColumnType.right
      );
    },

    medicationColumns(): ReportColumn[] | null {
      if (
        !this.report ||
        (this.report &&
          (isEmpty(this.report?.columns) || !this.report.show_medication_tab))
      ) {
        return null;
      }

      return this.report.columns.filter((column) => {
        return some(
          column.sections,
          (section: ReportSection) =>
            section.type === ReportSectionType.medication
        );
      });
    },

    nurseIntakeColumns(): ReportColumn[] | null {
      if (!this.report?.columns) {
        return null;
      }

      return this.report.columns.filter(
        (column) => column.type === ReportColumnType.nurseIntake
      );
    },

    nurseColumnTitle(): string | null {
      const columns = this.nurseIntakeColumns;
      if (!columns?.length) {
        return null;
      }

      return columns[0].title;
    },

    // Whether the summary tab should be shown. We show it if the
    // report has been loaded and the tab has not been explicitly
    // disabled.
    showSummaryTab(): boolean {
      // this.report is set to an empty object at start for some reason,
      // can't very well change that without inducing unwanted side effects.
      // Check for a property that'll only be present if the report is loaded.
      return (
        !!this.report?.screening_id && this.report.show_summary_tab !== false
      );
    },

    showNurseIntakeTab(): boolean {
      return (
        !!this.report?.screening_id &&
        this.report.show_nurse_intake_tab !== false
      );
    },
  },
  methods: {
    titleize,
    guard(next: NavigationGuardNext): void {
      if (this.changed) {
        this.$buefy.dialog.confirm({
          title: i18n.t("dialog.open_changes.title").toString(),
          message: i18n.t("dialog.open_changes.message").toString(),
          cancelText: i18n.t("dialog.open_changes.cancel").toString(),
          confirmText: i18n.t("dialog.open_changes.confirm").toString(),
          type: "is-danger",
          onConfirm: () => {
            next();
          },
          onCancel: () => {
            next(false);
          },
        });
      } else {
        next();
      }
    },

    copySummary(summary: ReportColumn | null | undefined) {
      if (!summary) {
        return;
      }

      const text = summary.text ?? summary.sections[0].text;

      (this as any).$clipboard(text);
      this.$buefy.toast.open({
        message: i18n
          .t("common.summary_copied_to_clipboard_message")
          .toString(),
        type: "is-success",
      });
    },

    // Whether the "danger" filter can be active for the given column.
    columnCanBeFiltered(columnIndex: number) {
      return columnIndex === 1;
    },

    filterWarnings(columIndex: number) {
      return this.filter === "danger" && this.columnCanBeFiltered(columIndex);
    },

    medicationSections(sections: ReportSection[]) {
      return sections.filter((section) => section.type === "medication");
    },

    // Fetches the reference data for the section with the given ID
    findSection(sectionId: number) {
      return this.report.columns
        .map((column) => column.sections)
        .flat()
        .find((section) => section.id === sectionId);
    },

    // Start editing the given section
    startEditSection(sectionId: number) {
      if (this.sectionIsEditing(sectionId)) {
        return;
      }

      const section = this.findSection(sectionId);
      this.$set(this.editingSections, sectionId, cloneDeep(section));
    },

    // Returns whether the given section has active changes
    sectionIsDirty(sectionId: number) {
      const editing = this.editingSections[sectionId];
      const cached = this.findSection(sectionId);
      return editing && !isEqual(editing, cached);
    },

    // Returns whether the given section is currently editing
    sectionIsEditing(sectionId: number) {
      return !!this.editingSections[sectionId];
    },

    // Cancels editing of the given section
    restoreSection(sectionId: number) {
      if (!this.sectionIsEditing(sectionId)) {
        return;
      }

      this.$delete(this.editingSections, sectionId);
    },

    // Returns the section edit object if the given section is currently
    // being edited, otherwise the original section object.
    sectionData(section: ReportSection) {
      return this.editingSections[section.id] || section;
    },

    async saveSection(sectionId: number) {
      if (!this.sectionIsEditing(sectionId)) {
        return;
      }

      const section = this.editingSections[sectionId];
      const column = this.report.columns.find((column) =>
        column.sections.some((section) => section.id === sectionId)
      )!;
      const sectionIndex = column.sections.findIndex(
        (section) => section.id === sectionId
      );

      try {
        const { data: updatedSection } = await saveReportSection(
          this.screeningId,
          section
        );

        this.$set(column.sections, sectionIndex, updatedSection);

        await Promise.all([this.getAllSummaries()]);

        this.$buefy.toast.open({
          message: i18n.t("report_page.saved_report_message").toString(),
          type: "is-success",
        });

        // The source of truth has been updated, so restore simply
        // closes edit mode for the section.
        this.restoreSection(sectionId);
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            i18n.t("report_page.saving_report_error_message").toString(),
          type: "is-danger",
        });
      }
    },

    saveAllEditedSectionsWithConfirm() {
      this.$buefy.dialog.confirm({
        title: i18n.t("report_page.dialog.save_sections.title").toString(),
        message: i18n.t("report_page.dialog.save_sections.message").toString(),
        cancelText: i18n
          .t("report_page.dialog.save_sections.cancel")
          .toString(),
        confirmText: i18n
          .t("report_page.dialog.save_sections.confirm")
          .toString(),
        type: "is-success",
        onConfirm: this.saveAllEditedSections,
      });
    },

    // Cancels all currently editing sections
    cancelAllEditing() {
      for (const sectionId of Object.keys(this.editingSections)) {
        this.$delete(this.editingSections, sectionId);
      }
    },

    // Saves all sections that have changes
    async saveAllEditedSections() {
      try {
        this.isSavingSections = true;

        const editedSections = Object.values(this.editingSections).filter((s) =>
          this.sectionIsDirty(s.id)
        );

        if (!editedSections.length) {
          this.cancelAllEditing();
          return;
        }

        // Save edited sections
        await saveReportSections(this.screeningId, editedSections);

        // Fetch the updated report and summary
        await Promise.all([this.getReport(), this.getAllSummaries()]);
        this.cancelAllEditing();
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("report_page.saving_report_sections_error_message")
            .toString(),
          type: "is-danger",
        });
      } finally {
        this.isSavingSections = false;
      }
    },
    async getReport() {
      this.isLoadingReport = true;
      this.abortController = new AbortController();

      if (!this.$route.params?.screeningId) {
        throw Error("ignore");
      }

      const { data } = await getData<Report>(this.screeningId, "report", {
        signal: this.abortController.signal,
      } as AxiosRequestConfig);

      // if the screeningId is not the same after the request has been done.
      if (data.screening_id !== parseInt(this.$route.params?.screeningId)) {
        return;
      }

      this.report = data;
      this.isLoadingReport = false;
    },
    async getAllSummaries() {
      await Promise.all([this.getReportSummary(), this.getNurseIntake()]);
    },
    async getReportSummary() {
      if (!this.report.show_summary_tab) {
        return;
      }

      this.isLoadingSummary = true;

      if (!this.$route.params?.screeningId) {
        throw Error("ignore");
      }

      const { data } = await getReportSummary(this.screeningId, {
        signal: this.abortController?.signal,
      } as AxiosRequestConfig);

      this.reportSummary = data;
      this.isLoadingSummary = false;
    },
    async getNurseIntake() {
      if (!this.report.show_nurse_intake_tab) {
        return;
      }

      this.isLoadingNurseIntake = true;

      const { data } = await getNurseIntake(this.screeningId, {
        signal: this.abortController?.signal,
      });

      this.nurseIntake = data;
      this.isLoadingNurseIntake = false;
    },
    async getQuestionnaire() {
      this.isLoadingQuestionnaire = true;

      if (!this.$route.params?.screeningId) {
        throw Error("ignore");
      }

      const { data } = await getQuestionnaire(this.screeningId, {
        signal: this.abortController?.signal,
      } as AxiosRequestConfig);

      this.questionnaire = data;
      this.isLoadingQuestionnaire = false;
    },
    async getProcessForm() {
      try {
        const { data } = await getForm(this.screeningId, "process");
        const { data: processData } = await getData<ProcessData>(
          this.screeningId,
          "process"
        );
        this.fields = data;
        this.processData = processData;
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("common.retrieving_error_message", {
              resource: i18n.t("common.conclude_form").toString(),
            })
            .toString(),
          type: "is-danger",
        });
      }
    },

    async onSubmitClick() {
      this.isSubmitDisabled = true;
      try {
        await postFormData<ProcessData>(
          this.screeningId,
          "process",
          this.processData
        );
        this.$buefy.toast.open({
          message: i18n.t("report_page.saved_conclude_message").toString(),
          type: "is-success",
        });
        this.$router.push(`/patient/${this.patientId}`);
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            i18n
              .t("common.retrieving_error_message", {
                resource: i18n.t("common.preparation").toString(),
              })
              .toString(),
          type: "is-danger",
        });
      } finally {
        this.isSubmitDisabled = false;
      }
    },

    async getTenantSettings() {
      this.tenantSettings = await getTenantSettings();
    },

    async getData() {
      this.getTenantSettings();

      try {
        if (this.$route.name === "screening-process") {
          await this.getProcessForm();
        }

        await this.getReport();

        await Promise.all([this.getQuestionnaire(), this.getAllSummaries()]);
      } catch (err) {
        if ((err as AxiosError).message === "ignore" || axios.isCancel(err)) {
          return;
        }

        this.$buefy.toast.open({
          message: i18n
            .t("report_page.retrieving_report_error_message")
            .toString(),
          type: "is-danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    isNil(v: any) {
      return isNil(v);
    },
    promptStartVideoCall() {
      this.$buefy.dialog.confirm({
        title: i18n.t("video_call.title").toString(),
        message: i18n.t("video_call.message").toString(),
        confirmText: i18n.t("video_call.start").toString(),
        cancelText: i18n.t("common.cancel").toString(),
        type: "is-success",
        onConfirm: this.startVideoCall,
      });
    },

    async startVideoCall() {
      const screeningId = this.$route.params["screeningId"];
      const params = await createCall(screeningId);
      const routeData = this.$router.resolve({
        name: "call-host",
        params: {
          callId: params.id.toString(),
          key: params.host_key,
        },
      });

      window.open(routeData.href, "_blank");
    },
  },
  watch: {
    isLocked: {
      handler: function ({ value }) {
        if (!value) {
          this.getData();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
});
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.report-container {
  // Set the default padding of main-content, but let the
  // tabs consume the top padding. This way the content doesn't
  // bleed through the padding when the tabs are sticky.
  padding: 0 1em 1em 1em;
}

.questionnaire-row {
  .translation-collapse a {
    color: $primary;
  }
}

.translated-answer {
  border-left: 1px #ababab solid;
  font-weight: 500;
  padding-left: 1em;
  padding-top: 0.2em;
}

.google-translated {
  font-weight: 400;
}

// We want sticky report tabs, as well as a sticky report filter. To do
// this with CSS alone requires some hacks, so bear with me throughout
// this CSS code block. First of all, we specify in the route definition
// that the report route takes care of the main content paddings by itself.
// That way we can scroll the tabs all the way up to the breadcrumb bar
// without clipping.
.tabs-container {
  position: relative;

  // Then, we use a deep selector to add the original top padding to
  // the tabs bar, and make it sticky.
  :deep(.tabs) {
    // A slight bottom padding looks a bit better than no padding at all.
    padding: 0.5em 1em 0.1em 1em;

    position: sticky;

    // Stick below the subheader
    top: 42.25px;

    // We need to overlay the tab contents, which is also why we add the
    // background color below. 36 is below the main header ("back
    // to patient details"), which still needs to overlay this.
    z-index: 36;

    // Overall background color
    background-color: #eee;
  }

  :deep(.tab-content) {
    min-height: 200px;
  }
}

// The real hack is the filters. We can't give them position: absolute
// *and* position: sticky at the same time. Having the filter in the
// regular document flow puts it either above or below the tabs,
// which we don't want either. Putting it above the tabs with a negative
// margin either has the invisible filter div overlay the tabs, making them
// unclickable, or the tabs background overlays the filters, hiding them.
// Neither of those work. We *can* wrap the filters with a container, which
// is absolutely positioned. However, scrolling the bottom of the container
// out of view ends up dragging the filters with it. We can make the container
// large enough to accommodate this, but then it overlays the tab contents,
// making everything inside unclickable...
// The solution is terrible, but effective:
// - We put an absolutely positioned filter container
//   (with the filters inside of it) before the tabs, in a shared container.
//   The filter container gets a width and height of 100%.
// - The filter, inside the container, gets position:sticky and a z-index
//   larger than the tabs container.
// - Because the filter container is specified before the tabs, it's behind
//   it in the stacking context, and doesn't block the tabs or their contents.
// - The filter specifies a z-index, putting it above the tabs. The tabs are
//   now effectively between it and its parent.
// - Because the parent is w/h 100%, the filter doesn't scroll out of view.
//
// You know code is great when it has more lines of code explaining it
// than implementing it....
.report-filter-container {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding-right: 1rem;
  text-align: right;
}

.report-filter {
  padding-top: 1em;
  position: sticky;
  top: 42.25px;
  z-index: 37;
  display: flex;
  flex-direction: row;
}

.filter-button {
  margin-left: 0.5rem;

  // We're really just positioning all these so they seem
  // to be roughly in the right position, to be honest.
  margin-top: -9px;
  z-index: 37;
}

:deep(.column-filter-warnings) {
  .field-no-warnings {
    display: none;
  }
}
</style>
