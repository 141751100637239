)
<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <b-loading :is-full-page="true" :active="isLoading" />

      <transition name="fade">
        <Card :title="$tc('common.patient') | titleize" v-if="!isLoading">
          <b-message v-if="existingPatient" type="is-warning" has-icon>
            {{ $t("patient_edit_page.existing_patient_warning_message") }}
            <div class="existing-patient-info">
              <patient-info :patient="existingPatient" />
              <div class="mt-5">
                <template v-if="needLocationAccess">
                  <template
                    v-if="existingPatient.request_location_access.length > 1"
                  >
                    {{
                      $t("patient_edit_page.require_location_access", {
                        locations: currentLocations,
                      })
                    }}

                    <!-- Need to choose a location, show radio buttons with location options -->
                    {{ $t("patient_edit_page.choose_add_location") }}

                    <b-field grouped group-multiline class="mt-3">
                      <b-radio-button
                        v-for="location in existingPatient.request_location_access"
                        :key="location.id"
                        :native-value="location.id"
                        v-model="selectedLocationAccess"
                        >{{ location.name }}
                      </b-radio-button>
                    </b-field>
                    <b-field>
                      <b-button
                        type="is-primary"
                        :disabled="!selectedLocationAccess"
                        :loading="addingToLocation"
                        @click="
                          addToLocationAndOpen(selectedLocationAccess ?? 0)
                        "
                      >
                        {{ $t("patient_edit_page.add_and_show") }}
                      </b-button>
                    </b-field>
                  </template>
                  <!-- There is only one location to choose from -->
                  <div class="level" v-else>
                    <div class="level-left">
                      <p class="level-item">
                        {{
                          $t("patient_edit_page.require_location_access", {
                            locations: currentLocations,
                          })
                        }}
                      </p>
                      <div class="level-item">
                        <b-button
                          type="is-primary"
                          :loading="addingToLocation"
                          @click="
                            addToLocationAndOpen(
                              existingPatient.request_location_access[0].id
                            )
                          "
                        >
                          {{
                            $t("patient_edit_page.add_to_current_location", {
                              location:
                                existingPatient.request_location_access[0].name,
                            })
                          }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <router-link
                    class="link"
                    :to="'/patient/' + existingPatient.id"
                    >{{ $t("patient_edit_page.click_here_to_view_patient") }}
                  </router-link>
                </template>
              </div>
            </div>
          </b-message>

          <Form
            :fields="fields"
            :onSubmitClick="onSubmitClick"
            @onFormFieldBlur="onPreSubmitClick"
            v-model="patient"
          >
            <template v-slot:form-actions>
              <b-button @click="onBackClick"> {{ $t("common.back") }}</b-button>
            </template>
          </Form>
        </Card>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Card from "@/components/Card.vue";
import Form from "@/components/Form/Form.vue";

import {
  addPatientToLocation,
  createPatient,
  getPatient,
  getPatientForm,
  preSubmitPatient,
  updatePatient,
} from "@/api";
import type { PatientBasicInfo, PatientFormValues } from "@/types/patient";
import { FormField, FormFieldTypes } from "@/types/form";
import { cloneDeep, isArray, isEmpty, isEqual } from "lodash";
import PatientInfo from "@/components/PatientInfo.vue";
import i18n from "@/i18n";
import { ResponseError } from "@/types/response-error";

export default Vue.extend({
  name: "PatientEdit",
  components: {
    Card,
    Form,
    PatientInfo,
  },
  data() {
    return {
      existingPatient: null as PatientBasicInfo | null,
      fields: [] as FormField[],
      isLoading: true,
      patient: {} as PatientFormValues,
      preSubmitData: {} as PatientFormValues,
      selectedLocationAccess: null as null | number,
      addingToLocation: false,
    };
  },

  computed: {
    patientId(): number | null {
      return parseInt(this.$route.params?.patientId) || null;
    },
    needLocationAccess(): boolean {
      return !!this.existingPatient?.request_location_access?.length;
    },
    currentLocations(): string {
      return this.existingPatient
        ? this.existingPatient.current_locations.map((l) => l.name).join(", ")
        : "";
    },
  },

  methods: {
    async getPatient(patientId: number) {
      const response = await getPatient(patientId);
      this.patient = { ...response.data.form_values };
    },
    async getPatientForm() {
      const { data } = await getPatientForm();
      this.fields = data;

      for (const field of this.fields) {
        (this.patient as Record<string, any>)[field.key] =
          field.type === FormFieldTypes.multiSelect ? [] : null;
      }
    },
    async onBackClick() {
      let route = this.patientId ? `/patient/${this.patientId}` : "/patients";
      this.$router.push(route);
    },
    async onPreSubmitClick() {
      try {
        // Ignore if all values are empty
        const keys = Object.keys(this.patient) as (keyof PatientFormValues)[];
        const filledValues = keys.filter((k) => {
          const v = this.patient[k];
          return k !== "id" && !!v && (!isArray(v) || !!v.length);
        });
        if (!filledValues.length) {
          this.existingPatient = null;
          return;
        }

        // Ignore if this has already been checked.
        if (isEqual(this.patient, this.preSubmitData)) {
          return;
        }

        this.preSubmitData = cloneDeep(this.patient);

        const { data: patient } = await preSubmitPatient(this.patient);

        if (!isEmpty(patient)) {
          this.existingPatient = patient;
        } else {
          this.existingPatient = null;
        }
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as ResponseError).response.data.message ||
            i18n
              .t("patient_edit_page.checking_patient_exists_error_message")
              .toString(),
          type: "is-danger",
        });

        // Bubble up the errors.
        throw (err as ResponseError).response.data?.errors;
      }
    },
    async onSubmitClick() {
      try {
        let patientId = this.patientId;

        if (patientId) {
          await updatePatient(patientId, this.patient);
        } else {
          const { data: patient } = await createPatient(this.patient);
          patientId = patient.id;
          this.$emit("patientCreated", patientId);
        }

        this.$buefy.toast.open({
          message: i18n.t("patient_edit_page.saved_patient").toString(),
          type: "is-success",
        });

        this.$router.push(`/patient/${patientId}`);
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as ResponseError).response?.data?.message ||
            i18n.t("common.saving_data_error_message").toString(),
          type: "is-danger",
        });

        // Bubble up the errors.
        throw (err as ResponseError).response.data?.errors;
      }
    },
    async addToLocationAndOpen(locationId: number) {
      const patientId = this.existingPatient?.id;
      if (!patientId) {
        return;
      }

      this.addingToLocation = true;
      await addPatientToLocation(patientId!, locationId);
      await this.$router.push(`/patient/${patientId}`);
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      await this.getPatientForm();

      if (this.patientId) {
        await this.getPatient(this.patientId);
      }
    } catch (err) {
      this.$buefy.toast.open({
        message: i18n
          .t("common.retrieving_error_message", {
            resource: i18n.t("common.patients").toString(),
          })
          .toString(),
        type: "is-danger",
      });
    } finally {
      this.isLoading = false;
    }
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.existing-patient-info {
  padding-top: 1rem;
}

.message .media-content {
  a.link {
    color: $primary !important;
  }
}
</style>
