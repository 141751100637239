<template>
  <div>
    <router-view @patientCreated="patientCreated" />
  </div>
</template>
<script lang="ts">
import { getPatient, postLock, unlockPatient } from "@/api";
import Vue from "vue";
import eventBus from "@/utils/eventBus";
import i18n from "@/i18n";
import { AxiosError } from "axios";
import { parseInt } from "lodash";
const isLocked = Vue.observable({ value: false });

export default Vue.extend({
  name: "PatientRouter",
  provide() {
    return {
      isLocked: isLocked,
    };
  },

  data() {
    return {
      isLockedModalShowed: false,
      isUnlockedModalShowed: false,
      lockOwner: "",
      lockRetrier: 0,
      unlockPatientIdBeforeDestroy: 0,
    };
  },

  computed: {
    patientId() {
      return parseInt(this.$route.params?.patientId);
    },
  },
  watch: {
    patientId() {
      this.postLock(this.patientId);
    },
  },
  methods: {
    async postLock(patientId: number | undefined) {
      if (!patientId) {
        return;
      }

      try {
        const response = await postLock(patientId); // current user has now locked this patient
        eventBus.$emit("update-patient-from-lock", response.data || []);

        this.unlockPatientIdBeforeDestroy = patientId;
        isLocked.value = false;
        if (this.isLockedModalShowed && !this.isUnlockedModalShowed) {
          eventBus.$emit("update-patient", response.data);
          this.showUnlockedDialog();
        }
      } catch (err) {
        if ((err as AxiosError).response?.status === 423) {
          isLocked.value = true;
          if (!this.lockOwner) {
            this.lockOwner = await this.getLockOwner(patientId);
          }

          if (!this.isLockedModalShowed) {
            this.showLockedDialog();
          }
        }
      }
    },

    patientCreated(newPatientId: number) {
      this.postLock(newPatientId);
    },
    retryLock() {
      this.lockRetrier = window.setInterval(() => {
        this.postLock(this.patientId);
      }, parseInt(process.env.VUE_APP_POST_LOCK_INTERVAL ?? "0") || 10000);
    },

    showLockedDialog() {
      this.isLockedModalShowed = true;
      this.$buefy.dialog.alert({
        title: i18n.t("dialog.locked.title").toString(),
        message: i18n
          .t("dialog.locked.message", { lockOwner: this.lockOwner })
          .toString(),
        type: "is-danger",
        hasIcon: true,
        icon: "lock",
        ariaRole: "alertdialog",
        confirmText: i18n.t("dialog.locked.confirm").toString(),
        ariaModal: true,
      });
    },

    showUnlockedDialog() {
      this.isUnlockedModalShowed = true;
      this.$buefy.dialog.confirm({
        title: i18n.t("dialog.unlocked.title").toString(),
        message: i18n
          .t("dialog.unlocked.message", { lockOwner: this.lockOwner })
          .toString(),
        type: "is-success",
        hasIcon: true,
        icon: "unlock",
        ariaRole: "alertdialog",
        ariaModal: true,
        confirmText: i18n.t("dialog.unlocked.confirm").toString(),
      });
    },

    async getLockOwner(patientId: number): Promise<string> {
      const response = await getPatient(patientId);
      const lock = response.data.lock;
      return (lock && lock.locked_by_name) || "";
    },
  },
  async mounted() {
    if (this.patientId) {
      await this.postLock(this.patientId);
    }
    this.retryLock();
  },
  async beforeDestroy() {
    clearInterval(this.lockRetrier);
    try {
      if (this.unlockPatientIdBeforeDestroy) {
        await unlockPatient(this.unlockPatientIdBeforeDestroy);
      }
    } catch (err) {
      //nothing
    }
  },
});
</script>
