<template>
  <div>
    <b-loading :is-full-page="true" :active="true"></b-loading>
  </div>
</template>
<script setup lang="ts">
import { watch } from "vue";
import { currentUser, removeJWT, setJWT } from "@/utils/authUtil";
import { useRoute, useRouter } from "vue-router/composables";
import { determineLandingRoute, setAuthRequiredFrom } from "@/router";
import { importPatientAndScreening } from "@/api";
import { DialogProgrammatic as Dialog } from "buefy";
import {
  PatientImport,
  PatientScreeningImport,
  ScreeningImport,
} from "@/types/screening";
import i18n from "@/i18n";

const dataKey = "patient_import_data";
const route = useRoute();
const router = useRouter();

function extractImportData(hash: string): PatientScreeningImport {
  // Extract the fields we expect to belong to the screening,
  // put the rest on the patient object.
  const params = new URLSearchParams(hash);
  const screeningFields = [
    "location_id",
    "reference_id",
    "order_id",
    "care_activity_code",
    "care_activity_description",
    "operation_side",
    "operation_date",
    "priority_code",
  ];

  const screening: ScreeningImport = {};
  const patient: PatientImport = {};
  for (const key of params.keys()) {
    const v = params.get(key) as any;
    if (screeningFields.includes(key)) {
      screening[key as keyof ScreeningImport] = v;
    } else {
      patient[key as keyof PatientImport] = v;
    }
  }

  return {
    patient,
    ...screening,
  };
}

async function check() {
  const hash = (route.hash || "#").substring(1);

  if (hash) {
    // If a hash is given, store it in session storage and replace
    // the current page without the hash. This seems to prevent the URL
    // from showing up in search history for some browsers (Chrome,
    // notably) but not others (e.g. Firefox).
    sessionStorage.setItem(dataKey, hash);

    // Redirect to the current page without the hash
    await router.replace({
      name: "patientCreateGateway",
      params: route.params,
    });
    window.location.replace(window.location.href.split("#")[0]);
    return;
  }

  if (!currentUser.value) {
    // Redirect to login.
    // Store that this is the page requiring auth
    setAuthRequiredFrom(route.fullPath);

    if (route.params.handle) {
      // OpenID Connect redirect flow
      await router.replace({
        name: "openId",
        params: {
          handle: route.params.handle,
        },
      });
    } else {
      // Regular login flow
      await router.replace({ name: "login" });
    }

    return;
  }

  const data = sessionStorage.getItem(dataKey);
  if (!data) {
    // No data hash data or session data, nothing to do here
    await router.replace(
      determineLandingRoute(currentUser.value) || "/patients"
    );
    return;
  }

  // Logged in and data present
  const importData = extractImportData(data);
  sessionStorage.removeItem(dataKey);

  try {
    const result = await importPatientAndScreening(importData);

    // Set the scoped JWT as the current JWT and redirect to
    // the patient specific page.
    setJWT(result.jwt);

    await router.replace({
      name: "patient-details",
      params: {
        patientId: result.patient_id.toString(),
      },
    });
  } catch (e) {
    removeJWT();
    Dialog.alert({
      message: i18n.t("patient_login.error").toString(),
      type: "is-danger",
      canCancel: false,
      onConfirm: () => false,
      closeOnConfirm: false,
    });
  }

  return;
}

watch(
  () => route.hash,
  () => check(),
  { immediate: true, flush: "post" }
);
</script>

<style scoped lang="scss"></style>
