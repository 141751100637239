<template>
  <div>
    <Card
      :title="$t('patient_tasks.title').toString()"
      icon="tasks"
      class="card"
    >
      <div
        class="is-flex is-align-items-center is-justify-content-space-between mb-2 task-row"
        v-for="(task, index) in patient.tasks"
        :key="index"
      >
        <div class="is-flex-grow-1">
          {{ task.type.label }}
        </div>

        <progress-bar :progress-items="task.steps" />

        <div
          v-if="!isLocked.value"
          class="buttons task-actions is-justify-content-flex-end"
        >
          <b-button
            v-for="action in task.actions"
            :loading="action.key === 'download' && isDownloadActive"
            :type="action.is_primary ? 'is-primary' : 'is-secondary'"
            :key="action.key"
            :icon-left="action.icon"
            @click="onActionButtonClick(task.id, action.key)"
            >{{ action.label }}
          </b-button>
        </div>
      </div>
      <template v-slot:card-footer v-if="!isLocked.value">
        <b-button type="is-primary" @click="onCreateTaskClick"
          >{{ $t("patient_tasks.add_task") }}
        </b-button>
      </template>

      <span
        v-if="!patient.tasks || patient.tasks.length === 0"
        class="is-italic"
        >{{ $t("patient_tasks.no_tasks") }}
      </span>
    </Card>
    <b-modal
      v-model="isCreateTaskModalOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="true"
    >
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ $t("patient_tasks.add_task") }}</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Type" grouped group-multiline>
              <b-checkbox-button
                v-model="selectedTaskKeys"
                v-for="task in patient.task_types"
                :native-value="task.key"
                :key="task.key"
              >
                <span> {{ task.label }}</span>
              </b-checkbox-button>
            </b-field>
          </section>
          <div class="modal-card-foot is-justify-content-flex-end">
            <b-button @click="isCreateTaskModalOpen = false"
              >{{ $t("common.cancel") }}
            </b-button>
            <b-button
              @click="onAddTaskClick"
              :disabled="selectedTaskKeys.length === 0"
              type="is-primary"
              >{{ $t("common.add") }}
              {{
                selectedTaskKeys.length
                  ? "(" + selectedTaskKeys.length + ")"
                  : null
              }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script lang="ts">
/* eslint vue/multi-word-component-names: 0 */
import { addTask, processTaskAction } from "@/api";
import { Patient } from "@/types/patient";
import { Task, TaskType } from "@/types/task";
import Card from "@/components/Card.vue";
import Vue, { PropType } from "vue";
import ProgressBar from "./ProgressBar.vue";
import eventBus from "@/utils/eventBus";
import { datadogLogs } from "@datadog/browser-logs";

export default Vue.extend({
  name: "Tasks",
  components: {
    Card,
    ProgressBar,
  },
  props: {
    patient: {
      type: Object as PropType<Patient>,
    },
  },
  inject: ["isLocked"],
  data() {
    return {
      isCreateTaskModalOpen: false,
      tasks: [] as Task[],
      taskTypes: [] as TaskType[],
      selectedTaskKeys: [] as TaskType["key"][],
    };
  },
  methods: {
    onCreateTaskClick() {
      this.isCreateTaskModalOpen = true;
    },

    async onAddTaskClick() {
      try {
        const { data } = await addTask(this.patient.id, this.selectedTaskKeys);
        // eslint-disable-next-line vue/no-mutating-props
        this.patient.tasks = data;
        this.isCreateTaskModalOpen = false;
        this.selectedTaskKeys = [];
        eventBus.$emit("update-patient");
        this.$buefy.toast.open({
          message: "Taak toegevoegd",
          type: "is-success",
        });
      } catch (err) {
        datadogLogs.logger.error("Error adding task", undefined, err as Error);
        this.$buefy.toast.open({
          message: "Er ging iets fout bij het toevoegen van de taak",
          type: "is-danger",
        });
      }
    },

    async onActionButtonClick(taskKey: number, actionKey: string) {
      try {
        const { data } = await processTaskAction(taskKey, actionKey);
        // eslint-disable-next-line vue/no-mutating-props
        this.patient.tasks = data;
        this.$buefy.toast.open({
          message: "Actie uitgevoerd",
          type: "is-success",
        });
        eventBus.$emit("update-patient");
      } catch (err) {
        this.$buefy.toast.open({
          message: "Er ging iets fout bij het uitvoeren van de actie",
          type: "is-danger",
        });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.card {
  margin: 2em 0;
}

.task-row {
  height: 32px;
}

.task-actions {
  min-width: 300px;
  @media screen and (max-width: 980px) {
    min-width: auto;
  }
}
</style>
