export enum FormFieldTypes {
  text = "text",
  number = "number",
  select = "select",
  email = "email",
  tel = "tel",
  anesthesiaTable = "anesthesia-table",
  multiSelect = "multi-select",
  operationSelect = "operation-select",
  tags = "tags",
  date = "date",
  sendPasswordResetLink = "send-password-reset-link",
}

export interface FormFieldOption {
  value: string;
  label: string;
}

export interface FormField {
  key: string;
  label: string;
  type: FormFieldTypes;
  options?: FormFieldOption[];
  is_required: boolean;
  is_inline: boolean;
  update_only: boolean;
  update_forbidden?: boolean;
  attributes?: string[];
}
