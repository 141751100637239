export type Cache = {
  [key: string]: any;
};

export const Cache = {
  data: {} as Cache,

  get(key: string): any {
    return this.data?.[key];
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: string, data: any): void {
    this.data[key] = data;
  },
  delete(key: string): any {
    delete this.data[key];
  },
  clear(): void {
    this.data = {};
  },
};
