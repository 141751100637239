<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <Card :title="$t('profile_page.title').toString()">
        <UserPasswordUpdate :savePassword="onSaveClick" />
        <hr class="separator" v-if="currentUser.value.authenticator_allowed" />
        <AuthenticatorSettings
          :user="currentUser.value"
          v-if="currentUser.value.authenticator_allowed"
        />
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Card from "@/components/Card.vue";
import { currentUser } from "@/utils/authUtil";

import { updatePassword } from "@/api";
import UserPasswordUpdate from "@/components/UserPasswordUpdate.vue";
import AuthenticatorSettings from "@/components/AuthenticatorSettings.vue";
import i18n from "@/i18n";
import type { ResponseError } from "@/types/response-error";

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Profile",
  components: {
    UserPasswordUpdate,
    Card,
    AuthenticatorSettings,
  },
  data() {
    return {
      currentUser: currentUser,
      isLoading: true,
      showAuthenticator: false,
    };
  },

  methods: {
    async onSaveClick(currentPassword: string, newPassword: string) {
      try {
        await updatePassword(currentPassword, newPassword);

        this.$buefy.toast.open({
          message: i18n.t("profile_page.saved_password_message").toString(),
          type: "is-success",
          duration: 5000,
        });
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            i18n.t("profile_page.saving_password_failed_message").toString(),
          type: "is-danger",
        });

        throw (err as ResponseError).response.data.errors;
      }
    },
  },
});
</script>
