<template>
  <div v-if="patient?.details?.sections?.length" class="reset-height">
    <sections :sections="patient?.details?.sections" />
  </div>
  <span v-else>{{ $t("patient_info.empty_message") }}</span>
</template>

<script lang="ts">
import { PatientBasicInfo } from "@/types/patient";
import Vue, { PropType } from "vue";
import Sections from "./Sections.vue";
export default Vue.extend({
  components: { Sections },
  name: "PatientInfo",
  props: {
    patient: {
      type: Object as PropType<PatientBasicInfo>,
    },
  },
});
</script>
<style scoped>
.reset-height {
  margin-bottom: -2em;
}
</style>
