import { computed, ref } from "vue";
import useToggler from "@/utils/toggler";

// Creates a loading status variable and a function that can wrap async
// function calls to keep the loading state positive. Calls to this `whileLoading`
// function can be nested, the loading status will only be negative once
// the last one resolves.
// If `startLoading` is passed as `true`, the loading status will additionally
// be true up to the first call to `whileLoading`. This is practical, for instance,
// to avoid glitches when loading data in onMounted.
export default function useLoadingStatus(initialValue = true) {
  const counter = ref(0);
  const initializing = ref(initialValue);
  const isLoading = computed(() => initializing.value || counter.value > 0);
  const toggler = useToggler(
    () => counter.value++,
    () => {
      counter.value--;
      initializing.value = false;
    }
  );

  function makeLoader<T>(f: () => Promise<T>) {
    return async () => {
      return toggler(f);
    };
  }

  return {
    isLoading,
    whileLoading: toggler,
    makeLoader,
  };
}
