<template>
  <section class="dropdown-select">
    <b-autocomplete
      ref="autocompleteComponent"
      v-model="searchInput"
      :open-on-focus="true"
      :data="filteredDataArray"
      :placeholder="placeholder"
      field="label"
      @input="input"
      @select="select"
      :disabled="disabled"
      :position="pickerPosition"
      clearable
    >
      <template v-slot="props">
        <span>{{ props.option.label }}</span>
      </template>
      <template #empty>
        <span> {{ emptyPlaceholder }} </span>
      </template>
    </b-autocomplete>
  </section>
</template>

<script lang="ts">
import Vue from "vue";
import { FieldMixin } from "./FieldMixin";
import { FormFieldOption } from "@/types/form";
import i18n from "@/i18n";

export default Vue.extend({
  name: "DropdownField",
  mixins: [FieldMixin],
  props: {
    emptyPlaceholder: {
      type: String,
      default: i18n.t("dropdown_field.no_options"),
    },
    placeholder: {
      type: String,
      default: i18n.t("dropdown_field.search_placeholder"),
    },
    pickerPosition: {
      type: String,
      default: "is-bottom-right",
    },
  },
  data() {
    return {
      // @ts-ignore
      options: this.field.options,
      searchInput: "",
      selected: {} as FormFieldOption,
    };
  },
  methods: {
    select(option: FormFieldOption) {
      if (!option) {
        return;
      }

      this.selected = option;

      // Note that selected keeps the value of the object and selectedValue is an array of selected values.
      // We only need the selected value
      this.$emit("input", this.selected.value);
    },
    input(value: string | null) {
      // this happens if the icon on the right side is clicked / i.e., triggers a 'clear input'.
      if (!value) {
        this.selected = {} as FormFieldOption;
        this.$emit("unset");
      }
    },
  },
  computed: {
    filteredDataArray(): FormFieldOption[] {
      if (!this.searchInput || this.selected?.label === this.searchInput) {
        return this.options;
      }

      return this.options.filter((option: FormFieldOption) => {
        return (
          option.label
            .toString()
            .toLowerCase()
            .indexOf(this.searchInput.toLowerCase()) >= 0
        );
      });
    },
  },
  mounted() {
    this.$watch(
      () => {
        // @ts-ignore
        return this.$refs.autocompleteComponent.isActive;
      },
      (val) => {
        if (!val) {
          this.searchInput = this.selected?.label || "";
        }
      }
    );
    if (this.$props.value) {
      this.$nextTick(() => {
        const selected = this.options.find(
          (option: FormFieldOption) => option.value === this.$props.value
        );

        this.selected = selected;
        this.searchInput = selected.label;
      });
    }
  },
});
</script>

<style lang="scss" scoped>
:deep(.dropdown-menu) {
  box-shadow: rgba(67, 90, 111, 0.3) 0 0 1px,
    rgba(67, 90, 111, 0.47) 0 8px 10px -4px;
}

:deep(.dropdown-content) {
  padding-bottom: 0;
}

:deep(.dropdown-item) {
  font-size: 1rem;
  border-bottom: 1px solid #edeff5;
}
</style>
