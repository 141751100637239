<template>
  <div>
    <dyte-meeting id="meeting-container"></dyte-meeting>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted } from "vue";
import DyteClient from "@dytesdk/web-core";

// Don't remove, import is not actually unused
import HTMLDyteMeetingElement, {
  defaultLanguage,
  useLanguage,
} from "@dytesdk/ui-kit";
import { defineCustomElements } from "@dytesdk/ui-kit/loader";
import { useRoute } from "vue-router/composables";
import { joinCallHost, joinCallPatient } from "@/api";

const route = useRoute();

// Load Dyte's custom HTML5 components
onBeforeMount(() => {
  defineCustomElements();
});

onMounted(async () => {
  const routeName = route.name;
  const callId = route.params["callId"];
  const key = route.params["key"];
  const joinCall = routeName === "call-host" ? joinCallHost : joinCallPatient;
  const authToken = await joinCall(callId, key);
  const meeting = await DyteClient.init({
    authToken,
    defaults: {
      audio: true,
      video: true,
    },
  });
  const meetingElement = document.getElementById(
    "meeting-container"
  )! as HTMLDyteMeetingElement;

  // Option to customize the locale
  // Doesn't seem to be any language packs available at this point, though.
  // https://docs.dyte.io/ui-kit/customizations/custom-locale
  meetingElement.t = useLanguage({
    ...defaultLanguage,
    "setup_screen.joining_as": "Joining as",
  });

  meetingElement.meeting = meeting;
});
</script>

<style scoped lang="scss"></style>
