<template>
  <card
    v-if="isVisible"
    :title="section.title"
    :icon="section.icon"
    :class="[
      hasWarnings ? 'has-warnings' : 'no-warnings',
      isEditing ? 'is-editing' : 'not-editing',
    ]"
  >
    <template
      v-slot:header-actions
      v-if="(section.is_editable && !isLocked.value) || isSummary"
    >
      <report-card-actions
        v-if="!isSummary"
        :is-editing="isEditing"
        :is-dirty="isDirty"
        @start-editing="$emit('edit-section')"
        @save="() => $emit('save-section')"
        @cancel-editing="() => $emit('restore-section')"
        @toggle-history="() => (isHistoryModalActive = !isHistoryModalActive)"
      />
    </template>

    <template v-if="section.blocks">
      <template v-for="(block, index) in section.blocks">
        <div
          v-if="hasVisibleFields(block)"
          :class="isLastVisibleBlock(index) ? 'mb-2' : 'mb-5'"
          :key="block.id"
        >
          <h5 class="title is-5 section-title mb-4" v-if="block.title">
            {{ block.title }}
          </h5>
          <fields
            :fields="block.fields"
            :changesLookup="changesLookup"
            v-if="block.fields.length > 0"
            :is-edit-mode="isEditing"
          />
          <span v-else>{{ $t("report_card.no_info") }}</span>
        </div>
      </template>
    </template>
    <b-modal v-model="isHistoryModalActive" :can-cancel="false">
      <screening-history-modal :changes="section.changes" />
    </b-modal>
  </card>
</template>
<script lang="ts">
import {
  fieldHasWarnings,
  ReportBlock,
  ReportChange,
  ReportSection,
} from "@/types/report";
import { Dictionary } from "@/types/generic";
import Vue, { PropType } from "vue";
import Fields from "@/components/Fields.vue";
import ReportCardActions from "@/components/ReportCardActions.vue";
import ScreeningHistoryModal from "@/components/ScreeningHistoryModal.vue";
import Card from "./Card.vue";
import { groupBy } from "lodash";

function blockHasWarnings(block: ReportBlock) {
  return block.fields.some(fieldHasWarnings);
}

export default Vue.extend({
  name: "ReportCard",
  inject: ["isLocked"],
  components: {
    Fields,
    ReportCardActions,
    Card,
    ScreeningHistoryModal,
  },
  props: {
    filterWarnings: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
      required: true,
    },
    isDirty: {
      type: Boolean,
    },
    section: {
      type: Object as PropType<ReportSection>,
      required: true,
    },
    isSummary: {
      type: Boolean,
      default: false,
    },
    toggleStopEditing: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    changesLookup(): Dictionary<ReportChange[]> {
      return groupBy(this.section.changes, "id");
    },
    hasWarnings(): boolean {
      return this.section.blocks.some(blockHasWarnings);
    },
    hasContent(): boolean {
      if (!this.section) {
        return false;
      }

      return (
        !!this.section.fields ||
        (this.section.blocks &&
          this.section.blocks.some((block) => block.fields.length > 0))
      );
    },
    isVisible(): boolean {
      return (
        this.hasContent &&
        (this.isEditing || !this.filterWarnings || this.hasWarnings)
      );
    },
  },
  data() {
    return {
      isHistoryModalActive: false,
    };
  },
  methods: {
    hasVisibleFields(block: ReportBlock) {
      return (
        block.fields.length > 0 &&
        (this.isEditing || !this.filterWarnings || blockHasWarnings(block))
      );
    },
    isLastVisibleBlock(i: number) {
      for (let j = i + 1; j < this.section.blocks.length; j++) {
        if (this.hasVisibleFields(this.section.blocks[j])) {
          return false;
        }
      }

      return true;
    },
  },
});
</script>
