<template>
  <div
    id="subheader"
    ref="subheader"
    :class="{ fixed: subHeaderHitsTop }"
    class="subheader"
  >
    <div
      class="item link"
      @click="$router.push(previous.path)"
      v-if="previous && isPreviousAllowed"
    >
      <b-icon icon="chevron-left" key="chevron" />
      <span>{{ $t("common.back_to") }} {{ $t(previous.label.title) }}</span>
    </div>
    <div class="item" v-else>
      <b-icon icon="home" key="home" />
      <span>
        {{ $t(this.$route.meta.title) }}
      </span>
    </div>

    <div class="action-container">
      <div class="is-flex-grow-1" />

      <PortalTarget name="subheader-actions" class="actions buttons" />
    </div>
  </div>
</template>
<script lang="ts">
/* eslint vue/multi-word-component-names: 0 */
import { defineComponent } from "vue";

export default defineComponent({
  name: "Subheader",
  inject: ["singlePatientMode"],
  data() {
    return {
      subHeaderHitsTop: false,
    };
  },
  mounted() {
    this.setSubHeaderHitsTop();
  },
  methods: {
    setSubHeaderHitsTop() {
      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        const observer = new IntersectionObserver(
          (entries) => {
            this.subHeaderHitsTop = entries[0].boundingClientRect.y <= 0;
          },
          {
            rootMargin: "0px 0px -100% 0px",
            threshold: 0,
          }
        );

        const subheader = this.$refs.subheader! as Element;

        observer.observe(subheader);
      }
    },
  },
  computed: {
    previous(): null | {
      path: string;
      label: any;
    } {
      if (this.$route.meta?.breadcrumb?.parent) {
        const pathParts = this.$route.meta.breadcrumb.parent.split("/");
        const resolvedPath = pathParts.map((part: string) =>
          (part as string).startsWith(":")
            ? this.$route.params[part.substring(1)]
            : part
        );
        const path = resolvedPath.join("/");
        return { path, label: this.$router.resolve(path).route.meta };
      }

      return null;
    },
    isPreviousAllowed(): boolean {
      return !(
        // @ts-ignore
        (this.singlePatientMode && this.$route.name === "patient-details")
      );
    },
  },
});
</script>
<style lang="scss" scoped>
.subheader {
  transition: all 0.1s ease;
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  z-index: 39;
  width: 100%;
  left: 0;

  &.fixed {
    box-shadow: rgba(67, 90, 111, 0.3) 0 0 1px,
      rgba(67, 90, 111, 0.47) 0 4px 10px -4px;
  }

  background-color: #e0e0e0;

  height: 42.25px;
  display: flex;
  align-items: center;
  padding: 0 1em;

  .action-container {
    width: 100%;

    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .item {
    display: flex;
    white-space: nowrap;

    .icon {
      margin-right: 0.5em;
    }
  }

  .link {
    cursor: pointer;
    text-decoration: underline;
  }

  @media only screen and (max-width: 800px) {
    height: auto;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
</style>
