<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="passwordResetObserver">
    <form v-on:submit.prevent>
      <ValidationProvider name="password-current" v-slot="{ errors }">
        <b-field
          :label="$t('user_password_update.current_password').toString()"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            type="password"
            v-model="currentPassword"
            ref="password"
            @keyup.native.enter="handleSubmit(onSubmit)"
            autofocus
          >
          </b-input>
        </b-field>
      </ValidationProvider>
      <ValidationProvider name="password-new" v-slot="{ errors }">
        <b-field
          :label="$t('user_password_update.new_password').toString()"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            type="password"
            ref="newPassword"
            rules="password"
            v-model="newPassword"
            @keyup.native.enter="handleSubmit(onSubmit)"
            autofocus
          >
          </b-input>
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        name="password-repeat"
        rules="confirmed:password-new"
        v-slot="{ errors }"
      >
        <b-field
          :label="$t('user_password_update.repeat_password').toString()"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            type="password"
            v-model="newPasswordRepeat"
            @keyup.native.enter="handleSubmit(onSubmit)"
            autofocus
          >
          </b-input>
        </b-field>
      </ValidationProvider>
      <div class="mt-5">
        <b-button
          expanded
          class="submit-button"
          @click="handleSubmit(onSubmit)"
          type="is-primary"
          >{{ $t("common.save") }}
        </b-button>
        <slot name="form-actions" />
      </div>
    </form>
  </ValidationObserver>
</template>

<script lang="ts">
import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    savePassword: Function,
  },
  data() {
    return {
      newPassword: "",
      newPasswordRepeat: "",
      currentPassword: "",
    };
  },
  methods: {
    onSubmit() {
      const savePassword = this.$props.savePassword;

      savePassword(this.currentPassword, this.newPassword)
        .then(() => {
          this.currentPassword = "";
          this.newPassword = "";
          this.newPasswordRepeat = "";
        })
        .catch((errors: []) => {
          (
            this.$refs.passwordResetObserver as InstanceType<
              typeof ValidationProvider
            >
          ).setErrors(errors);
        });
    },
  },
});
</script>
