import Vue from "vue";

import i18n from "./i18n";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";

import "@/styles/global.scss";
import "@/filters/date";

// https://stackoverflow.com/questions/52484377/font-awesome-icons-in-buefy
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas, far, fal);

// If auto CSS is enabled, we have to allow `unsafe-inline` in our CSP,
// which we don't want. Disabled it, and import the stylesheet manually.
config.autoAddCss = false;
import "@fortawesome/fontawesome-svg-core/styles.css";

Vue.component("font-awesome-icon", FontAwesomeIcon);

import "./validation";
import { resizeTextarea } from "./directives/resizeTextArea";
import PortalVue from "portal-vue";

import "@/utils/logger";
import Clipboard from "v-clipboard";

import { titleizeFilter, uppercaseFilter } from "@/utils/templateFilters";

Vue.filter("uppercase", uppercaseFilter);
Vue.filter("titleize", titleizeFilter);

Vue.use(Clipboard);
Vue.config.productionTip = false;

// Custom HTML5 components
Vue.config.ignoredElements = ["dyte-meeting"];

Vue.use(Buefy, {
  defaultIconPack: "fas",
  defaultIconComponent: FontAwesomeIcon,
  defaultNotificationDuration: 5000,
  defaultNotificationPosition: "is-top-center",
})
  .use(PortalVue)
  .directive("resizeTextarea", resizeTextarea);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
