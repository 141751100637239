<template>
  <div>
    <Portal to="subheader-actions" key="operation-actions">
      <span class="mb-2 mr-2 is-hidden-mobile"
        >{{
          $t("common.last_refreshed_on", {
            updatedOn: formatTime(lastRefreshData),
          })
        }}
      </span>
      <b-button icon-left="sync-alt" @click="onRefreshClick"
        >{{ $t("common.refresh_list") }}
      </b-button>

      <b-button
        type="is-primary"
        icon-left="plus"
        @click="$router.push('/operation/create')"
      >
        {{ $t("operations_page.add_operation") }}
      </b-button>
    </Portal>
    <transition name="fade">
      <div class="columns">
        <div class="column is-one-third">
          <Card :title="$t('common.filter') | titleize">
            <Filters
              :onSearchClick="onSearchClick"
              :resource="AttributeResource.Operations"
            />
          </Card>
        </div>
        <div class="column">
          <Card :contentPadding="false" :overflow="true" class="no-background">
            <Table
              :isLoading="isLoading"
              :columns="columns"
              :items="items"
              :page="params.page"
              :page-size="params.page_size"
              :total="total"
              :sort-direction="params.direction"
              :sort="params.sort"
              :onPageChange="onPageChange"
              :onSortChange="onSortChange"
              :onRowClick="onRowClick"
              :onPageSizeChange="onPageSizeChange"
            >
              <template v-slot:empty-table>
                {{ $t("common.no_results") }}
              </template>
            </Table>
          </Card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Table from "@/components/Table.vue";
import Card from "@/components/Card.vue";
import Filters from "@/components/Filters.vue";

import { getOperations } from "@/api";
import TableMixin from "@/mixins/TableMixin";
import mixins from "vue-typed-mixins";
import { formatTime } from "@/filters/date";

import { AttributeResource, FilterParams } from "@/types/table";
import { Operation } from "@/types/operations";
import { Cache } from "@/utils/cache";
import i18n from "@/i18n";

export default mixins(TableMixin<Operation, FilterParams>()).extend({
  name: "OperationsOverview",
  components: {
    Table,
    Card,
    Filters,
  },

  data() {
    return {
      lastRefreshData: null as unknown as Date,
      AttributeResource,
      isLoading: true,
      formatTime: formatTime,
    };
  },

  watch: {
    $route: {
      handler: function () {
        this.getOperations();
      },
    },
  },

  methods: {
    async getOperations() {
      const query = this.createQueryString(this.$route);
      try {
        const { data: operationsResponse } = await getOperations(query);
        this.items = [...operationsResponse.items];
        this.params = operationsResponse.params;
        this.total = operationsResponse.total;
        this.lastRefreshData = new Date();
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("common.retrieving_error_message", {
              resource: i18n.tc("common.operations", 1).toString(),
            })
            .toString(),
          type: "is-danger",
        });
      } finally {
        this.isLoading = false;
      }
    },

    onRefreshClick() {
      this.getOperations();
    },

    onRowClick(row: Operation) {
      this.$router.push(`/operation/${row.id}`);
    },
  },

  async mounted() {
    this.loadCachedQueryString();
    await this.getAttributes(AttributeResource.Operations);
    this.getOperations();
  },

  beforeRouteLeave(to, from, next) {
    if (this.$route.query) {
      Cache.set(this.$route.path, this.$route.query);
    }
    next();
  },
});
</script>
