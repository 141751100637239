import VueI18n from "vue-i18n";
import Vue from "vue";
import { nl, de, enUS } from "date-fns/locale";

Vue.use(VueI18n);

const locales = require.context(
  "./assets/locales",
  true,
  /[A-Za-z0-9-_,]+\.json$/i
);

// Supported locales for date-fns
export const dateLocales = {
  en: enUS,
  nl: nl,
  de: de,
};

function loadLocaleMessages() {
  const messages = {} as Record<string, any>;
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

const allMessages = loadLocaleMessages();
const availableLocales = Object.keys(allMessages);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "nl",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),

  // Fallback is expected and the warning is annoying, disable only if
  // you want to test.
  silentFallbackWarn: true,
});

// Makes a best effort guess what locale is currently used for
// translations by VueI18n, so we can set it as the html[lang] attribute.
// Pass in the locale requested by the client, which can either be
// the navigator language or the configured tenant locale.
export function guessActiveLocale(locale: string) {
  // If the locale is present verbatim in our locale map,
  // that's the one we're using.
  if (availableLocales.includes(locale)) {
    return locale;
  }

  // If a generic locale variant is present in our map,
  // most likely that is what's being used.
  const generic = locale.split("-", 1)[0];
  if (generic !== locale && availableLocales.includes(generic)) {
    return generic;
  }

  // If the main locale and generic locale aren't present, the fallback
  // locale is most likely being used. A possible exception would occur
  // if, for instance, we register en-GB as a locale, but not `en`.
  // VueI18n might fall back to `en-GB` for `en-US` in that case. We should
  // make sure to always register the most common specific variant as the
  // generic variant for this reason.
  return i18n.fallbackLocale.toString();
}

export default i18n;
