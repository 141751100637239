<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <card :title="$tc('routes.title.select_tenant')">
        <b-message type="is-info" has-icon>
          {{ $t("select_tenant.prompt") }}
        </b-message>
        <a
          @click="loginAsTenant(tenant)"
          class="tenant"
          v-for="tenant in tenants"
          :key="tenant.id"
        >
          {{ tenant.name }}
        </a>
      </card>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { getProfile, switchTenant } from "@/api";
import Card from "@/components/Card.vue";
import { sortBy } from "lodash";
import { currentUser, setJWT, showTenantSelectionPage } from "@/utils/authUtil";
import { determineLandingRoute } from "@/router";
import { Profile } from "@/types/profile";

type TenantOption = {
  id: number;
  name: string;
};

export default Vue.extend({
  name: "TenantSelector",
  components: {
    Card,
  },

  data() {
    return {
      tenants: [] as TenantOption[],
      profile: null as Profile | null,
    };
  },

  methods: {
    async loginAsTenant(tenant: TenantOption) {
      if (tenant.id === this.profile!.tenant_id) {
        // This is the main tenant, no need to switch
        this.goToLandingRoute();
        return;
      }

      const response = await switchTenant(tenant.id);
      setJWT(response.data);

      this.goToLandingRoute();
    },

    goToLandingRoute() {
      const path = determineLandingRoute(currentUser.value) || "/patients";

      this.$router
        .push({ path, query: { timestamp: Date.now().toString() } })
        .then(() => {
          this.$emit("afterSwitch");
        });
    },
  },

  async mounted() {
    const data = (await getProfile()).data;
    this.profile = data;

    if (data.impersonated || !showTenantSelectionPage(data)) {
      // There's currently way to know what the original tenant is here, just log
      // out and log back in again.
      this.goToLandingRoute();
      return;
    }

    this.tenants = sortBy(
      [
        // This only works if we're not 'impersonating', otherwise
        // the tenant ID currently in the profile will be one that's
        // in the list as well, and the original tenant is unknown.
        {
          id: data.tenant_id,
          name: data.tenant_name,
        },
        ...data.user_tenants!.map((tenant) => ({
          id: tenant.id,
          name: tenant.name,
        })),
      ],
      (t) => t.name
    );
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

:deep(.message-body .media) {
  align-items: center;
}

.tenant {
  display: block;
  padding: 0.5rem;
  font-size: 1.2rem;

  &:hover {
    background-color: $primary-light;
  }
}

.tenant + .tenant {
  border-top: 1px solid $color-cloud;
}
</style>
