<template>
  <div>
    <b-loading :is-full-page="true" :active="isLoading" />
    <transition name="fade">
      <div class="columns" v-if="!isLoading">
        <div class="column is-half">
          <Card title="Export" icon="user">
            <div class="columns">
              <div class="column">
                <div>
                  <div class="label-row">
                    <div class="label">
                      {{ $t("export_page.title") }}
                    </div>
                    <span>{{ this.export.export_template_name }}</span>
                  </div>
                </div>
                <div class="label-row">
                  <div class="label">
                    {{ $t("export_page.file_type") }}
                  </div>
                  <span>{{ this.export.type | titleize }}</span>
                </div>
              </div>
              <div class="column">
                <div class="label-row">
                  <div class="label" v-if="this.export.status">
                    {{ $t("export_page.export_status") }}
                  </div>
                  <span>{{ this.export.status_name }}</span>
                </div>
                <div class="label-row">
                  <div class="label">
                    {{ $t("export_page.number_of_screenings_in_export") }}
                  </div>
                  <span>{{ this.export.total || "N/A" }}</span>
                </div>
                <div class="label-row">
                  <div class="label">{{ $t("export_page.file_size") }}</div>
                  <span>{{ this.export.file_size || "N/A" }}</span>
                </div>
              </div>
            </div>
            <div class="flex center-h">
              <b-button
                class="flex"
                v-if="
                  !['processing', 'pending', 'errored'].includes(
                    this.export.status
                  )
                "
                type="is-primary"
                @click="downloadExport()"
                icon-left="download"
              >
                <span>{{ $t("export_page.download_file") }}</span></b-button
              >
            </div>
            <live-progress-bar
              v-if="['processing', 'pending'].includes(this.export.status)"
              :total="total"
              :processed="processed"
            />
          </Card>
        </div>
        <div class="column is-half-desktop">
          <Card
            :title="$t('common.history') | titleize"
            icon="history"
            class="is-hidden-mobile"
          >
            <history :history="this.export.logs" />
          </Card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Card from "@/components/Card.vue";
import History from "@/components/History.vue";
import { currentUser } from "@/utils/authUtil";
import { getExport, downloadExport, getExportTemplates } from "@/api";
import eventBus from "@/utils/eventBus";
import LiveProgressBar from "@/components/LiveProgressBar.vue";
import { ExportData, ExportTemplate } from "@/types/export";
import Vue from "vue";
import i18n from "@/i18n";

export default Vue.extend({
  name: "ExportView",
  components: {
    Card,
    History,
    LiveProgressBar,
  },
  data() {
    return {
      currentUser: currentUser,
      export: {} as ExportData,
      isDownloading: false,
      isLoading: true,
      pollExportChanges: undefined as number | undefined,
      processed: null as number | null,
      total: null as number | null,
      exportTemplates: [] as ExportTemplate[],
    };
  },
  computed: {
    exportId(): number {
      return parseInt(this.$route.params?.exportId);
    },
    title() {
      return "Export";
    },
  },
  watch: {
    export(): void {
      this.total = this.export ? this.export.total : 0;
      this.processed = this.export ? this.export.processed : 0;
    },
  },
  methods: {
    startPollingExportChanges() {
      if (this.pollExportChanges) {
        window.clearInterval(this.pollExportChanges);
      }

      let lastStatus: string | null = null;

      this.pollExportChanges = window.setInterval(async () => {
        const exportResponse = await this.getExport();

        if (!exportResponse) {
          this.stopPollingExportChanges();
        } else {
          // Refresh only if status has been changed.
          if (lastStatus !== exportResponse.status) {
            this.$set(this, "total", exportResponse.total);
            this.$set(this, "processed", exportResponse.processed);
          }

          lastStatus = exportResponse.status;

          // Anything else than processing or pending should stop the interval.
          if (!["pending", "processing"].includes(exportResponse.status)) {
            this.stopPollingExportChanges();
          }
        }
      }, 2000);
    },
    stopPollingExportChanges() {
      window.clearInterval(this.pollExportChanges);

      this.pollExportChanges = undefined;
    },
    async downloadExport() {
      this.isDownloading = true;
      await downloadExport(this.exportId);
      this.getExport();
      this.isDownloading = false;
    },
    async getExport(): Promise<ExportData | null> {
      try {
        const { data: exportResponse } = await getExport(this.exportId);
        this.export = exportResponse;

        return this.export;
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("common.retrieving_error_message", {
              resource: i18n.tc("common.exports", 1).toString(),
            })
            .toString(),
          type: "is-danger",
        });

        return null;
      }
    },
    onFilterResetClick() {
      eventBus.$emit("clear-filters");
    },
  },
  async mounted() {
    this.isLoading = true;

    getExportTemplates().then(
      (response) => (this.exportTemplates = response.data)
    );

    await this.getExport();
    this.isLoading = false;

    if (["processing", "pending"].includes(this.export.status)) {
      this.startPollingExportChanges();
    }
  },
  beforeDestroy() {
    window.clearInterval(this.pollExportChanges);
  },
});
</script>

<style scoped lang="scss">
.label {
  margin-bottom: 0;
}

.label-row {
  margin-bottom: 0.5em;
}
</style>
