<template>
  <div>
    <b-table
      v-if="columns?.length > 0"
      :data="items ?? []"
      :pagination-simple="true"
      :loading="isLoading ?? false"
      :paginated="true"
      :current-page="page ?? 1"
      :per-page="pageSize"
      :backend-pagination="true"
      :backend-sorting="true"
      :backend-filtering="true"
      :total="total"
      :default-sort="sort"
      :default-sort-direction="sortDirection ?? 'asc'"
      :bordered="false"
      :mobile-cards="false"
      sort-icon="chevron-up"
      sortIconSize="is-small"
      class="custom-table"
      striped
      @page-change="onPageChange"
      @click="onRowClick"
      @sort="onSortChange"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="'column-' + index"
        :visible="column.visible"
        :label="column.label"
        :field="column.field"
        :sortable="column.sort !== 'none'"
        :meta="column"
        width="300"
      >
        <template v-slot:header="{ column }">
          <b-icon v-if="column.meta.icon" :icon="column.meta.icon" />
          <span v-if="column.label">{{ column.label }}</span>
          <b-icon
            v-if="column.field === sort"
            icon="chevron-up"
            :class="{
              'is-desc': sortDirection === 'desc',
            }"
          />
        </template>
        <template v-slot="props">
          <grid-cell-representation
            :column="column"
            :content="props.row[column.field]"
          />
        </template>
      </b-table-column>
      <template v-slot:loading>
        <div class="table-loader">
          <b-icon
            v-if="isLoading"
            pack="fas"
            icon="spinner-third"
            size="is-large"
            custom-class="fa-spin"
          />
        </div>
      </template>
      <template #bottom-left>
        <b-select
          :placeholder="$t('table.number_of_rows_placeholder')"
          class="ml-4"
          @input="onPageSizeChange"
          value="15"
        >
          <option value="15">{{ $t("table.number_of_rows_15") }}</option>
          <option value="50">{{ $t("table.number_of_rows_50") }}</option>
          <option value="100">{{ $t("table.number_of_rows_100") }}</option>
        </b-select>
      </template>

      <template v-if="!isLoading" #empty>
        <slot name="empty-table">
          {{ $t("table.empty") }}
        </slot>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { PatientRow } from "@/types/patient";
import GridCellRepresentation from "@/components/GridCellRepresentation.vue";
import { Attribute, FilterParams, SortDirections } from "@/types/table";

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Table",
  components: {
    GridCellRepresentation,
  },
  props: {
    items: {
      type: Array as PropType<PatientRow[]>,
      default: () => [],
    },
    columns: {
      type: Array as PropType<Attribute[]>,
      required: true,
    },
    pageSize: {
      type: Number as PropType<FilterParams["page_size"]>,
      default: 10,
    },
    total: {
      type: Number as PropType<FilterParams["total"]>,
    },
    page: {
      type: Number as PropType<FilterParams["page"]>,
    },
    sort: {
      type: String,
    },
    sortDirection: {
      type: String as PropType<SortDirections>,
    },
    onPageChange: {
      type: Function,
    },
    onSortChange: {
      type: Function,
    },
    onPageSizeChange: {
      type: Function,
    },
    onRowClick: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.table-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
}

.custom-table {
  min-height: 10rem;
  border-radius: 0.25rem;
}

:deep(.custom-table) {
  .table {
    border: none !important;
  }

  td {
    text-align: left !important;
    vertical-align: middle !important;
    height: 48px;
  }

  .th-wrap .icon {
    margin-left: 0 !important;
  }

  tr td:first-of-type {
    padding-left: 1em;
  }

  tbody tr {
    cursor: pointer;
  }

  thead {
    background-color: $primary !important;

    th {
      height: 49px;
      vertical-align: middle !important;
      color: white !important;
    }
  }

  .pagination {
    margin-right: 1em;
  }
}
</style>
