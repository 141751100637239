<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <card :title="$t('login_page.login').toString()">
        <div class="loader-element">
          <b-loading :is-full-page="false" :active="true" />
        </div>
      </card>
    </div>
  </div>
</template>

<script setup lang="ts">
import Card from "@/components/Card.vue";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import {
  generateRandomString,
  getRedirectUri,
  storeParameters,
} from "@/utils/openid";
import { openIdConnectInitiate } from "@/api";

const router = useRouter();
const route = useRoute();

onMounted(async () => {
  const state = generateRandomString(32);
  const handle = route.params.handle;
  const redirectUri = getRedirectUri(router, handle);

  const queryParams = new URLSearchParams(window.location.search);

  // Check if MDN scope is given. If it is, we redirect to the
  // new patient-login route, which replaces the previous directly
  // scoped MDN mechanism with something that provides more options.
  const mdn = queryParams.get("patient_number") as string | null;
  if (mdn) {
    await router.replace({
      name: "patientCreateGateway",
      hash: `#patient_id=${encodeURIComponent(mdn)}`,
      params: { handle },
    });
    return;
  }

  // Check if explicit patient ID scope is given
  const patientId = queryParams.get("patient") as string | null;

  const response = await openIdConnectInitiate(handle, state, redirectUri);
  const { auth_uri, nonce_secret } = response.data;
  storeParameters(handle, state, nonce_secret, { patientId });

  window.location.href = auth_uri;
});
</script>

<style scoped lang="scss">
.loader-element {
  width: 100%;
  min-height: 5rem;
  position: relative;
}
</style>
