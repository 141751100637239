<template>
  <div class="flex center-h live-progress-bar">
    <svg class="flex" id="line-progress" height="120" width="300">
      <g class="progress-container">
        <line
          x1="0"
          y1="50%"
          x2="100%"
          y2="50%"
          stroke="#efefef"
          stroke-width="30"
        />
      </g>
      <g class="progress-content">
        <line
          x1="0"
          y1="50%"
          :x2="percentage"
          y2="50%"
          stroke="#2b3d51"
          fill="transparent"
          stroke-width="28"
        />
      </g>

      <text class="percentage" fill="ab0000" x="45%" y="55%">
        {{ percentage }}
      </text>
    </svg>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "LiveProgressBar",
  props: {
    total: {
      default: 0,
    },
    processed: {
      default: 0,
    },
  },
  computed: {
    percentage() {
      if (this.total > 0) {
        return Math.round((this.processed * 100) / this.total) + "%";
      } else {
        return "0%";
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.percentage {
  fill: #f8f9f9;
  text-shadow: 1px 1px 1px black;
}
</style>
