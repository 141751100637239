<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <card :title="$tc('forbidden_page.title')">
        {{ $t("forbidden_page.forbidden_page_message") }}
      </card>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint vue/multi-word-component-names: 0 */

import Vue from "vue";

import Card from "@/components/Card.vue";

export default Vue.extend({
  name: "Forbidden",
  components: {
    Card,
  },
});
</script>
<style lang="scss" scoped></style>
