<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <Card :title="$t('password_reset_page.title').toString()">
        <ValidationObserver
          v-slot="{ handleSubmit }"
          ref="passwordResetObserver"
        >
          <form v-on:submit.prevent>
            <ValidationProvider
              name="password"
              rules="password"
              v-slot="{ errors }"
            >
              <b-field
                :label="$tc('password_reset_page.password')"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <b-input
                  type="password"
                  v-model="password"
                  ref="password"
                  @keyup.native.enter="handleSubmit(onSaveClick)"
                  autofocus
                >
                </b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              name="passwordRepeat"
              rules="confirmed:password"
              v-slot="{ errors }"
            >
              <b-field
                :label="$tc('password_reset_page.repeat_password')"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <b-input
                  type="password"
                  v-model="passwordRepeat"
                  @keyup.native.enter="handleSubmit(onSaveClick)"
                  autofocus
                >
                </b-input>
              </b-field>
            </ValidationProvider>
            <div class="mt-5">
              <b-button
                expanded
                class="submit-button"
                @click="handleSubmit(onSaveClick)"
                type="is-primary"
                >{{ $t("common.save") }}
              </b-button>
              <slot name="form-actions" />
            </div>
          </form>
        </ValidationObserver>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { setPassword, validateToken } from "@/api";
import Card from "@/components/Card.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import i18n from "@/i18n";

export default Vue.extend({
  name: "PasswordReset",
  components: {
    Card,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      password: "",
      passwordRepeat: "",
    };
  },
  computed: {
    token(): string {
      return this.$route.query.token as string;
    },
  },
  methods: {
    async onSaveClick() {
      try {
        await setPassword(this.token, this.password);
        this.$buefy.toast.open({
          message: i18n
            .t("password_reset_page.password_saved_message")
            .toString(),
          type: "is-success",
          duration: 5000,
        });

        this.$router.push("/login");
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("password_reset_page.saving_password_failed_message")
            .toString(),
          type: "is-danger",
        });
      }
    },
  },
  async mounted() {
    if (!this.token) {
      this.$router.push("/login");
    }
    try {
      await validateToken(this.token);
    } catch (err) {
      this.$router.push("/login");
    }
  },
});
</script>
<style lang="scss" scoped></style>
