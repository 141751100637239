<template>
  <b-taginput
    :value="value"
    @input="$emit('input', $event)"
    :disabled="disabled"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { FieldMixin } from "./FieldMixin";

export default Vue.extend({
  name: "TagsField",
  mixins: [FieldMixin],
  methods: {},
});
</script>
