<template>
  <div>
    <Portal to="subheader-actions" key="user-actions">
      <span class="mb-2 mr-2 is-hidden-mobile">{{
        $t("common.last_refreshed_on", {
          updatedOn: formatTime(lastRefreshData),
        })
      }}</span>
      <b-button icon-left="sync-alt" @click="onRefreshClick"
        >{{ $t("common.refresh_list") }}
      </b-button>

      <b-button
        type="is-primary"
        icon-left="plus"
        @click="$router.push('/user/create')"
        >{{ $t("users_page.add_user") }}
      </b-button>
    </Portal>
    <transition name="fade">
      <div class="columns">
        <div class="column is-one-third">
          <Card :title="$t('common.filter') | titleize">
            <Filters
              :onSearchClick="onSearchClick"
              :resource="AttributeResource.Users"
            />
          </Card>
        </div>
        <div class="column">
          <Card :contentPadding="false" :overflow="true" class="no-background">
            <Table
              :isLoading="isLoading"
              :columns="columns"
              :items="items"
              :page="params.page"
              :page-size="params.page_size"
              :total="total"
              :sort-direction="params.direction"
              :sort="params.sort"
              :onPageChange="onPageChange"
              :onSortChange="onSortChange"
              :onRowClick="onRowClick"
              :onPageSizeChange="onPageSizeChange"
            >
              <template v-slot:empty-table>
                {{ $t("common.no_results") }}
              </template>
            </Table>
          </Card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Table from "@/components/Table.vue";
import Card from "@/components/Card.vue";
import Filters from "@/components/Filters.vue";

import { getUsers } from "@/api";
import TableMixin from "@/mixins/TableMixin";
import mixins from "vue-typed-mixins";
import { formatTime } from "@/filters/date";

import { AttributeResource, FilterParams } from "@/types/table";
import { User } from "@/types/users";
import { Cache } from "@/utils/cache";
import { isNumber } from "lodash";

export default mixins(TableMixin<User, FilterParams>()).extend({
  name: "UsersOverview",
  components: {
    Table,
    Card,
    Filters,
  },

  data() {
    return {
      lastRefreshData: null as unknown as Date,
      AttributeResource,
      isLoading: true,
      formatTime: formatTime,
    };
  },

  watch: {
    $route: {
      handler: function () {
        this.getUsers();
      },
    },
  },

  methods: {
    async getUsers() {
      const query = this.createQueryString(this.$route);
      try {
        const { data: usersResponse } = await getUsers(query);
        this.items = [...usersResponse.items];
        this.params = usersResponse.params;
        this.total = usersResponse.total;
        this.lastRefreshData = new Date();
      } catch (err) {
        this.$buefy.toast.open({
          message: "Er ging iets fout bij het ophalen van de gebruikers",
          type: "is-danger",
        });
      } finally {
        this.isLoading = false;
      }
    },

    onRefreshClick() {
      this.getUsers();
    },

    onRowClick(row: User) {
      // The user ID is always returned in the list, but if
      // a grid field is configured it is returned as an object.
      const id = isNumber(row.id) ? row.id : row.id.value;
      this.$router.push(`/user/${id}`);
    },
  },

  async mounted() {
    this.loadCachedQueryString();
    await this.getAttributes(AttributeResource.Users);
    this.getUsers();
  },

  beforeRouteLeave(to, from, next) {
    if (this.$route.query) {
      Cache.set(this.$route.path, this.$route.query);
    }
    next();
  },
});
</script>
