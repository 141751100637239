<template>
  <div class="filters">
    <div v-if="isLoading" class="skeleton">
      <div class="is-flex is-align-content-stretch my-2">
        <span class="icon is-small">
          <b-skeleton />
        </span>
        <span class="icon is-small ml-2">
          <b-skeleton />
        </span>
        <span class="icon is-small ml-2">
          <b-skeleton />
        </span>
        <span class="icon is-small ml-2">
          <b-skeleton />
        </span>
      </div>

      <b-skeleton width="60%" :animated="true" />

      <b-skeleton width="60%" :animated="true" />
      <b-skeleton class="py-2" width="80%" :animated="true" />

      <b-skeleton :animated="true" />
    </div>

    <template v-if="filters && filters.length > 0">
      <b-field
        v-for="(filter, filterIndex) in filters"
        :key="filterIndex"
        :label="filter.label"
        :class="filter.width"
      >
        <b-input
          v-if="
            filter.type === FilterTypes.Text ||
            filter.type === FilterTypes.Email ||
            filter.type === FilterTypes.Tel
          "
          :placeholder="$t('filters.fill_in_search_term_placeholder')"
          v-model="filter.value"
        />
        <b-datepicker
          :placeholder="$tc('filters.fill_in_date_placeholder')"
          class="date-picker"
          v-else-if="filter.type === FilterTypes.Date"
          v-model="filter.value"
          :date-parser="parseInput"
          :date-formatter="formatDate"
          :position="pickerPosition(filterIndex)"
          :first-day-of-week="1"
          editable
        />
        <template v-if="filter.type === FilterTypes.Select">
          <div class="button-group">
            <template v-for="(option, key) in filter.options">
              <b-tooltip
                :label="option.tooltip"
                :key="key"
                v-if="option.tooltip"
              >
                <b-button
                  :key="key"
                  :class="{ 'is-primary': filter.value === option.value }"
                  :icon-left="option.icon"
                  @click="onSelectChange(filterIndex, option.value)"
                >
                  <template v-if="option.label">{{ option.label }}</template>
                </b-button>
              </b-tooltip>
              <b-button
                :key="key"
                :class="{ 'is-primary': filter.value === option.value }"
                :icon-left="option.icon"
                @click="onSelectChange(filterIndex, option.value)"
                v-else
              >
                <template v-if="option.label">{{ option.label }}</template>
              </b-button>
            </template>
          </div>
        </template>
      </b-field>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { getFilters } from "@/api";
import { AttributeResource, Filter, FilterTypes } from "@/types/table";
import { format } from "date-fns";
import eventBus from "@/utils/eventBus";
import { parseDateInput } from "@/utils/input";

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Filters",
  props: {
    resource: String as PropType<AttributeResource>,
    onSearchClick: Function,
    tenantId: Number,
  },
  data() {
    return {
      filters: [] as Filter[],
      parsedQuery: {} as Record<string, string | undefined>,
      onSearchClickFired: false,
      isLoading: false,
      FilterTypes,
    };
  },

  watch: {
    filters: {
      handler: function (filters: Filter[]) {
        for (let filter of filters) {
          if (filter.value) {
            this.parsedQuery[filter.key] = this.formatSearchTermToQueryParam(
              filter.value,
              filter.type
            );
          } else {
            this.parsedQuery[filter.key] = undefined;
          }
        }

        // Determines if a query was already active, ie., onSearchCLick has been fired before.
        // If no onSearchClick has been fired before, we remain on the current page (page is null)
        // Otherwise we go the first page because then someone changed the filter actively.
        if (this.onSearchClickFired) {
          this.parsedQuery.page = "1";
        }

        this.onSearchClick(this.parsedQuery);
        this.onSearchClickFired = true;

        this.$emit(
          "update:activeFilters",
          this.filters.filter((item) => item.value)
        );
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    async loadFilters() {
      this.isLoading = true;
      this.filters = [];

      const { data } = await getFilters(this.resource).finally(
        () => (this.isLoading = false)
      );

      this.filters = data.map((filter) => {
        return {
          ...filter,
          value: this.$route.query[filter.key]
            ? this.formatSearchTermToModel(
                this.$route.query[filter.key] as string,
                filter.type
              )
            : undefined,
        };
      });
    },

    formatSearchTermToQueryParam(
      searchTerm: unknown,
      searchType: string
    ): string {
      if (searchType === "text" || searchType === "select") {
        return searchTerm as string;
      }
      if (searchType === "date") {
        return format(searchTerm as Date, "yyyy-MM-dd");
      }
      return "";
    },
    formatSearchTermToModel(searchTerm: string, searchType: string): any {
      if (searchType === "text" || searchType === "select") {
        return searchTerm;
      }
      if (searchType === "date") {
        return new Date(searchTerm);
      }
      return "";
    },
    onSelectChange(filterIndex: number, value: string) {
      if (this.filters[filterIndex].value === value) {
        this.$set(this.filters[filterIndex], "value", undefined);
      } else {
        this.$set(this.filters[filterIndex], "value", value);
      }
    },
    parseInput(date: string) {
      return parseDateInput(date);
    },
    formatDate(date: Date) {
      if (date) {
        return format(date, "dd-MM-yyyy");
      }
    },
    onClearFilters() {
      this.filters = this.filters.map((filter) => ({
        ...filter,
        value: undefined,
      }));
      this.$router.push({
        path: "/patients",
        query: {
          page: this.$route.query.page || "1",
          page_size: this.$route.query.page_size,
        },
      });
    },

    pickerPosition(filterIndex: number) {
      if (!this.filters?.length) {
        return "is-bottom-right";
      }

      let fieldsAbove = 0;
      let fieldsBelow = 0;

      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i];

        // Count field pairs only half
        const plus = filter.width === "half" ? 0.5 : 1;
        fieldsAbove += i < filterIndex ? plus : 0;
        fieldsBelow += i > filterIndex ? plus : 0;
      }

      // If we end up with a half field anywhere, that field
      // must be next to us (unless we've configured a single
      // half field somewhere, which we assume we didn't right now).
      fieldsAbove = Math.floor(fieldsAbove);
      fieldsBelow = Math.floor(fieldsBelow);

      // Note: "right" means left in Buefy world. Weird.
      return fieldsBelow < 3 && fieldsAbove > 2
        ? "is-top-right"
        : "is-bottom-right";
    },
  },

  async mounted() {
    eventBus.$on("clear-filters", this.onClearFilters);
    await this.loadFilters();
  },
});
</script>

<style lang="scss" scoped>
.skeleton {
  width: 100%;
}

.filters {
  min-height: 20rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .field {
    width: 100%;

    &.half {
      width: 48%;
    }

    .button-group {
      width: 100%;

      .button {
        margin-right: 0.75rem;
        margin-bottom: 0.75rem;
      }
    }
  }
}
</style>
