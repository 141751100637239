import { encode } from "he";
import { TranslateResult } from "vue-i18n";

/**
 * @param value
 */
export const uppercaseFilter = (value: string): string => {
  return value.toUpperCase();
};

export const titleize = (value: string | TranslateResult): string => {
  return (value as string)
    .toLowerCase()
    .replace(/(?:^|\s|-|\/)\S/g, (m) => m.toUpperCase());
};

/**
 * @param value
 */
export const titleizeFilter = (value: string): string => {
  return titleize(value);
};

/**
 * Simple function that replaces ** strong ** with <strong>strong</strong>
 * and _em_ with <em>em</em> and escapes the rest of the text. You can use
 * this on translation strings to display them with a bit more options
 * while still not having to worry about using html entities for everything
 * else.
 * @param value
 */
export function richMessage(value: string) {
  return (
    encode(value)
      // **strong** to <strong>strong</strong>
      .replace(/\*\*(.+?)\*\*/g, "<strong>$1</strong>")
      .replace(/_(.+?)_/g, "<em>$1</em>")
  );
}
