import { CollectionResponse } from "./table";
import { FormField, FormFieldTypes } from "@/types/form";

export enum AnesthesiaTechnique {
  druppel = "druppel",
  full = "full",
  fullEpidural = "fullepidural",
  fullWithSpinal = "fullwithspinal",
  fullLeg = "fullleg",
  fullArm = "fullarm",
  spinal = "spinal",
  spinalLeg = "spinalleg",
  leg = "leg",
  arm = "arm",
  sedation = "sedation",
  epiduraalPreg = "epiduraalpreg",
  sedationOnly = "sedationonly",
  spinalWithSedation = "spinalwithsedation",
  local = "local",
}

export enum IsProsthetic {
  prosthetic = "prosthetic",
  notProsthetic = "not-prosthetic",
}

export enum OperationRisk {
  low = "low",
  medium = "medium",
  high = "high",
}

export enum SurgerySide {
  left = "left",
  right = "right",
  both = "na",
  na = "na",
}

export interface OperationData {
  patient_operation: string | null;
  operation_side: SurgerySide | null;
  doctor_operation_risk: OperationRisk | null;
  is_prosthetic: IsProsthetic | null;
  anesthesia_methods: {
    selected_options: AnesthesiaTechnique[];
    preferred_options: AnesthesiaTechnique[];
  };
  labs?: [];
  location?: string;
  videos?: string;
}

export interface Operation extends OperationData {
  id: number;
  name: string;

  anesthesia_preferences: string;
  location: string;
  created_at: string;
  updated_at: string;
}

export type OperationsResponse = CollectionResponse<Operation>;

// Returns an object with all keys matching the given operation data fields
// set to their default "empty" values.
export function operationDataFactory(
  fields: FormField[]
): Partial<OperationData> {
  const data = {} as any;

  for (const field of fields) {
    const key = field.key as keyof OperationData;
    if (field.type === FormFieldTypes.anesthesiaTable) {
      data[key] = {
        selected_options: [],
        preferred_options: [],
      };
    } else if (field.type === FormFieldTypes.multiSelect) {
      data[key] = [];
    } else {
      data[key] = null;
    }
  }

  return data;
}
