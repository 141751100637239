<template>
  <div>
    <div class="is-flex is-flex-direction-row-reverse actions" v-if="isEditing">
      <b-tooltip :label="$t('report_card.actions.stop_editing').toString()">
        <b-button
          class="round-icon-button stop-edit"
          @click="stopEditing"
          rounded
        >
          <div class="fa-layers fa-fw">
            <span class="fas fa-slash" data-fa-mask="fas fa-pencil-alt" />
            <span class="fas fa-slash" />
          </div>
        </b-button>
      </b-tooltip>
      <b-tooltip :label="$t('report_card.actions.see_history').toString()">
        <b-button
          class="round-icon-button"
          @click="() => $emit('toggle-history')"
          icon-left="history"
          rounded
        />
      </b-tooltip>
      <b-tooltip :label="$t('common.save').toString()" v-if="isDirty">
        <b-button
          type="is-primary"
          class="round-icon-button"
          @click="onSaveClick"
          icon-left="save"
          rounded
        />
      </b-tooltip>
    </div>

    <div
      class="is-flex is-justify-content-space-between is-flex-direction-row-reverse"
      v-else
    >
      <b-tooltip
        :label="$t('report_card.actions.edit_section').toString()"
        v-if="isEditModeAllowed"
      >
        <b-button
          class="round-icon-button"
          @click="startEditing()"
          icon-left="pencil-alt"
          rounded
        />
      </b-tooltip>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { currentUser } from "@/utils/authUtil";
import i18n from "@/i18n";

export default Vue.extend({
  name: "ReportCardActions",
  props: {
    isDirty: Boolean,
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentUser: currentUser,
    };
  },
  computed: {
    isEditModeAllowed(): boolean {
      return (
        this.currentUser &&
        this.currentUser.value &&
        /* @ts-ignore */
        this.currentUser.value.permissions.includes("report-edit")
      );
    },
  },
  methods: {
    onSaveClick() {
      this.$buefy.dialog.confirm({
        title: i18n.t("report_card.dialog.save_section.title").toString(),
        message: i18n.t("report_card.dialog.save_section.message").toString(),
        cancelText: i18n.t("report_card.dialog.save_section.cancel").toString(),
        confirmText: i18n
          .t("report_card.dialog.save_section.confirm")
          .toString(),
        type: "is-success",
        onConfirm: () => {
          this.$emit("save");
          /* @ts-ignore */
          this.isEditModeLocal = false;
        },
      });
    },

    startEditing() {
      this.$emit("start-editing");
    },
    stopEditing() {
      if (!this.isEditing || !this.isDirty) {
        this.$emit("cancel-editing");
        return;
      }

      this.$buefy.dialog.confirm({
        title: i18n
          .t("report_card.dialog.stop_editing_section.title")
          .toString(),
        message: i18n
          .t("report_card.dialog.stop_editing_section.message")
          .toString(),
        cancelText: i18n
          .t("report_card.dialog.stop_editing_section.cancel")
          .toString(),
        confirmText: i18n
          .t("report_card.dialog.stop_editing_section.confirm")
          .toString(),
        type: "is-success",
        onConfirm: () => {
          this.$emit("save");
        },
        onCancel: () => {
          this.$emit("cancel-editing");
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.actions {
  & > * {
    margin: 0 4px;
  }
}

.stop-edit {
  height: 35px;
  width: 35px;
}
</style>
