import router from "@/router";
import {
  Patient,
  PatientBasicInfo,
  PatientFormValues,
  PatientsResponse,
} from "@/types/patient";

import { FormField } from "@/types/form";

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ReportSection, ReportColumn } from "@/types/report";
import {
  PatientScreeningImport,
  Screening,
  ScreeningActionType,
} from "@/types/screening";
import { Attribute, AttributeResource, Filter } from "@/types/table";
import { User, UsersResponse } from "@/types/users";
import { Operation, OperationsResponse } from "@/types/operations";
import { currentUser, SessionUser } from "@/utils/authUtil";
import { Task, TaskType } from "@/types/task";
import {
  ExportOptions,
  ExportResponse,
  ExportData,
  ExportTemplate,
} from "@/types/export";
import {
  AnesthesiaTechniqueField,
  AnesthesiaVideo,
} from "@/types/anesthesiaTechniques";
import type { Profile, TenantSettings } from "@/types/profile";
import { getAppAuthMethod } from "@/api/auth_method";
import i18n from "@/i18n";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export function cleanToken(token: string) {
  return token && token.toLowerCase().startsWith("bearer ")
    ? token.substring(7)
    : token;
}

axios.interceptors.request.use(
  function (config) {
    if (getAppAuthMethod() === "jwt") {
      const token = sessionStorage.getItem("token");

      if (token) {
        config.headers.setAuthorization(`Bearer ${token}`);
      }
    } else {
      config.withCredentials = true;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.headers["authorization"]) {
      sessionStorage.setItem(
        "token",
        cleanToken(response.headers["authorization"])
      );
    }
    return response;
  },
  async function (error) {
    if (
      error &&
      "response" in error &&
      "status" in error.response &&
      error.response.status === 401
    ) {
      sessionStorage.removeItem("token");
      currentUser.value = null;

      if (!window.location.href.includes("/login")) {
        router.push("/login");
      }
    }

    return Promise.reject(error);
  }
);

export async function login(credentials: {
  email: string;
  password: string;
}): Promise<string> {
  const { data: token } = (await axios.post("/login", {
    ...credentials,
    locale: i18n.locale,
  })) as AxiosResponse<string>;

  sessionStorage.setItem("token", token);

  return token;
}

export const loginSSO = async (auth_token: string): Promise<string> => {
  const { data: token } = (await axios.post("/sso/login", {
    auth_token,
  })) as AxiosResponse<string>;

  sessionStorage.setItem("token", token);

  return token;
};

export const resendSMSCode = async (): Promise<"ok"> =>
  axios.post("/login/resend");

export const verifyCode = async (
  code: string
): Promise<AxiosResponse<string>> => axios.post("/login/twofactor", { code });

export const getUserSession = async (): Promise<AxiosResponse<SessionUser>> =>
  axios.get("/session/user");

// Shared between loginServerSession / linkServerSession, though
// currently the output value is unused.
export type ZorgplatformData = {
  user?: any;
  saml?: any;
};

export const loginServerSession = async (credentials: {
  email: string;
  password: string;
}): Promise<ZorgplatformData> => {
  const { data: token } = await axios.post("/session/login", credentials);
  return token;
};

export async function linkServerSession(
  jwt: string
): Promise<ZorgplatformData> {
  const { data } = await axios.post("/session/link", { jwt });
  return data;
}

export const getUsers = (
  query?: string
): Promise<AxiosResponse<UsersResponse>> =>
  axios.get("/users" + (query ? `?${query}` : ""));

export const getUser = (userId: number): Promise<AxiosResponse<User>> =>
  axios.get(`/user/${userId}`);

export const getUserForm = (): Promise<AxiosResponse<FormField[]>> =>
  axios.get("/users/form");

export const createUser = (user: User): Promise<AxiosResponse<User>> =>
  axios.post("/users", user);

export const deleteUser = (userId: number): Promise<AxiosResponse<User>> =>
  axios.delete(`/user/${userId}`);

export const updateUser = (
  userId: number,
  user: User
): Promise<AxiosResponse<User>> => axios.put(`/user/${userId}`, user);

export const getOperations = (
  query?: string
): Promise<AxiosResponse<OperationsResponse>> =>
  axios.get("/operations" + (query ? `?${query}` : ""));

export const getOperation = (
  operationId: number
): Promise<AxiosResponse<Operation>> => axios.get(`/operation/${operationId}`);

export const getOperationFormData = (
  operationId: number
): Promise<AxiosResponse<Operation>> =>
  axios.get(`/operation/${operationId}/form/data`);

export const getOperationForm = (): Promise<AxiosResponse<FormField[]>> =>
  axios.get("/operations/form");

export const createOperation = (
  operation: Operation
): Promise<AxiosResponse<Operation>> => axios.post("/operations", operation);

export const deleteOperation = (
  operationId: number
): Promise<AxiosResponse<Operation>> =>
  axios.delete(`/operation/${operationId}`);

export const updateOperation = (
  operationId: number,
  operation: Operation
): Promise<AxiosResponse<Operation>> =>
  axios.put(`/operation/${operationId}`, operation);

export const sendPasswordResetLink = (
  userId: number
): Promise<AxiosResponse<User>> =>
  axios.post(`/user/${userId}/sendpasswordresetlink`);

export const setPassword = (
  token: string,
  password: string
): Promise<AxiosResponse<User>> =>
  axios.post("/user/setpassword", { token: token, password: password });

export const updatePassword = (
  currentPassword: string,
  newPassword: string
): Promise<AxiosResponse<User>> =>
  axios.post("/user/password", {
    "password-current": currentPassword,
    "password-new": newPassword,
  });

export const validateToken = (token: string): Promise<AxiosResponse<User>> =>
  axios.post("/user/validatetoken", { token: token });

export const getPatients = (
  query?: string
): Promise<AxiosResponse<PatientsResponse>> =>
  axios.get("/patients" + (query ? `?${query}` : ""));

export const getPatient = (
  patientId: number
): Promise<AxiosResponse<Patient>> => axios.get(`/patient/${patientId}`);

export const refreshPatientDetails = (
  patientId: number,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<Patient>> =>
  axios.post(`/patient/${patientId}/refresh`, {}, config);

export const createPatient = (
  patient: PatientFormValues
): Promise<AxiosResponse<Patient>> => axios.post("/patients", patient);

export const preSubmitPatient = (
  patient: PatientFormValues
): Promise<AxiosResponse<PatientBasicInfo>> =>
  axios.post("/patients/presubmit", patient);

export const addPatientToLocation = (
  patientId: number,
  locationId: number
): Promise<AxiosResponse<Patient>> =>
  axios.post(`/patient/${patientId}/locations`, {
    tenant_location_id: locationId,
  });

export const updatePatient = (
  patientId: number,
  patient: PatientFormValues
): Promise<AxiosResponse<Patient>> =>
  axios.put(`/patient/${patientId}`, patient);

export const updatePatientNotes = (
  patientId: number,
  notes: string
): Promise<AxiosResponse<Patient>> =>
  axios.put(`/patient/${patientId}/notes`, {
    notes,
  });

export async function importPatientAndScreening(
  data: PatientScreeningImport
): Promise<{
  patient_id: number;
  screening_id: number | null;
  jwt: string;
}> {
  return (await axios.post("/patients/data-login", data)).data;
}

export const createScreening = (
  patientId: number
): Promise<AxiosResponse<Screening>> =>
  axios.post(`/patient/${patientId}/screenings`);

export const postPatientAction = (
  patientId: number,
  action: string,
  data: Record<string, unknown>
): Promise<AxiosResponse<Patient>> =>
  axios.post(`/patient/${patientId}/action/${action}`, data);

export const getPatientActionForm = (
  patientId: number,
  action: string
): Promise<AxiosResponse<FormField[]>> =>
  axios.get(`/patient/${patientId}/action/${action}/form`);

export const getPatientActionValues = (
  patientId: number,
  action: string
): Promise<AxiosResponse<Record<string, unknown>>> =>
  axios.get(`/patient/${patientId}/action/${action}`);

const getAttrFromContentDisposition = (
  contentDisposition: string,
  attrName: string
) => {
  if (!contentDisposition) {
    return null;
  }
  let returnValue = "";

  contentDisposition.split(";").forEach((setting) => {
    const entry = setting.split("=").map((s) => s.trim());

    if (entry[0] === attrName) {
      returnValue = entry[1];
    }
  });

  return returnValue;
};

const downloadFile = (response: AxiosResponse, defaultFileName: string) => {
  const contentType = response.headers["content-type"] || "text/plain";
  const filename =
    getAttrFromContentDisposition(
      response.headers["content-disposition"],
      "filename"
    ) || defaultFileName;

  const blob = new Blob([response.data], { type: contentType });
  const tag = document.createElement("a");

  if ("download" in tag && window.navigator.userAgent.indexOf("Edge") === -1) {
    tag.download = filename;

    const windowUrl = window.URL || window.webkitURL;
    const url = windowUrl.createObjectURL(blob);

    tag.href = url;
    tag.target = "_blank";
    document.body.appendChild(tag);

    tag.click();
    windowUrl.revokeObjectURL(url);
  } else if ((navigator as any).msSaveBlob) {
    // Typescript doesn't know about msSaveBlob (anymore)
    // https://stackoverflow.com/questions/69485778/new-typescript-version-does-not-include-window-navigator-mssaveblob?noredirect=1&lq=1
    // That workaround seems more roundabout than just casting to any, especially considering
    // that still requires an ESlint exception.
    (navigator as any).msSaveBlob(blob, filename);
  }
};

export const downloadReport = async (
  screeningId: number
): Promise<AxiosResponse> => {
  const report = await axios.get(`/screening/${screeningId}/report/pdf`, {
    responseType: "blob",
  });
  downloadFile(report, "rapport.pdf");
  return report;
};

export const downloadQuestionnaire = async (
  screeningId: number
): Promise<AxiosResponse> => {
  const report = await axios.get(
    `/screening/${screeningId}/questionnaire/pdf`,
    {
      responseType: "blob",
    }
  );
  downloadFile(report, "questionnaire.pdf");
  return report;
};

export const getAttributes = (
  resource: AttributeResource
): Promise<AxiosResponse<Attribute[]>> => axios.get(`/${resource}/attributes`);

export const getFilters = (
  resource: AttributeResource
): Promise<AxiosResponse<Filter[]>> => axios.get(`/${resource}/filters`);

export const getPatientForm = (): Promise<AxiosResponse<FormField[]>> =>
  axios.get("/patients/form");

export const getForm = (
  screeningId: number,
  action: ScreeningActionType
): Promise<AxiosResponse<FormField[]>> => {
  if (!screeningId || !action) {
    throw new Error("action / screening ID can't be empty");
  }
  return axios.get(`/screening/${screeningId}/${action}/form`);
};

export const getData = <T>(
  screeningId: number,
  action: ScreeningActionType,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> => {
  if (!screeningId || !action) {
    throw new Error("action / screening ID can't be empty");
  }

  return axios.get(`/screening/${screeningId}/${action}`, config);
};

export const postFormData = <T = any>(
  screeningId: number,
  action: ScreeningActionType,
  data?: T
): Promise<AxiosResponse<T>> =>
  axios.post(`/screening/${screeningId}/${action}`, data);

export const getReportSummary = (
  screeningId: number,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<ReportColumn>> => {
  return axios.get(`/screening/${screeningId}/report/summary`, config);
};

export function getNurseIntake(
  screeningId: number,
  config: AxiosRequestConfig = {}
) {
  return axios.get(`/screening/${screeningId}/report/nurse-intake`, config);
}

export const getQuestionnaire = (
  screeningId: number,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<ReportColumn>> => {
  return axios.get(`/screening/${screeningId}/report/questionnaire`, config);
};

export const saveReportSection = (
  screeningId: number,
  section: ReportSection
): Promise<AxiosResponse<ReportSection>> =>
  axios.put(`/screening/${screeningId}/report/section/${section.id}`, section);

export const saveReportSections = (
  screeningId: number,
  sections: Array<ReportSection>
): Promise<AxiosResponse<ReportSection>> => {
  return axios.put(`/screening/${screeningId}/report/sections`, sections);
};

export const postLock = (patientId: number): Promise<AxiosResponse<Patient>> =>
  axios.post(`/patient/${patientId}/lock`);

export const unlockPatient = (
  patientId: number
): Promise<AxiosResponse<Patient>> =>
  axios.post(`/patient/${patientId}/unlock`);

export const addTask = (
  patientId: number,
  taskKeys: TaskType["key"][]
): Promise<AxiosResponse<Task[]>> =>
  axios.post(
    `/patient/${patientId}/tasks/multiple`,
    taskKeys.map((key) => ({ type: key }))
  );

export const processTaskAction = (
  taskKey: number,
  actionKey: string
): Promise<AxiosResponse<Task[]>> =>
  axios.post(`/task/${taskKey}/action/${actionKey}`);

export const getLocks = (
  ids: string
): Promise<AxiosResponse<Record<string, string>>> =>
  axios.get("/patients/locks" + (ids ? `?ids=${ids}` : ""));

export const generateSecret = (password: string) =>
  axios.post("/user/generateSecret", { password });

export const enableAuthenticator = (
  otpCode: string
): Promise<AxiosResponse<string>> =>
  axios.post("/user/enableAuthenticator", {
    code: otpCode,
  });

export const disableAuthenticator = (password: string) =>
  axios.post("/user/disableAuthenticator", { password });

export const getExports = (
  query?: string
): Promise<AxiosResponse<ExportResponse>> =>
  axios.get("/exports" + (query ? `?${query}` : ""));

export const getExport = (
  exportId: number
): Promise<AxiosResponse<ExportData>> => axios.get(`/export/${exportId}`);

export const doExport = (
  options?: ExportOptions
): Promise<AxiosResponse<ExportData>> => axios.post("/export", options);

export const downloadExport = async (
  exportId: number
): Promise<AxiosResponse> => {
  const report = await axios.get(`/export/${exportId}/download`, {
    responseType: "blob",
  });

  downloadFile(report, "excel.xlsx");
  return report;
};

export const getExportTemplates = (): Promise<
  AxiosResponse<ExportTemplate[]>
> => axios.get("/export/templates");

export const getProfile = (): Promise<AxiosResponse<Profile>> =>
  axios.get("/profile");

export async function getTenantSettings(): Promise<TenantSettings> {
  const { data } = await axios.get("/settings");
  return data;
}

export const switchBackToTenant = (): Promise<AxiosResponse<string>> =>
  axios.post(`/tenant/switch-back`);

export const switchTenant = (
  toTenantId: number
): Promise<AxiosResponse<string>> => axios.post(`/tenant/${toTenantId}/switch`);

export const switchTenantWithRole = (
  toTenantId: number,
  role: string
): Promise<AxiosResponse<string>> =>
  axios.post(`/tenant/${toTenantId}/switch/${role}`);

// Initiates an OpenID connect login
export const openIdConnectInitiate = (
  handle: string,
  state: string,
  redirectUri: string
): Promise<
  AxiosResponse<{
    nonce_secret: string;
    auth_uri: string;
  }>
> =>
  axios.post(`/open-id-connect/${handle}/initiate`, {
    state,
    redirect_uri: redirectUri,
  });

export type OIDCExchangeParams = {
  handle: string;
  nonceSecret: string;
  code: string;
  redirectUri: string;
  postLogoutRedirectUri?: string;
  patientIdScope?: number;
  mdnScope?: string;
};

// Exchanges an OpenID connect auth code for an admin service JWT.
export const openIdConnectExchange = (
  params: OIDCExchangeParams
): Promise<AxiosResponse<string>> => {
  const data: Record<string, any> = {
    nonce_secret: params.nonceSecret,
    code: params.code,
    redirect_uri: params.redirectUri,

    // Abusing the fact that undefined won't be serialized here.
    post_logout_redirect_uri: params.postLogoutRedirectUri || undefined,
    patient_id: params.patientIdScope || undefined,
    mdn: params.mdnScope || undefined,
  };

  return axios.post(
    `/open-id-connect/${encodeURIComponent(params.handle)}/exchange`,
    data
  );
};

export const getAnesthesiaTechniques = (): Promise<
  AxiosResponse<AnesthesiaTechniqueField[]>
> => {
  return axios.get(`/resources/anesthesia-techniques`);
};

export const getAnesthesiaVideos = (): Promise<
  AxiosResponse<AnesthesiaVideo[]>
> => {
  return axios.get(`/resources/anesthesia-videos`);
};

export async function getClinicToken(): Promise<string> {
  const { data } = await axios.post("/clinic-token");
  return data;
}

/**
 * Get or create a video meeting instance between the currently logged
 * in user and the given patient.
 */
export async function createCall(
  screeningId: string | number,
  emailInvite = true,
  smsInvite = true
): Promise<{
  id: number;
  host_key: string;
  expires_at: string;
}> {
  const { data } = await axios.post(`/screening/${screeningId}/calls`, {
    email_invite: emailInvite,
    sms_invite: smsInvite,
  });
  return data;
}

/**
 * Join a video call as an admin
 */
export async function joinCallHost(
  callId: string | number,
  key: string
): Promise<string> {
  const { data } = await axios.post("/calls/join/host", {
    call_id: callId,
    key: key,
  });
  return data;
}

/**
 * Join a video call as a patient
 */
export async function joinCallPatient(
  callId: string | number,
  key: string
): Promise<string> {
  const { data } = await axios.post("/calls/join/patient", {
    call_id: callId,
    key: key,
  });
  return data;
}
