<template>
  <b-sidebar
    :fullheight="true"
    :fullwidth="false"
    :overlay="true"
    :right="true"
    v-model="open"
    :can-cancel="true"
  >
    <div class="p-3">
      <div class="is-flex is-justify-content-flex-end mb-3">
        <b-button class="is-primary" icon-left="times" @click="open = false">{{
          $t("common.close")
        }}</b-button>
      </div>
      <card
        :title="$t('video_sidebar.videos_for_patient').toString()"
        icon="video"
      >
        <video-list :videos="videos" />
      </card>

      <card
        class="mt-4"
        :title="titleize($t('common.anesthesia_techniques').toString())"
        icon="list-ol"
      >
        <technique-list
          :techniques="techniques"
          :preferredTechniqueKey="preferredTechniqueKey"
        />
      </card>
    </div>
  </b-sidebar>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import VideoList from "@/components/Prepare/VideoList.vue";
import Card from "@/components/Card.vue";

import {
  AnesthesiaTechniqueField,
  AnesthesiaVideo,
} from "@/types/anesthesiaTechniques";

import { titleize } from "@/utils/templateFilters";

import TechniqueList from "./TechniqueList.vue";

export default Vue.extend({
  name: "VideoSidebar",
  components: { VideoList, Card, TechniqueList },
  props: {
    videos: {
      type: Array as PropType<AnesthesiaVideo[]>,
      default: () => [],
    },
    techniques: {
      type: Array as PropType<AnesthesiaTechniqueField[]>,
      default: () => [],
    },
    preferredTechniqueKey: {
      type: String,
    },
  },
  data() {
    return {
      open: false,
      titleize: titleize,
    };
  },

  mounted() {
    this.$root.$on("toggle-video-sidebar", (open: boolean) => {
      this.open = open;
    });

    window.addEventListener("resize", () => {
      if (this.open && window.innerWidth > 1024) {
        this.open = false;
      }
    });
  },
});
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.sidebar-content {
  background: $background;
}
</style>
