<template>
  <ValidationObserver
    v-slot="{ handleSubmit }"
    v-if="fields?.length > 0"
    ref="observer"
  >
    <form>
      <ValidationProvider
        :name="field.key"
        :rules="getValidationRules(field)"
        v-slot="{ errors, validate }"
        v-for="(field, fieldIndex) in fields"
        :key="field.key"
        :skipIfEmpty="false"
        slim
      >
        <b-field
          :key="field.key"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          v-if="showField(field)"
        >
          <template v-slot:label>
            <span
              v-html="field.label + (field.is_required ? ' * ' : ' ')"
            ></span>
            <slot name="extraLabel" v-bind="field"></slot>
          </template>

          <text-field
            v-if="
              field.type === FormFieldTypes.text ||
              field.type === FormFieldTypes.number ||
              field.type === FormFieldTypes.email ||
              field.type === FormFieldTypes.tel
            "
            v-model="v[field.key]"
            :field="field"
            :disabled="field.update_forbidden && !!value.id"
            @blur="onFormFieldBlur"
          />
          <date-field
            v-if="field.type === FormFieldTypes.date"
            v-model="v[field.key]"
            :field="field"
            :disabled="field.update_forbidden && !!value.id"
            :picker-position="pickerPosition(fieldIndex)"
            @blur="onFormFieldBlur"
          />
          <tags-field
            v-if="field.type === FormFieldTypes.tags"
            v-model="v[field.key]"
            :field="field"
            :disabled="field.update_forbidden && !!value.id"
            @blur="onFormFieldBlur"
          />
          <select-field
            v-if="field.type === FormFieldTypes.select"
            v-model="v[field.key]"
            :field="field"
            :disabled="field.update_forbidden && !!value.id"
            @input="onFormFieldBlur"
          />
          <multi-select-field
            v-if="field.type === FormFieldTypes.multiSelect"
            v-model="v[field.key]"
            :field="field"
            :disabled="field.update_forbidden && !!value.id"
            @input="onFormFieldBlur"
          >
          </multi-select-field>
          <dropdown-field
            v-if="field.type === FormFieldTypes.operationSelect"
            :value="v[field.key]"
            :field="field"
            :empty-placeholder="$tc('form.no_operations_found_placeholder')"
            :disabled="field.update_forbidden && !!value.id"
            :picker-position="pickerPosition(fieldIndex)"
            @input="selectOperation(field, $event)"
            @unset="unsetOperation(field)"
          >
          </dropdown-field>
          <anesthesia-table
            v-if="field.type === FormFieldTypes.anesthesiaTable"
            v-model="v[field.key]"
            :field="field"
            :validate="validate"
          />
          <b-button
            type="is-danger"
            icon-left="unlock-alt"
            v-if="field.type === FormFieldTypes.sendPasswordResetLink"
            @click="sendPasswordResetLinkConfirm"
            >{{ $t("form.send_link") }}
          </b-button>
        </b-field>
      </ValidationProvider>

      <div class="form-actions" :class="formActionType || 'inline'">
        <div class="content">
          <b-button
            :disabled="isSubmitDisabled"
            :loading="isSaving"
            class="submit-button"
            @click="handleSubmit(onSubmit)"
            type="is-primary"
            icon-left="save"
            >{{ submitLabel }}
          </b-button>
          <slot name="form-actions" />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script lang="ts">
import { FormField, FormFieldTypes } from "@/types/form";
import { defineComponent, PropType } from "vue";
import TextField from "./TextField.vue";
import DateField from "./DateField.vue";
import TagsField from "./TagsField.vue";
import SelectField from "./SelectField.vue";
import AnesthesiaTable from "./AnesthesiaTable.vue";
import { getOperation, getOperationForm, sendPasswordResetLink } from "@/api";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import MultiSelectField from "@/components/Form/MultiSelectField.vue";
import DropdownField from "@/components/Form/DropdownField.vue";
import i18n from "@/i18n";
import { OperationData, operationDataFactory } from "@/types/operations";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Form",
  props: {
    value: {
      type: Object as PropType<any>,
    },
    fields: {
      type: Array as PropType<FormField[]>,
      required: true,
    },
    formActionType: {
      type: String,
      default: null,
    },
    onPreSubmitClick: {
      type: [Function, Promise],
      default: null,
    },
    onSubmitClick: {
      type: [Function, Promise],
    },
    submitLabel: {
      default: i18n.t("common.save"),
    },
    isSubmitDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSaving: false,
      operationFields: [] as FormField[],
      FormFieldTypes,
    };
  },
  computed: {
    // Wrapper for the value prop to bypass the "no modifying props" warning.
    v(): any {
      return this.value ?? {};
    },
  },
  components: {
    MultiSelectField,
    DropdownField,
    TextField,
    DateField,
    SelectField,
    AnesthesiaTable,
    ValidationObserver,
    ValidationProvider,
    TagsField,
  },
  methods: {
    async sendPasswordResetLinkConfirm() {
      this.$buefy.dialog.confirm({
        title: i18n.t("dialog.password_reset_link.title").toString(),
        message: i18n.t("dialog.password_reset_link.message").toString(),
        confirmText: i18n.t("dialog.password_reset_link.confirm").toString(),
        cancelText: i18n.t("dialog.password_reset_link.cancel").toString(),
        type: "is-success",
        /* @ts-ignore */
        onConfirm: this.sendPasswordResetLink,
      });
    },
    async sendPasswordResetLink() {
      try {
        await sendPasswordResetLink(this.value.id);
        this.$buefy.toast.open({
          message: i18n.t("form.link_sent_message").toString(),
          type: "is-success",
        });
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n.t("form.send_link_failed_message").toString(),
          type: "is-danger",
        });
      }
    },
    onFormFieldBlur(event: any) {
      if (event == "coloscopy") {
        /* @ts-ignore */
        // eslint-disable-next-line vue/no-mutating-props
        this.value.bvo = null;
      } else if (
        ["gastroscopy", "cologastro", "ercp", "sigmoidoscopy"].includes(event)
      ) {
        /* @ts-ignore */
        // eslint-disable-next-line vue/no-mutating-props
        this.value.bvo = false;
      }
      this.$emit("onFormFieldBlur", event);
    },
    onSubmit() {
      /* @ts-ignore */
      this.isSaving = true;
      const onSubmitClick = this.$props.onSubmitClick;

      /* @ts-ignore */
      onSubmitClick()
        .catch((errors: []) => {
          (
            this.$refs.observer as InstanceType<typeof ValidationProvider>
          ).setErrors(errors);
        })
        .finally(() => {
          /* @ts-ignore */
          this.isSaving = false;
        });
    },
    getValidationRules(field: FormField) {
      const rules = [];

      if (field.is_required) {
        rules.push("required");
      }
      if (field.type === FormFieldTypes.number) {
        rules.push("numeric");
      }
      if (field.type === FormFieldTypes.email) {
        rules.push("email");
      }
      if (field.type === FormFieldTypes.tel) {
        rules.push("tel");
      }
      if (field.type === FormFieldTypes.anesthesiaTable && field.is_required) {
        rules.push("minoptions:1");
      }

      return rules.join("|");
    },
    showField(field: FormField) {
      // Hide update_only fields if we're not editing
      if (!this.value.id && field.update_only) {
        return false;
      }

      // Hide fields that have a "hidden" attribute
      return !field.attributes?.includes("hidden");
    },

    // Loads the tenant's operation form fields if the form contains
    // an operation select box. If the fields were previously loaded,
    // this does nothing.
    async loadOperationFormFields() {
      if (
        this.operationFields.length ||
        !this.fields.some(
          (field) => field.type === FormFieldTypes.operationSelect
        )
      ) {
        return;
      }

      // Load the operation form so we know which fields are relevant
      // when setting / clearing operations.
      this.operationFields = (await getOperationForm()).data;
    },

    // Loads the operation form fields (if they haven't already) and returns
    // an object with all applicable properties set to empty.
    async getEmptyOperationData() {
      // Form fields are loaded in mounted also, so likely already available
      // at this point.
      await this.loadOperationFormFields();
      return operationDataFactory(this.operationFields);
    },

    // Called when an operation has been selected in the current form.
    // Fills matching fields in the active form, then emits a selectOperation
    // event matching this function's signature.
    async selectOperation(field: FormField, operationId: number) {
      const { data: operation } = await getOperation(operationId);
      const operationMock = await this.getEmptyOperationData();

      const data = this.v;
      data[field.key] = operationId;

      // Check for other fields in the current form that have keys matching
      // the operation, and fill them.
      for (const field of this.fields) {
        if (!(field.key in operationMock)) {
          // Ignore fields not in our operation form
          continue;
        }

        const key = field.key as keyof OperationData;

        // For single select field types, only select the operation value if it
        // exists in the option list, otherwise use `null`.
        if (
          field.options &&
          ![
            FormFieldTypes.multiSelect,
            FormFieldTypes.anesthesiaTable,
          ].includes(field.type)
        ) {
          const option = field.options.find(
            (option) => option.value === operation[key]
          );
          data[key] = option ? operation[key] : null;
          continue;
        }

        // For all other types, just copy the operation's value.
        data[key] = operation[key] ?? null;
      }

      this.$emit("selectOperation", operation);
    },

    // Called when an operation has been unset. This clears all
    // properties known to be part of operation data to their
    // "empty" values, provided they're present in the form.
    // Then it emits an unsetOperation event.
    async unsetOperation(field: FormField) {
      const clear = await this.getEmptyOperationData();
      const data = this.v;
      data[field.key] = null;

      for (const field of this.fields) {
        if (!(field.key in clear)) {
          continue;
        }

        const key = field.key as keyof OperationData;
        data[key] = clear[key];
      }

      this.$emit("unsetOperation");
    },
    pickerPosition(fieldIndex: number) {
      const fieldCount = this.fields?.length ?? 0;
      const fieldsAbove = fieldIndex;
      const fieldsBelow = fieldCount - 1 - fieldsAbove;

      // Note: "right" means left in Buefy world. Weird.
      return fieldsBelow < 3 && fieldsAbove > 2
        ? "is-top-right"
        : "is-bottom-right";
    },
  },

  mounted() {
    this.loadOperationFormFields();
  },
});
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.form-actions {
  &.inline {
    margin: -1.5em;
    padding: 0.75em 1.5em;
    margin-top: 1em;
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid #ededed;
  }

  &.bar {
    background: white;
    bottom: 0;
    left: 0;
    padding: 1rem;
    position: fixed;
    box-shadow: rgba(67, 90, 111, 0.2) 0 -5px 10px,
      rgba(67, 90, 111, 0.47) 0 8px 0px -4px;
    width: 100%;
    z-index: 20;

    button {
      font-size: 1.1em;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  button {
    margin-left: 0.5em;
  }
}
</style>
<style>
.help.is-danger {
  margin-bottom: 1em;
}
</style>
