import { format, formatDistanceToNowStrict } from "date-fns";
import i18n, { dateLocales, guessActiveLocale } from "@/i18n";

export function formatDateTime(value: string) {
  return value ? format(new Date(value), "dd-MM-yyyy HH:mm") : "";
}

export function formatDate(value: string) {
  return value ? format(new Date(value), "dd-MM-yyyy") : "";
}

export function formatTime(value: string | Date): string {
  if (!value) return "";

  return format(value instanceof Date ? value : new Date(value), "HH:mm");
}

export function relativeDateTime(value: string) {
  if (!value) {
    return "";
  }
  const locale = guessActiveLocale(i18n.locale) as keyof typeof dateLocales;
  const parsed = new Date(value);
  return formatDistanceToNowStrict(parsed, {
    locale: dateLocales[locale] ?? dateLocales.en,
  });
}
