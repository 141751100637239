<template>
  <span>
    <template v-if="column.type === AttributeTypes.Progress">
      <progress-bar :progress-items="content" />
    </template>
    <template v-else>
      <template v-if="!hasTooltip()">
        <b-icon v-if="content && content.icon" :icon="content.icon"></b-icon>
        <value-presentation
          :content="content"
          :column="column"
        ></value-presentation>
      </template>

      <template v-if="hasTooltip()">
        <b-tooltip
          :contentClass="getTooltipContentClass()"
          :position="getTooltipPosition()"
          :size="getTooltipSize()"
          multilined
        >
          <template v-slot:content>
            <template v-if="specialTooltips.includes(content.tooltip.type)">
              <ul>
                <li :key="index" v-for="(row, index) in content.tooltip.value">
                  <div class="tooltip-label">
                    <span>{{ row.label }}</span>
                  </div>
                  <div>
                    <span class="tooltip-text">{{ row.text }}</span>
                    <b-icon
                      class="tooltip-icon"
                      :key="row.icon"
                      :icon="row.icon"
                      v-if="row.icon"
                    ></b-icon>
                  </div>
                </li>
              </ul>
            </template>

            <template v-else>
              {{ content.tooltip.value }}
            </template>
          </template>
          <b-icon
            v-if="content.icon"
            :class="spinClass(content.icon)"
            :icon="content.icon"
          ></b-icon>
          <value-presentation
            :content="content"
            :column="column"
          ></value-presentation>
        </b-tooltip>
      </template>
    </template>
  </span>
</template>
<script lang="ts">
import { Attribute, AttributeTypes } from "@/types/table";
import Vue, { PropType } from "vue";
import ProgressBar from "./ProgressBar.vue";
import ValuePresentation from "./ValuePresentation.vue";

export default Vue.extend({
  components: { ProgressBar, ValuePresentation },
  name: "GridCellRepresentation",
  data() {
    return {
      AttributeTypes,
      specialTooltips: ["is-special-type-list"],
    };
  },
  props: {
    column: {
      type: Object as PropType<Attribute>,
    },
    content: {} as PropType<any>,
  },
  methods: {
    spinClass(icon: string): string | null {
      return icon === "spinner" ? "fa-spin" : null;
    },
    hasTooltip() {
      return !!this.content?.tooltip.value;
    },
    getTooltipSize() {
      return this.content?.tooltip.type === "is-small"
        ? "is-small"
        : "is-large";
    },
    getTooltipPosition() {
      return this.content?.tooltip.type === "is-special-type-list"
        ? "is-right"
        : "is-left";
    },
    getTooltipContentClass() {
      return this.specialTooltips.includes(this.content?.tooltip.type)
        ? "special-list-tooltip left-align"
        : "";
    },
  },
});
</script>
<style lang="scss" scoped>
:deep(.special-list-tooltip) {
  width: 300px !important;

  &.left-align {
    text-align: unset !important;

    ul {
      list-style: inside;

      li {
        height: 2em;
        display: flex;
        justify-content: space-between;

        .tooltip-label {
          margin-top: 3px;
          font-weight: 700;
        }

        .tooltip-text {
          padding-right: 5px;
        }
      }
    }
  }
}

.tooltip-trigger {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px !important;
}
</style>
