<template>
  <div :class="layout">
    <template v-if="layout === 'columns'">
      <label
        v-if="label"
        v-html="label"
        :class="{ 'is-bold': bold === 'label' }"
        class="column"
      />
    </template>
    <template v-if="layout === 'header'">
      <label v-if="label"><span v-html="label" /></label>
    </template>
    <template v-if="layout === 'inline'">
      <label v-if="label" :class="{ 'is-bold': bold === 'label' }"
        ><span v-html="label" /><span v-if="label && value">:</span></label
      >
    </template>
    <template>
      <slot :value="value" :bold="bold" :column="value" name="value_slot">
        <span
          :class="{
            'is-bold': bold === 'value',
            column: layout === 'columns',
          }"
          class="value"
          v-html="value"
        ></span>
      </slot>
    </template>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "InlineTextPresentation",
  props: {
    label: String,
    value: String,
    layout: {
      type: String,
      default: "inline",
      validator: function (value: string) {
        return ["columns", "inline", "header"].indexOf(value) !== -1;
      },
    },
    bold: {
      type: String,
      default: "label",
      validator: function (value: string) {
        return ["label", "value"].indexOf(value) !== -1;
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.columns {
  min-height: 100% !important;
  // padding: 8px 0 8px 0 !important;
  > span {
    flex: 1;
  }

  > label {
    flex: 2;
    margin-right: 5em;
  }

  .column {
    padding: 4px 1em;
  }
}

.is-bold {
  font-weight: 700;
}

.value {
  white-space: pre-wrap;
}

.inline {
  label + .value {
    margin-left: 4px;
  }
}
</style>
