import { loginSSO } from "@/api";
import router from "@/router";
import Vue from "vue";
import { Route } from "vue-router";
import { Profile } from "@/types/profile";

export interface AuthUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  mobile: string;
  permissions: string[];
  tenant: string;
  tenant_id: number;
  patient_id?: number | null;
  two_factor_permissions: [];
  two_factor_login_required: boolean;
  authenticator_enabled: boolean;
  locale: string;
  logout_redirect?: string | null;
}

export interface SessionUser {
  email: string;
  first_name: string;
  id: number;
  tenant_id: number;
  last_name: string;
  mobile: string;
  patient_id: number;
  locale: string;
}

// Parses an admin service JWT. Correctly decodes the JWT using base64Url.
// https://stackoverflow.com/a/38552302/358873
export function parseToken(token: string): {
  exp: number;
  iat: number;
  iss: string;
  sub: number;
  user: AuthUser;
} {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const getParsedJwt = (tokenKey: string): AuthUser | null => {
  const token = sessionStorage.getItem(tokenKey);
  return token ? parseToken(token).user : null;
};

export const currentUser = Vue.observable<{
  value: AuthUser | SessionUser | null;
}>({
  value: null,
});

export const shouldVerify = (token: string): boolean => {
  const user = parseToken(token).user;
  return user.permissions.length === 1 && user.permissions[0] === "one-factor";
};

/**
 * Returns whether the currently logged in user is scoped to a specific
 * patient, and should therefore only be able to acces that particular
 * patient page.
 */
export function scopedToPatient() {
  return userScopedToPatient(currentUser.value);
}

export function userScopedToPatient(user: AuthUser | SessionUser | null) {
  return user && !!user.patient_id;
}

/**
 * @param token
 * @param setUser If true, the currentUser value is updated. This may not
 *                always be what you want (immediately), because it might
 *                affect the displayed UI.
 */
export const setJWT = (
  token: string,
  setUser = true
): { value: AuthUser | SessionUser | null } => {
  sessionStorage.setItem("token", token);

  if (setUser) {
    currentUser.value = getParsedJwt("token");
  }

  return currentUser;
};

// Removes the current token, logging out the user.
export const removeJWT = () => {
  sessionStorage.removeItem("token");
  currentUser.value = null;
};

export const hasAuthToken = (currentRoute: Route): boolean => {
  return !!currentRoute.query?.auth_token;
};

export const getVerificationMethod = (token: string): "2fa" | "sms" => {
  return parseToken(token).user.authenticator_enabled ? "2fa" : "sms";
};

export const handleAuthToken = async (toRoute: Route): Promise<void> => {
  const cachedRedirect = toRoute.redirectedFrom?.includes("auth_token")
    ? toRoute.redirectedFrom
    : toRoute.path;

  const [newPath] = cachedRedirect?.split("?");
  // this one can take super long.
  await loginSSO(toRoute.query?.auth_token as string);
  router.replace(newPath);
};

// Workaround function to check whether we're dealing with a regular user
// that we want to be able to switch accounts, or an internal user
// that switches as a "superuser" kind of functionality. If this
// method returns true, we shouldn't display the SelectTenant
// view after logging in, but rather go straight to the first
// tenant's landing page. If it returns false, we should show
// the tenant switcher (if multiple tenants are available) and not
// show the internal switcher (for now, anyway).
export function showInternalAdminSwitcher(profile: Profile): boolean {
  const u = currentUser.value;
  return (
    hasExtraTenants(profile) &&
    !!u &&
    "permissions" in u &&
    u.permissions.some(
      (p) => p === "tenant_demo_login" || p === "tenant_production_login"
    )
  );
}

function hasExtraTenants(profile: Profile): boolean {
  return !!profile.user_tenants && !!profile.user_tenants.length;
}

export function showTenantSelectionPage(profile: Profile): boolean {
  return hasExtraTenants(profile) && !showInternalAdminSwitcher(profile);
}
