import { getAttributes } from "@/api";
import router from "@/router";
import { Attribute, AttributeResource } from "@/types/table";
import { debounce, isEqual } from "lodash";
import Vue from "vue";
import { Route } from "vue-router";
import { VueConstructor, ExtendedVue } from "vue/types/vue";
import { Cache } from "@/utils/cache";

export interface TableMixinMethods {
  loadCachedQueryString: () => void;
  createQueryString: (route: Route) => string;
  getAttributes: (resource: AttributeResource) => void;
}

export interface TableMixinData<TItem, TParam> extends Vue {
  items: TItem[];
  columns: Attribute[];
  params: TParam;
  total: number;
}

function updateQueryWith(query: Record<string, string>) {
  const newQuery = {
    ...router.currentRoute.query,
    ...query,
  };

  if (isEqual(router.currentRoute.query, newQuery)) {
    return;
  }

  router.replace({
    path: router.currentRoute.path,
    query: newQuery,
  });
}

const table = <TItem, TParam>(): ExtendedVue<
  Vue,
  TableMixinData<TItem, TParam>,
  TableMixinMethods,
  unknown,
  unknown
> =>
  (Vue as VueConstructor<TableMixinData<TItem, TParam>>).extend({
    data() {
      return {
        items: [] as TItem[],
        columns: [],
        params: {} as TParam,
        total: 0,
      };
    },
    methods: {
      loadCachedQueryString() {
        const query = Cache.get(this.$route.path);

        if (query && Object.keys(query).length) {
          this.$router.replace({
            path: this.$route.path,
            query,
          });
        }

        Cache.delete(this.$route.path);
      },
      createQueryString(route: Route) {
        return route.fullPath.split("?")[1];
      },

      async getAttributes(resource: AttributeResource) {
        const { data: attributeResponse } = await getAttributes(resource);
        //@ts-ignore
        this.columns = attributeResponse.map((attribute) => ({
          ...attribute,
          field: attribute.key,
        }));
      },

      /**
       * Route to the current route with a query. If page is null we reroute to the current page if available.
       */
      onSearchClick: debounce((query) => updateQueryWith(query), 250),

      onPageChange(pageNumber: number) {
        updateQueryWith({
          page: pageNumber.toString() || "1",
        });
      },
      onSortChange(field: string, direction: string) {
        updateQueryWith({
          page: "1",
          sort: field,
          direction,
        });
      },
      onPageSizeChange(pageSize: number) {
        updateQueryWith({
          page_size: pageSize.toString(),
        });
      },
    },
  });
export default table;
