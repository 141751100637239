<template>
  <div>
    <p v-if="techniques.length === 0">
      {{ $t("anesthesia_technique_list.no_techniques_chosen_message") }}
    </p>
    <p v-else-if="noInfo">
      {{ $t("anesthesia_technique_list.no_information") }}
    </p>
    <p v-else-if="techniques.length === 1">
      {{ $t("anesthesia_technique_list.one_technique_chosen_message") }}
    </p>
    <p v-else>
      {{ $t("anesthesia_technique_list.multiple_techniques_chosen_message") }}
    </p>
    <ol v-if="techniques.length > 0 && !noInfo">
      <li v-for="technique in sortedTechniques" :key="technique.value">
        <strong class="is-block"
          >{{ technique.title[locale] }}
          <span v-if="technique.value === preferredTechniqueKey">
            {{ $t("anesthesia_technique_list.doctor_preferred_message") }}</span
          >
        </strong>
        <span class="has-text-grey is-italic">{{
          technique.description[locale]
        }}</span>
      </li>
    </ol>
  </div>
</template>
<script setup lang="ts">
import i18n from "@/i18n";
import { AnesthesiaTechniqueField } from "@/types/anesthesiaTechniques";
import { computed } from "vue";

const props = defineProps<{
  techniques: AnesthesiaTechniqueField[];
  preferredTechniqueKey?: string;
}>();

const locale = computed(() => i18n.locale ?? "nl");

const noInfo = computed(
  () =>
    props.techniques?.length === 1 && props.techniques[0].information === false
);

const sortedTechniques = computed(() => {
  if (!props.preferredTechniqueKey) {
    return props.techniques;
  }

  const selected = props.techniques.find(
    (t) => t.value === props.preferredTechniqueKey
  );
  const withoutSelected = props.techniques.filter(
    (t) => t.value !== props.preferredTechniqueKey
  );
  return selected ? [selected, ...withoutSelected] : props.techniques;
});
</script>
<style lang="scss" scoped>
ol {
  padding: 1em 0 0 1em;
}
</style>
