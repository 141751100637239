export function longerThanNSecondsAgo(
  compareDate: Date,
  seconds: number
): boolean {
  return (new Date().getTime() - compareDate.getTime()) / 1000 > seconds;
}

export function getAbsoluteUri(route: { href: string }) {
  return new URL(route.href, window.location.origin).href;
}
