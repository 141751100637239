<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <card
        :title="
          !verificationMethod
            ? $tc('login_page.title')
            : $tc('login_page.verification_code')
        "
      >
        <b-message type="is-info" has-icon>
          {{ $t("login_page.login_info_warning") }}
        </b-message>

        <ValidationObserver v-slot="{ handleSubmit }" ref="loginObserver">
          <form v-if="!token" v-on:submit.prevent>
            <ValidationProvider
              name="username"
              rules="required|email"
              v-slot="{ errors }"
            >
              <b-field
                :label="$tc('login_page.email')"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <b-input
                  type="email"
                  v-model="email"
                  @keyup.native.enter="onLoginClick"
                  autofocus
                >
                </b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              name="password"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                :label="$tc('login_page.password')"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <b-input
                  type="password"
                  v-model="password"
                  @keyup.native.enter="onLoginClick"
                />
              </b-field>
            </ValidationProvider>

            <div class="mt-5">
              <b-button
                :loading="isLoading"
                expanded
                class="submit-button"
                @click="handleSubmit(onLoginClick)"
                type="is-primary"
                >{{ $t("login_page.login") }}
              </b-button>
              <slot name="form-actions" />
            </div>
          </form>
        </ValidationObserver>

        <ValidationObserver
          v-slot="{ handleSubmit }"
          ref="verificationObserver"
        >
          <form v-if="token && shouldVerify" @submit.prevent>
            <p class="mb-5">
              <span v-if="verificationMethod === 'sms'">
                {{
                  $t("login_page.sms_sent_message", {
                    phoneNumber: phoneNumber,
                  })
                }}
              </span>
              <span v-else>
                {{ $t("login_page.open_authenticator_message") }}
              </span>
            </p>
            <ValidationProvider
              name="code"
              rules="required|numeric"
              v-slot="{ errors }"
            >
              <b-field
                :label="$tc('login_page.verification_code')"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
              >
                <b-input
                  type="text"
                  v-model="code"
                  @keyup.native.enter="onVerificationClick"
                  ref="codeInput"
                  autofocus
                >
                </b-input>
              </b-field>
            </ValidationProvider>
            <div class="mt-5 columns">
              <div class="column">
                <b-button
                  expanded
                  :loading="isLoading"
                  :disabled="isResendingSms"
                  class="submit-button"
                  @click="handleSubmit(onVerificationClick)"
                  type="is-primary"
                  >{{ $t("login_page.login") }}
                </b-button>
              </div>
              <div class="column" v-if="verificationMethod === 'sms'">
                <b-button
                  expanded
                  :loading="isResendingSms"
                  :disabled="isLoading"
                  class="submit-button"
                  @click="onNotReceivedSMSClicked()"
                  type="is-secondary"
                  >{{ $t("login_page.i_have_not_received_code") }}
                </b-button>
              </div>
              <slot name="form-actions" />
            </div>
          </form>
        </ValidationObserver>

        <div v-if="error" class="mt-5">
          <b-message
            icon-size="is-medium"
            class="is-flex is-align-items-center mb-1"
            type="is-danger"
            has-icon
          >
            {{ error }}
          </b-message>
        </div>
      </card>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint vue/multi-word-component-names: 0 */

import Vue from "vue";
import Card from "@/components/Card.vue";
import { ErrorResponse } from "@/types/response";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  getProfile,
  login,
  loginServerSession,
  resendSMSCode,
  verifyCode,
} from "@/api";
import {
  currentUser,
  getParsedJwt,
  getVerificationMethod,
  parseToken,
  shouldVerify,
  showTenantSelectionPage,
} from "@/utils/authUtil";

import { determineLandingRoute, initServerSession } from "@/router";
import i18n from "@/i18n";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "Login",
  components: {
    Card,
    ValidationObserver,
    ValidationProvider,
  },
  inject: ["authMode", "singlePatientMode"],
  data() {
    return {
      currentUser: currentUser,
      isLoading: false,
      isResendingSms: false,
      email: "",
      password: "",
      token: "",
      verificationMethod: "",
      code: "",
      error: null as Array<any> | null,
      errorMessage: null as string | null,
    };
  },
  computed: {
    redirectUrl(): string {
      return this.$route.query?.redirect as string;
    },
    phoneNumber(): string {
      let mobile = parseToken(this.token)?.user.mobile;
      return mobile && mobile.length === 8 ? "06" + mobile : mobile;
    },
    shouldVerify(): boolean {
      return shouldVerify(this.token);
    },
  },

  methods: {
    async onLoginClick() {
      try {
        this.isLoading = true;
        this.error = null;
        this.errorMessage = null;

        // @ts-ignore
        if (this.authMode === "server") {
          await loginServerSession({
            email: this.email,
            password: this.password,
          });

          currentUser.value = await initServerSession();

          this.$router.push({
            name: "patient-details",
            params: { patientId: currentUser.value.patient_id.toString() },
          });
        } else {
          this.token = await login({
            email: this.email,
            password: this.password,
          });

          if (this.shouldVerify) {
            this.verificationMethod = getVerificationMethod(this.token);
            Vue.nextTick(() => {
              (this.$refs.codeInput as HTMLElement).focus();
            });
          } else {
            await this.onSuccessfulLogin();
          }
        }
      } catch (error) {
        this.handleFailedLogin((error as AxiosError).response?.data);
      } finally {
        this.isLoading = false;
      }
    },

    async onVerificationClick() {
      try {
        this.isLoading = true;
        this.error = null;
        this.errorMessage = null;

        const { data: token } = await verifyCode(this.code);

        sessionStorage.setItem("token", token);
        currentUser.value = getParsedJwt("token");

        await this.onSuccessfulLogin();
      } catch (error) {
        this.handleFailedLogin((error as AxiosError).response?.data);
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * @param error
     */
    handleFailedLogin(error: ErrorResponse | string | any) {
      const toastParams = {
        message: i18n.t("login_page.login_failed_message").toString(),
        type: "is-danger",
      };

      this.errorMessage = error.message;

      if (error.message === "ip-block") {
        toastParams.message = error.metadata?.ip
          ? i18n
              .t("login_page.ip_block_message", {
                ipAddress: error.metadata.ip,
              })
              .toString()
          : i18n.t("login_page.ip_block_ip_unknown_message").toString();
      } else if (error.message === "missing-mobile") {
        toastParams.message = i18n
          .t("login_page.missing_mobile_message")
          .toString();
      } else if (error.message === "too-many-login-attempts") {
        toastParams.message = i18n
          .t("login_page.too_many_login_attempts_message")
          .toString();

        this.error = error?.errors[0];
      } else if (error.message == "invalid-login") {
        toastParams.message = i18n
          .t("login_page.invalid_login_message")
          .toString();

        this.error = error?.errors[0];
      } else if (error.message == "invalid-otp-code") {
        toastParams.message = i18n.t("login_page.invalid_otp_code").toString();

        this.error = error?.errors[0];
      } else {
        toastParams.message = i18n
          .t("login_page.generic_invalid_login_message")
          .toString();
      }

      this.$buefy.toast.open(toastParams);
    },

    async onSuccessfulLogin() {
      // @ts-ignore
      if (this.singlePatientMode) {
        this.$router.push({
          name: "patient-details",
          params: { patientId: currentUser.value!.patient_id!.toString() },
        });
      } else if (this.redirectUrl) {
        this.$router.push(this.redirectUrl);
      } else {
        const profile = await getProfile();
        if (showTenantSelectionPage(profile.data)) {
          // If multiple tenants are available for a non-internal account,
          // force the user to select a tenant when logging in.
          this.$router.push({ name: "select-tenant" });
          return;
        }

        const path = determineLandingRoute(currentUser.value) || "/patients";
        this.$router.push(path);
      }
    },

    async onNotReceivedSMSClicked() {
      try {
        this.isResendingSms = true;
        await resendSMSCode().finally(() => (this.isResendingSms = false));
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n.t("login_page.could_send_sms_code").toString(),
          type: "is-danger",
        });
      }
    },
  },
  async mounted() {
    try {
      // @ts-ignore
      if (this.authMode === "server") {
        currentUser.value = await initServerSession();
      }
      // @ts-ignore
      if (currentUser.value && this.singlePatientMode) {
        this.$router.push("/patient/" + currentUser.value.patient_id);
      }
    } catch (err) {
      // nothing
    }
  },
});
</script>
<style lang="scss" scoped></style>
