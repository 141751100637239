<template>
  <div>
    <div
      v-for="(field, index) in fields"
      :key="field.key ? field.key : field.id"
      :class="
        fieldHasWarnings(field) ? 'field-has-warnings' : 'field-no-warnings'
      "
    >
      <div v-if="!isEditMode" class="flex">
        <div
          class="report-summary-header"
          :class="field.highlight ? 'highlight-' + field.highlight : undefined"
          v-if="field.type.includes('header')"
        >
          <inline-text-presentation layout="header" :label="field.label" />
        </div>
        <div
          :class="field.highlight ? 'highlight-' + field.highlight : undefined"
          v-if="field.type.includes('text')"
        >
          <inline-text-presentation :label="field.label" :value="field.value" />
        </div>
        <b-button
          type="is-text slim-button"
          @click="openModal(field)"
          v-else-if="field.type === 'attachment'"
          icon-left="paperclip"
        >
          {{ field.label }}
        </b-button>

        <b-tooltip
          class="history-tooltip"
          v-if="changesLookup[field.id]"
          position="is-left"
          :label="formatChangesToLabel(changesLookup, field)"
        >
          <b-icon icon="user-edit" size="is-small" />
        </b-tooltip>
      </div>
      <div v-else>
        <div class="columns is-mobile input-row small-gap">
          <b-field
            class="edit-field label-field"
            :class="
              field.highlight ? 'highlight-' + field.highlight : undefined
            "
          >
            <b-input
              v-model="field.label"
              class="label-field"
              :disabled="field.type === 'attachment'"
              expanded
            />
          </b-field>

          <b-field
            class="edit-field text-field"
            :class="
              field.highlight ? 'highlight-' + field.highlight : undefined
            "
          >
            <b-input
              v-model="field.value"
              v-resize-textarea
              type="textarea"
              rows="1"
              :disabled="field.type === 'attachment'"
              expanded
            />
          </b-field>

          <div class="flex center small-gap">
            <b-field class="edit-field flex">
              <b-dropdown
                aria-role="list"
                v-model="field.highlight"
                class="highlight-field"
              >
                <template #trigger="{ active }">
                  <b-button
                    label="■"
                    :icon-right="active ? 'chevron-up' : 'chevron-down'"
                    :class="
                      field.highlight
                        ? 'highlight-' + field.highlight
                        : undefined
                    "
                    expanded
                  />
                </template>

                <b-dropdown-item value="info" class="highlight-info"
                  >■ {{ $t("fields.standard") }}
                </b-dropdown-item>
                <b-dropdown-item value="warning" class="highlight-warning"
                  >■ {{ $t("fields.warning") }}
                </b-dropdown-item>
                <b-dropdown-item value="danger" class="highlight-danger">
                  ■ {{ $t("fields.danger") }}
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
            <b-field class="edit-field">
              <b-button icon-left="trash-alt" @click="deleteRow(index)" />
            </b-field>
          </div>
        </div>
      </div>
    </div>
    <div class="block-actions mt-2" v-if="isEditMode">
      <span
        class="icon-button has-text-weight-bold is-underlined is-size-7"
        @click="addRow"
        ><b-icon icon="plus" />{{ $t("fields.add") }}</span
      >
    </div>

    <b-modal v-model="isModalActive">
      <img :src="imageSrc" alt="" />
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import InlineTextPresentation from "./InlineTextPresentation.vue";
import { format } from "date-fns";
import i18n from "@/i18n";
import { random } from "lodash";
import { fieldHasWarnings } from "@/types/report";

export default Vue.extend({
  components: { InlineTextPresentation },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Fields",
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    isEditMode: {
      type: Boolean,
      value: true,
    },
    changesLookup: {
      type: Object,
    },
  },
  data() {
    return {
      isModalActive: false,
      imageSrc: null,
      highlight: "info",
    };
  },
  methods: {
    /**
     * @param changesLookup
     * @param field
     * @returns {string}
     */
    formatChangesToLabel(changesLookup, field) {
      const latestChange =
        changesLookup[field.id][changesLookup[field.id].length - 1];

      const formattedTime = format(
        new Date(latestChange.timestamp),
        "dd-MM-yyyy HH:mm"
      );

      return i18n.t("fields.changed_on", {
        user: latestChange.user_name,
        changedOn: formattedTime,
      });
    },
    openModal({ value }) {
      this.imageSrc = value;
      this.isModalActive = true;
    },
    deleteRow(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.fields.splice(index, 1);
    },
    addRow() {
      // eslint-disable-next-line vue/no-mutating-props
      this.fields.push({
        key: -random(1e6),
        type: "inline-text",
        highlight: "none",
        label: "",
        value: "",
      });
    },
    fieldHasWarnings(field) {
      return fieldHasWarnings(field);
    },
  },
});
</script>
<style lang="scss" scoped>
.report-summary-header {
  padding: 0.5em 0 0.2em 0;
  font-style: italic;
}

.history-tooltip {
  margin-left: auto;
}

.small-gap {
  > * + * {
    padding-left: 0.5em;
  }
}

.input-row {
  padding-bottom: 0.5em;
  display: flex;
  align-items: center;

  .field:last-child {
    margin-bottom: 0.75rem;
  }

  .edit-field {
    &.label-field {
      flex: 1;
    }

    &.text-field {
      flex: 3;
    }
  }

  .actions {
    flex: 1;
  }
}

.block-actions {
  margin-left: -4px;
  text-decoration: underline;

  > * {
    cursor: pointer;
  }
}

:deep(.textarea) {
  padding-top: 8px;
}

:deep(.label-field) {
  input {
    font-weight: 700 !important;
  }
}

:deep(.highlight-field) {
  a.dropdown-item.is-active {
    background-color: white;
    color: inherit;
  }

  display: block;
}
</style>
