export interface CollectionResponse<TItem> {
  params: FilterParams;
  total: number;
  items: TItem[];
}

export interface FilterParams {
  grid_entity: GridEntity;
  page: number;
  page_size: number;
  total: number;
  sort: string;
  direction: SortDirections;
  filters: unknown;
}

export enum GridEntity {
  patients = "patients",
  screenings = "screenings",
}

export enum SortDirections {
  Asc = "asc",
  Desc = "desc",
}

export interface Filter {
  key: string;
  label: string;
  type: FilterTypes;
  width: string;
  value?: string;
}

export enum FilterTypes {
  Text = "text",
  Date = "date",
  Select = "select",
  Tel = "tel",
  Email = "email",
}

export interface Attribute {
  key: string;
  label: string;
  type: AttributeTypes;
  sort: SortDirections;
  visible: boolean;
  field: string;
}

export enum AttributeTypes {
  Text = "text",
  List = "list",
  InlineText = "inline-text",
  Progress = "progress",
  Date = "date",
  DateTime = "datetime",
  RelativeDateTime = "relative-datetime",
}

export enum AttributeResource {
  Patients = "patients",
  Users = "users",
  Exports = "exports",
  Operations = "operations",
}

export interface TableRow {
  id: number;
}
