import {
  required,
  email,
  numeric,
  min,
  regex,
  confirmed,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import i18n from "@/i18n";

extend("required", {
  ...required,
  message: () => i18n.t("validate.required").toString(),
});

extend("email", {
  ...email,
  message: () => i18n.t("validate.email").toString(),
  validate(value) {
    return !value || email.validate(value);
  },
});

extend("tel", {
  ...email,
  message: () => i18n.t("validate.tel").toString(),
  validate(value) {
    if (!value) {
      return true;
    }

    const phoneRegex = /^(\+)?[0-9]{6,}$/;
    return phoneRegex.test(value.replaceAll(" ", "").replaceAll("-", ""));
  },
});

extend("numeric", {
  ...numeric,
  message: () => i18n.t("validate.numeric").toString(),
});

extend("minoptions", {
  ...min,
  validate(value) {
    return value.selected_options.length > 0;
  },
  message: () => i18n.t("validate.minoptions").toString(),
});

extend("password", {
  ...regex,
  validate(value) {
    return (
      value &&
      value.length >= 8 &&
      regex.validate(value, {
        regex:
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.@)($!%*#?&^\-_,]).*$/,
      })
    );
  },
  message: () => i18n.t("validate.password").toString(),
});

extend("confirmed", {
  ...confirmed,
  message: () => i18n.t("validate.confirmed").toString(),
});
