import { parse } from "date-fns";

// Simple form date input parser. Accepts dd-mm-yyyy, dd/mm/yyyy and
// dd.mm.yyyy
export function parseDateInput(date: string) {
  date = date.replaceAll("/", "-").replaceAll(".", "-");

  if (date) {
    return parse(date, "dd-MM-yyyy", new Date());
  }
}
