<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <card :title="$t('no_session_page.title')" icon="lock">
        {{ $t("no_session_page.no_session_message") }}
      </card>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import Card from "@/components/Card.vue";

export default Vue.extend({
  name: "NoSession",
  components: {
    Card,
  },
});
</script>
<style lang="scss" scoped></style>
