type LogValueType = {
  by: string;
  at: string;
};

type SectionColumn = {
  fields: Field[];
};

export interface Section {
  columns: SectionColumn[];
  placeholder: string | null;
  title: string;
  subtitle: Field | null;
}

export interface Field {
  inline: boolean;
  label: string;
  type: CardFieldType;
  value: string | number | (string | number)[] | LogValueType;
  highlight: boolean;
  copyable: boolean;
}

export enum CardFieldType {
  datetime = "datetime",
  date = "date",
  list = "list",
  text = "text",

  // Subtitle specific type
  log = "log",
}
