<template>
  <div id="app" class="notranslate" translate="no">
    <template v-if="!$route.meta?.hideHeader">
      <Header />

      <Subheader
        v-if="!$route.meta?.public"
        :hidden="singlePatientMode && $route.name === 'patient-details'"
      />
    </template>

    <!-- If a route has handlesPadding set in its metadata, it
         requires the main content padding to be zero. -->
    <!-- Add a view key to reload the entire content when the tenant ID
         switches. -->
    <router-view
      class="main-content"
      :key="viewKey"
      :class="$route.meta?.handlesPadding ? 'handles-padding' : ''"
    />
  </div>
</template>

<script setup lang="ts">
import Header from "@/components/Header.vue";
import Subheader from "@/components/Subheader.vue";
import { computed, provide } from "vue";
import { getAppAuthMethod } from "@/api/auth_method";
import { currentUser, scopedToPatient } from "@/utils/authUtil";
import { useRoute } from "vue-router/composables";

const authMode = computed(() => getAppAuthMethod());
provide("authMode", authMode);

const singlePatientMode = computed(() => scopedToPatient());
provide("singlePatientMode", singlePatientMode);

const route = useRoute();

// Tenant ID for the currently logged in user
const tenantId = computed(() => currentUser.value?.tenant_id ?? -1);

// Key to reload the main view when the tenant changes. For public routes
// this key is static, to prevent login pages resetting and flickering once
// they set the user after login.
const viewKey = computed(() =>
  route.meta?.public ? "main-content" : `content-tenant-${tenantId.value}`
);
</script>

<style lang="scss">
#app {
  font-family: Inter, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.main-content {
  padding: 1em;

  &.handles-padding {
    padding: 0;
  }
}
</style>
