<template>
  <div>
    <b-loading :is-full-page="true" :active="isLoading" />
    <!-- It's not clear what this check does here for singlePatientMode, because
         you're not supposed to be able to get here in the first place. Leaving it in
         case I missed something there. -->
    <Portal
      to="subheader-actions"
      key="operation-actions"
      v-if="!this.singlePatientMode"
    >
      <b-button
        v-if="operation.screening_count == '0'"
        type="is-danger"
        icon-left="trash"
        @click="showDeletePrompt()"
        >{{ $t("operation_edit_page.delete_operation") }}
      </b-button>
    </Portal>
    <transition name="fade">
      <div class="columns" v-if="!isLoading">
        <div class="column is-half-desktop">
          <Card :title="$t('operation_edit_page.title')">
            <template v-slot:header-actions></template>
            <Form
              :fields="fields"
              :canDelete="true"
              :onSubmitClick="onSubmitClick"
              v-model="operation"
            >
              <template v-slot:form-actions>
                <b-button @click="$router.push('/operations')"
                  >{{ $t("common.back") }}
                </b-button>
              </template>
            </Form>
          </Card>
        </div>
        <div class="column is-half is-hidden-touch vertical-card-spacing">
          <card
            :title="$t('operation_edit_page.videos_for_patient')"
            icon="video"
          >
            <video-list :videos="videos" />
          </card>
          <card :title="$t('common.anesthesia_techniques')" icon="list-ol">
            <technique-list
              :techniques="filteredTechniques"
              :preferredTechniqueKey="preferredTechniqueKey"
            />
          </card>
        </div>

        <video-sidebar
          :videos="videos"
          :techniques="filteredTechniques"
          :preferredTechniqueKey="preferredTechniqueKey"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Card from "@/components/Card.vue";
import Form from "@/components/Form/Form.vue";

import {
  createOperation,
  getOperationFormData,
  getOperationForm,
  updateOperation,
  deleteOperation,
} from "@/api";

import { FormField } from "@/types/form";
import { Operation } from "@/types/operations";
import TechniqueList from "@/components/Prepare/TechniqueList.vue";
import VideoList from "@/components/Prepare/VideoList.vue";
import VideoSidebar from "@/components/Prepare/VideoSidebar.vue";
import {
  AnesthesiaTechniqueField,
  AnesthesiaVideo,
  VideosMap,
} from "@/types/anesthesiaTechniques";
import { resources } from "@/utils/resources";
import { mapKeys } from "lodash";
import i18n from "@/i18n";
import { ResponseError } from "@/types/response-error";

export default Vue.extend({
  name: "OperationEdit",
  inject: ["authMode", "singlePatientMode"],
  components: {
    Card,
    TechniqueList,
    VideoList,
    Form,
    VideoSidebar,
  },
  data() {
    return {
      anesthesiaTechniques: [] as AnesthesiaTechniqueField[],
      anesthesiaVideos: [] as AnesthesiaVideo[],
      fields: [] as FormField[],
      operation: {} as Operation,
      isLoading: true,
    };
  },

  computed: {
    operationId(): number | null {
      return parseInt(this.$route.params?.operationId) || null;
    },
    filteredTechniques(): AnesthesiaTechniqueField[] {
      if (this.operation?.anesthesia_methods?.selected_options) {
        return this.anesthesiaTechniques.filter((c: AnesthesiaTechniqueField) =>
          this.operation.anesthesia_methods.selected_options.includes(c.value)
        );
      }
      return [];
    },
    preferredTechniqueKey(): string {
      return this.operation?.anesthesia_methods?.preferred_options
        ? this.operation.anesthesia_methods.preferred_options[0]
        : "";
    },
    videos(): AnesthesiaVideo[] {
      const videosMap = mapKeys(this.anesthesiaVideos, "name") as VideosMap;

      const videos = this.filteredTechniques
        .map((c) => {
          return c.videos.map((name) => {
            return videosMap[name];
          });
        })
        .flat();

      return [...new Set(videos)];
    },
  },

  methods: {
    showDeletePrompt() {
      this.$buefy.dialog.confirm({
        title: i18n.t("operation_edit_page.dialog_remove.title").toString(),
        message: i18n.t("operation_edit_page.dialog_remove.message").toString(),
        type: "is-danger",
        hasIcon: true,
        icon: "delete",
        ariaRole: "alertdialog",
        ariaModal: true,
        confirmText: i18n
          .t("operation_edit_page.dialog_remove.confirm")
          .toString(),
        onConfirm: () => this.deleteOperation(),
      });
    },
    async deleteOperation() {
      try {
        if (!this.operationId) {
          return;
        }

        await deleteOperation(this.operationId);

        this.$buefy.toast.open({
          message: i18n
            .t("operation_edit_page.deleted_operation_message")
            .toString(),
          type: "is-success",
        });

        this.$router.push("/operations");
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as ResponseError).response.data.message ||
            i18n.t("operation_edit_page.remove_error_message").toString(),
          type: "is-danger",
        });
      }
    },
    async getOperation(operationId: number) {
      const { data } = await getOperationFormData(operationId);
      this.operation = { ...data };
    },
    async getOperationForm() {
      const { data } = await getOperationForm();
      this.fields = data;

      for (let field of this.fields) {
        if (field.key === "anesthesia_methods") {
          Vue.set(this.operation, "anesthesia_methods", {});
          Vue.set(this.operation.anesthesia_methods, "selected_options", []);
          Vue.set(this.operation.anesthesia_methods, "preferred_options", []);
        } else if (field.type === "multi-select") {
          (this.operation as Record<string, any>)[field.key] = [];
        } else {
          (this.operation as Record<string, any>)[field.key] = null;
        }
      }
    },

    async onSubmitClick() {
      try {
        if (this.operationId) {
          const response = await updateOperation(
            this.operationId,
            this.operation
          );
          this.operation = { ...response.data };
        } else {
          /* @ts-ignore */
          const response = await createOperation(this.operation);
          this.operation = { ...response.data };
          this.$router.push(`/operation/${this.operation.id}`);
        }

        this.$buefy.toast.open({
          message: i18n
            .t("operation_edit_page.saved_operation_message")
            .toString(),
          type: "is-success",
        });
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            (err as ResponseError).response?.data?.message ||
            i18n.t("common.saving_data_error_message").toString(),
          type: "is-danger",
        });
      }
    },
  },

  async mounted() {
    try {
      const anesthesiaTechniques = resources.getAnesthesiaTechniques();
      const anesthesiaVideos = resources.getAnesthesiaVideos();

      this.anesthesiaTechniques = await anesthesiaTechniques;
      this.anesthesiaVideos = await anesthesiaVideos;

      await this.getOperationForm();

      if (this.operationId) {
        await this.getOperation(this.operationId);
      }
    } catch (err) {
      this.$buefy.toast.open({
        message: i18n
          .t("common.retrieving_error_message", {
            resource: i18n.t("common.operation").toString(),
          })
          .toString(),
        type: "is-danger",
      });
    } finally {
      this.isLoading = false;
    }
  },
});
</script>
