import { datadogLogs } from "@datadog/browser-logs";
import { currentUser } from "@/utils/authUtil";

datadogLogs.init({
  clientToken: process.env.VUE_APP_DATADOG_CLIENT_KEY ?? "",
  beforeSend: (log) => {
    const user = currentUser.value;
    if (user) {
      log.user = {
        id: user.id,
        tenant_id: user.tenant_id,
        patient_id: user.patient_id,
      };
    }
    return true;
  },
  site: "datadoghq.eu",
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

datadogLogs.setGlobalContextProperty("service", "admin-client");
datadogLogs.setGlobalContextProperty("env", process.env.NODE_ENV);
