<template>
  <div class="column is-half is-offset-one-quarter">
    <card :title="$t('login_page.login').toString()">
      <!-- No error -->
      <div v-if="!error" class="has-text-centered">
        <span class="is-size-4">
          {{ $t("open_id_login_page.logging_you_in_at_epos") }}
        </span>
      </div>

      <!-- Error -->
      <div v-else class="pt-4">
        <div class="has-text-centered">
          <span class="is-size-4">
            {{ $t("open_id_login_page.logging_you_in_failed_message") }}
          </span>
        </div>

        <div class="pt-4">
          <b-message type="is-danger" has-icon>
            {{ error }}
          </b-message>
        </div>
      </div>

      <div v-if="isLoading" class="loader-element">
        <b-loading :is-full-page="false" :active="isLoading" />
      </div>

      <template #card-footer v-if="error">
        <b-button type="is-primary" @click="retry()">
          {{ $t("open_id_login_page.retry") }}
        </b-button>
      </template>
    </card>
  </div>
</template>

<script setup lang="ts">
import Card from "@/components/Card.vue";
import { inject, onMounted, Ref, ref } from "vue";
import { getAndClearParameters, getRedirectUri } from "@/utils/openid";
import useLoadingStatus from "@/utils/loading-status";
import i18n from "@/i18n";
import { useRouter } from "vue-router/composables";
import {
  linkServerSession,
  OIDCExchangeParams,
  openIdConnectExchange,
} from "@/api";
import { currentUser, setJWT } from "@/utils/authUtil";
import { determineLandingRoute, initServerSession } from "@/router";
import { getAbsoluteUri } from "@/utils/functions";
import { AppAuthMethod } from "@/api/auth_method";

const { isLoading, whileLoading } = useLoadingStatus();
const error = ref(null as null | string);
const router = useRouter();
const storedHandle = ref(null as null | string);
const storedParams = ref(null as null | Record<string, string | null>);

// Used to check if we need to link a user after zorgplatform login
const authMode = inject("authMode") as Ref<AppAuthMethod>;

function retry() {
  if (storedHandle.value) {
    const query: Record<string, string> = {};

    if (storedParams.value?.patientId) {
      query.patient = storedParams.value.patientId;
    } else if (storedParams.value?.mdn) {
      query.mdn = storedParams.value.mdn;
    }

    router.push({
      name: "openId",
      params: { handle: storedHandle.value },
      query: query,
    });
  } else {
    router.push({ name: "login" });
  }
}

onMounted(async () => {
  await whileLoading(async () => {
    const { handle, state, nonceSecret, params } = getAndClearParameters();
    storedHandle.value = handle;
    storedParams.value = params;

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    const queryState = queryParams.get("state");
    if (!code || !handle || !state || !nonceSecret || queryState !== state) {
      // Prerequisites not met
      error.value = i18n.t("open_id_login_page.invalid_state").toString();
      return;
    }

    if (queryParams.get("error")) {
      // Error returned from the IDP
      error.value = i18n
        .t("open_id_login_page.unknown_user_failed_message")
        .toString();
      return;
    }

    try {
      const home = getAbsoluteUri(router.resolve({ name: "login" }));

      const exchangeParams: OIDCExchangeParams = {
        handle,
        nonceSecret,
        code,
        redirectUri: getRedirectUri(router, handle),
        postLogoutRedirectUri: home,
      };

      if (params.patientId) {
        exchangeParams.patientIdScope = parseInt(params.patientId);
      }

      const response = await openIdConnectExchange(exchangeParams);
      const token = response.data;
      if (authMode.value === "server") {
        // Link the server session to the logged in user, if needed.
        await linkServerSession(token);
        currentUser.value = await initServerSession();
      } else {
        setJWT(token);
      }

      const path = determineLandingRoute(currentUser.value) || "/patients";
      router.push(path);
    } catch (e: any) {
      if (
        e.response?.data?.errors?.patient_id ||
        e.response?.data?.errors?.mdn
      ) {
        error.value = i18n
          .t("open_id_login_page.invalid_patient_message")
          .toString();
      } else {
        error.value = i18n
          .t("open_id_login_page.unknown_user_failed_message")
          .toString();
      }

      return;
    }
  });
});
</script>

<style scoped lang="scss">
.loader-element {
  width: 100%;
  min-height: 5rem;
  position: relative;
}
</style>
