<template>
  <div>
    <b-loading :is-full-page="true" :active="isLoading" />

    <Portal to="subheader-actions" key="patients-actions">
      <span class="mb-2 mr-2 is-hidden-mobile"
        >{{
          $t("common.last_refreshed_on", { updatedOn: formatTime(updatedOn) })
        }}
      </span>
      <b-button
        type="is-secondary"
        @click="getExports"
        icon-left="sync-alt"
        :loading="isLoading"
      >
        {{ $t("common.refresh_data") }}
      </b-button>
      <b-button
        type="is-primary"
        icon-left="plus"
        @click="isExportModalActive = !isExportModalActive"
        >{{ $t("export_page.do_export") }}
      </b-button>
    </Portal>
    <transition name="fade">
      <div class="columns" v-if="!isLoading" key="content">
        <div class="column">
          <Card :contentPadding="false" :overflow="true" class="no-background">
            <Table
              :columns="columns"
              :items="items"
              :page="params.page"
              :page-size="params.page_size"
              :total="total"
              :sort-direction="params.direction"
              :sort="params.sort"
              :onPageChange="onPageChange"
              :onSortChange="onSortChange"
              :onRowClick="onRowClick"
              :onPageSizeChange="onPageSizeChange"
            >
              <template v-slot:empty-table>
                {{ $t("export_page.no_exports") }}
              </template>
            </Table>
          </Card>
        </div>
      </div>
    </transition>
    <b-modal v-model="isExportModalActive" :can-cancel="false" has-modal-card>
      <export-modal @afterSubmit="afterExport" />
    </b-modal>
  </div>
</template>

<script lang="ts">
import Table from "@/components/Table.vue";
import Card from "@/components/Card.vue";
import { currentUser } from "@/utils/authUtil";
import mixins from "vue-typed-mixins";
import TableMixin from "@/mixins/TableMixin";
import { AttributeResource, FilterParams } from "@/types/table";
import { ExportRow, ExportTemplate, OptionsInterface } from "@/types/export";
import ExportModal from "@/components/ExportModal.vue";
import { getExports, getExportTemplates } from "@/api";
import eventBus from "@/utils/eventBus";
import { formatTime } from "@/filters/date";
import i18n from "@/i18n";

export default mixins(TableMixin<ExportRow, FilterParams>()).extend({
  name: "Exports",
  components: {
    Card,
    ExportModal,
    Table,
  },
  watch: {
    $route: {
      handler: function () {
        this.getExports();
      },
    },
  },
  data() {
    return {
      AttributeResource,
      currentUser: currentUser,
      exportModalOpen: false,
      exportTemplates: {} as OptionsInterface<ExportTemplate>,
      isExportModalActive: false,
      isLoading: true,
      updatedOn: null as unknown as Date,
      formatTime: formatTime,
    };
  },
  methods: {
    async afterExport(exportId: number) {
      this.$router.push(`/export/${exportId}`);
    },
    async getExports() {
      const query = this.createQueryString(this.$route);

      this.isLoading = true;

      try {
        const { data: exportResponse } = await getExports(query);
        this.items = [...exportResponse.items];
        this.params = exportResponse.params;
        this.total = exportResponse.total;
        this.updatedOn = new Date();
      } catch (err) {
        this.$buefy.toast.open({
          message: i18n
            .t("common.retrieving_error_message", {
              resource: i18n.tc("common.exports", 2).toString(),
            })
            .toString(),
          type: "is-danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    onFilterResetClick() {
      eventBus.$emit("clear-filters");
    },

    async onRowClick(row: ExportRow) {
      this.$router.push(`/export/${row.id}`);
    },
  },
  async mounted() {
    this.getAttributes(AttributeResource.Exports);

    this.getExports();

    const { data: exportTemplates } = await getExportTemplates();

    this.exportTemplates = {
      options: exportTemplates,
    };
  },
});
</script>
