<template>
  <b-input
    :placeholder="field.placeholder"
    :type="field.type"
    :disabled="disabled"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    v-on:blur="$emit('blur', $event)"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { FieldMixin } from "./FieldMixin";
export default Vue.extend({
  name: "TextField",
  mixins: [FieldMixin],
});
</script>
