<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <b-loading :is-full-page="true" :active="isLoading" />

      <transition name="fade">
        <Card :title="$t('user_edit_page.title').toString()" v-if="!isLoading">
          <template v-slot:header-actions>
            <b-button
              type="is-danger"
              icon-left="trash"
              @click="showDeletePrompt()"
              v-if="user.id && currentUser.value.id !== user.id"
              >{{ $t("user_edit_page.delete_user") }}
            </b-button>
          </template>
          <Form
            :fields="fields"
            :canDelete="true"
            :onSubmitClick="onSubmitClick"
            v-model="user"
          >
            <template v-slot:form-actions>
              <b-button @click="$router.push('/users')">
                {{ $t("common.back") }}
              </b-button>
            </template>
          </Form>
        </Card>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Card from "@/components/Card.vue";
import Form from "@/components/Form/Form.vue";

import {
  createUser,
  getUser,
  getUserForm,
  updateUser,
  deleteUser,
} from "@/api";

import { FormField, FormFieldTypes } from "@/types/form";
import { User } from "@/types/users";
import { currentUser } from "@/utils/authUtil";
import i18n from "@/i18n";
import { ResponseError } from "@/types/response-error";

export default Vue.extend({
  name: "UserEdit",
  components: {
    Card,
    Form,
  },
  data() {
    return {
      fields: [] as FormField[],
      user: {} as User,
      isLoading: true,
      currentUser: currentUser,
    };
  },

  computed: {
    userId(): number | null {
      return parseInt(this.$route.params?.userId) || null;
    },
  },

  methods: {
    showDeletePrompt() {
      this.$buefy.dialog.confirm({
        title: i18n.t("user_edit_page.delete_dialog.title").toString(),
        message: i18n.t("user_edit_page.delete_dialog.message").toString(),
        confirmText: i18n.t("user_edit_page.delete_dialog.confirm").toString(),
        type: "is-danger",
        hasIcon: true,
        icon: "delete",
        ariaRole: "alertdialog",
        ariaModal: true,
        onConfirm: () => this.deleteUser(),
      });
    },
    async deleteUser() {
      try {
        if (!this.userId) {
          return;
        }

        await deleteUser(this.userId);

        this.$buefy.toast.open({
          message: i18n.t("user_edit_page.user_removed_message").toString(),
          type: "is-success",
        });

        this.$router.push("/users");
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as ResponseError).response.data.message ||
            i18n.t("common.delete_failed_error_message").toString(),
          type: "is-danger",
        });
      }
    },
    async getUser(userId: number) {
      const { data } = await getUser(userId);
      this.user = { ...data };
    },
    async getUserForm() {
      const { data } = await getUserForm();
      this.fields = data;

      for (const field of this.fields) {
        (this.user as Record<string, any>)[field.key] =
          field.type === FormFieldTypes.multiSelect ? [] : null;
      }
    },

    async onSubmitClick() {
      try {
        if (this.userId) {
          await updateUser(this.userId, this.user);
        } else {
          await createUser(this.user);
        }

        this.$buefy.toast.open({
          message: i18n.t("user_edit_page.user_saved_message").toString(),
          type: "is-success",
        });
        this.$router.push("/users");
      } catch (err) {
        this.$buefy.toast.open({
          message:
            (err as any).customErrorMessage ||
            i18n.t("common.saving_data_error_message").toString(),
          type: "is-danger",
        });

        throw (err as ResponseError).response.data?.errors;
      }
    },
  },

  async mounted() {
    try {
      await this.getUserForm();

      if (this.userId) {
        await this.getUser(this.userId);
      }
    } catch (err) {
      this.$buefy.toast.open({
        message: i18n
          .t("common.retrieving_error_message", {
            resource: i18n.t("common.user").toString(),
          })
          .toString(),
        type: "is-danger",
      });
    } finally {
      this.isLoading = false;
    }
  },
});
</script>
