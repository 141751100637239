<template>
  <b-field grouped group-multiline>
    <b-radio-button
      v-for="(option, key) in field.options"
      :key="key"
      :native-value="option[valueKey]"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      :disabled="disabled"
    >
      {{ option[labelKey] }}
      <b-icon :icon="option.icon" v-if="option.icon" />
    </b-radio-button>
  </b-field>
</template>

<script lang="ts">
import Vue from "vue";
import { FieldMixin } from "./FieldMixin";

export default Vue.extend({
  props: {
    valueKey: {
      default: "value",
    },
    labelKey: {
      default: "label",
    },
  },
  name: "SelectField",
  mixins: [FieldMixin],
});
</script>
