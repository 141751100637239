import { FormField } from "@/types/form";
import { PropType } from "vue";

export const FieldMixin = {
  props: {
    field: {} as PropType<FormField>,
    disabled: Boolean,
    value: [String, Number, Boolean, Object, Array, Date],
  },
};
